import styled from 'styled-components'

export const SmallNotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  cursor: ${({ isWeeklyReport }) => (isWeeklyReport ? 'pointer' : 'default')};
`

export const ReadIndicator = styled.div`
  width: 8px;
  height: 8px;
  content: '';
  background: ${props =>
    props.active ? 'rgba(255, 94, 109, 1)' : 'rgba(229, 229, 229, 1)'};
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`

export const InfoPart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${props => (props.hasActions ? 'flex-start' : 'center')};
  flex-grow: 1;
  padding-right: 21px;
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 12px;
  height: 50px;
  flex-grow: 0;
  max-width: 231px;
  height: 100%;
`

export const NotificationContent = styled.p`
  padding: 0;
  margin: 0;
`

export const LikeText = styled(NotificationContent)`
  font-size: 12px;
  line-height: 14.65px;
  letter-spacing: 2%;
  color: black;
  font-weight: 500;
`

export const EventText = styled(NotificationContent)`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const NotificationTime = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: rgba(139, 139, 139, 1);
`

export const ActionImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
`

export const GiftImage = styled.img`
  height: 26px;
  vertical-align: middle;
`

export const ReplyButton = styled.button`
  width: 80px;
  height: 30px;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: transparent;
  border-radius: 15px;
  border: 0.5px solid #d8d8d8;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
`

export const ActionButtonsGrid = styled.div`
  display: flex;
  margin-top: 7.5px;
  gap: 8px;
`

export const LikeButton = styled.button`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background: ${props => (props.active ? '#FFE8EA' : '#f5f5f5')};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WeeklyReportAvatar = styled.img`
  width: 50px;
  height: 50px;
`

export const MultiNotificationAvatars = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  flex-shrink: 0;

  & img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px #f1f1f1;
  }

  & img:nth-child(1) {
    top: 0;
    left: 0;
  }

  & img:nth-child(2) {
    top: 17px;
    left: 17px;
  }
`
