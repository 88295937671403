import axios from 'axios'
import { APPID } from '../constants/ApiEndpoints'
import { FEED_INIT, FEED_RESET } from '../redux/constants'
import { randomNodeId } from './nodeUtils'
//import { deleteCookie, setCookie } from './getUserCookie'

const KEY_USER = 'user_' + APPID

//------------------------------------------
const KEY_TOKEN = 'token'

export const setAuth = userdata => {
  localStorage.setItem(KEY_TOKEN, JSON.stringify(userdata))
}

export const logout = () => {
  localStorage.removeItem(KEY_TOKEN)
}

export const getLocalUserToken = () => {
  const l = localStorage.getItem(KEY_TOKEN)
  if (l) {
    return JSON.parse(l).sessionToken
  }
  return null
}

//------------------------------------------

export const getDomain = url => {
  var separators = ['/', '.']
  var temp = url.split(new RegExp('[' + separators.join('') + ']', 'g'))
  var domain = 'https://www.decormatters.com'
  temp.forEach((d, i) => {
    if (d === 'designer-dev') {
      domain = 'https://dev.decormatters.com'
    }
    if (d === 'designer-stage') {
      domain = 'https://stage.decormatters.com'
    }
    if (d === 'designer') {
      domain = 'https://www.decormatters.com'
    }
    if (d === 'localhost:4001') {
      domain = 'http://localhost:4000'
    }
    if (d === 'localhost:3001') {
      domain = 'http://localhost:3000'
    }
    if (d === '192') {
      domain = 'http://192.168.1.68:4000'
    }
  })

  return domain
}

export const getCookie = cname => {
  if (typeof window === 'undefined') return false

  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

export const setUserToken = token => {
  localStorage.setItem(KEY_TOKEN, token)
}

export const getUserToken = () => {
  try {
    if (typeof window === 'undefined') return false

    var oldUserCookie = getCookie(`user_${APPID}`)
    if (oldUserCookie === false) {
      const newUserCookie = getCookie(KEY_USER)
      if (newUserCookie === false) {
        const local = localStorage.getItem(KEY_TOKEN)
        if (!local) return null
        return local
      }
      const nd = JSON.parse(newUserCookie)
      return nd.puser.sessionToken
    }

    const od = JSON.parse(oldUserCookie)
    return od.puser.sessionToken
  } catch (error) {
    return null
  }
}

export const getUser = () => {
  try {
    var oldUserCookie = getCookie(`user_${APPID}`)
    if (oldUserCookie === false) {
      const newUserCookie = getCookie(KEY_USER)
      if (newUserCookie === false) return null
      const nd = JSON.parse(newUserCookie)
      return nd.puser
    }

    const od = JSON.parse(oldUserCookie)
    return od.puser
  } catch (error) {
    return null
  }
}

export const getHeaders = () => {
  const token = getUserToken()
  const headers = {
    'Content-Type': 'application/json',
    'X-Parse-Application-Id': APPID,
    'X-Content-Options': 'Deny',
    'Content-Security-Policy': "frame-ancestors 'none'"
  }

  if (token) {
    headers['X-Parse-Session-Token'] = token
  }

  return headers
}

export const api = async (endpoint, body, method = 'POST') => {
  const url = `${endpoint}`
  //console.log(endpoint)
  const appendant = {
    method,
    mode: 'cors',
    headers: getHeaders(),
    body
  }

  let response = await fetch(url, appendant)

  return response.json()
}

export const getUrl = (baseUrl, location, file) => {
  return 'https://' + baseUrl + '/' + location + '/' + file
}

export const changeS3Url = url => {
  const temp = url.split('/')
  //console.log(url)
  temp.forEach((d, i) => {
    if (d === 'https:') {
      temp[i] = null
    }
    if (d === '') {
      temp[i] = null
    }
    if (d === 'didr9pubr8qfh.cloudfront.net') {
      temp[i] = 'decormatters-prod.s3-us-west-1.amazonaws.com'
    }
    if (d === 'd4dlbxiydwyqu.cloudfront.net') {
      temp[i] = 'decormatters-dev.s3-us-west-1.amazonaws.com'
    }
  })

  const filtered = temp.filter(Boolean)
  //console.log(filtered)
  return 'https://' + filtered.join('/')
}

export const checkS3Url = url => {
  var isS3 = false
  const temp = url.split('/')
  temp.forEach((d, i) => {
    if (d === 'decormatters-prod.s3-us-west-1.amazonaws.com') {
      isS3 = true
    }
    if (d === 'decormatters-dev.s3-us-west-1.amazonaws.com') {
      isS3 = true
    }
    if (d === 'decormatters-dev.s3.us-west-1.amazonaws.com') {
      isS3 = true
    }
    if (d === 'decormatters-dev.s3.amazonaws.com') {
      isS3 = true
    }
    if (d === 'didr9pubr8qfh.cloudfront.net') {
      isS3 = true
    }
    if (d === 'd4dlbxiydwyqu.cloudfront.net') {
      isS3 = true
    }
  })
  return isS3
}

export const convertDataToArtboard = (objectId, templateUrl, targets, sceneWidth, sceneHeight) => {
  return {
    id: objectId,
    template: templateUrl,
    targets: targets ? targets : [],
    sceneWidth,
    sceneHeight
  }
}

export const convertiOSDataToArtboard = (objectId, templateUrl, prod, designData, sceneWidth, sceneHeight) => {
  const convertTiltX = (w, h, t, s = 1) => {
    const dir = Math.sign(t)
    const l = (w / 2) * (1 - Math.abs(t))
    const g = (h / 2) * (1 - Math.abs(t))
    const ny = (h * s) / 2
    if (dir < 0) return [-l, -g, l, -ny, l, ny, -l, g]
    return [-l, -ny, l, -g, l, g, -l, ny]
  }

  const convertTiltY = (w, h, t, s = 1) => {
    const dir = Math.sign(t)
    const l = (w / 2) * (1 - Math.abs(t))
    const g = (h / 2) * (1 - Math.abs(t))
    const nx = (w * s) / 2
    if (dir < 0) return [-l, -g, l, -g, nx, g, -nx, g]
    return [-nx, -g, nx, -g, l, g, -l, g]
  }

  const SCENE_WIDTH = sceneWidth || 750
  const SCENE_HEIGHT = sceneHeight || 750

  var targets = []

  prod.forEach(p => {
    const posX = Math.round(SCENE_WIDTH * p.positionData.centerXRatio)
    const posY = Math.round(SCENE_HEIGHT * p.positionData.centerYRatio)
    const scaleX = (SCENE_WIDTH * p.positionData.widthRatio) / p.w
    const scaleY = (SCENE_HEIGHT * p.positionData.heightRatio) / p.h
    const sx = p.positionData.isFlipped === true ? -scaleX : scaleX

    const src = changeS3Url(p.croppedImageUrl ? p.croppedImageUrl : p.imageUrl)

    var perspective = [0, 0, 0, 0, 0, 0, 0, 0]

    if (p.positionData.perspectiveX && p.positionData.perspectiveX !== 0) {
      perspective = convertTiltX(p.w, p.h, p.positionData.perspectiveX)
    }

    if (p.positionData.perspectiveY && p.positionData.perspectiveY !== 0) {
      perspective = convertTiltY(p.w, p.h, p.positionData.perspectiveY)
    }

    //console.log(p.positionData.perspectiveY)
    const obj = {
      src,
      f: p.positionData.brightness || [1],
      m: [posX, posY, sx, scaleY, p.positionData.rotation],
      p: perspective,
      item: {
        ...p.item,
        imgurl: changeS3Url(p.imageUrl),
        isOthersMyDecor: !!p.isOthersMyDecor
      },
      starter: p.starter || false
    }
    //console.log(obj)
    targets.push(obj)
  })
  //console.log(targets)
  return {
    id: objectId,
    template: templateUrl,
    targets,
    designData,
    sceneWidth,
    sceneHeight
  }
}

export const convertiOSMarkers = (markers, sceneWidth, sceneHeight) => {
  if (!markers) return []
  if (markers.length === 0) return []

  const SCENE_WIDTH = sceneWidth || 750
  const SCENE_HEIGHT = sceneHeight || 750

  const scale = SCENE_WIDTH / 750

  var mark = []

  markers.forEach(m => {
    const posX = Math.round((SCENE_WIDTH * m.position.x) / 0.9)
    const posY = Math.round((SCENE_HEIGHT * m.position.y) / 0.9)

    const obj = {
      id: randomNodeId(),
      categoryId: m.categoryId,
      x: posX,
      y: posY,
      subcategoryId: m.subcategoryId,
      title: m.title,
      complete: false,
      scale,
      frame: m.frame,
      icon: m.icon
    }

    mark.push(obj)
  })

  return mark
}

export const sendBase64File = async (signedUrl, file) => {
  //console.log('[fetchUtils][sendBase64File')
  //console.log(signedUrl)
  //console.log(file)
  try {
    const result = await axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
        'Access-Control-Allow-Origin': '*'
      }
    })
    return result
  } catch (e) {
    //console.log(e)
  }
}

export const checkFeedStatus = (status, listLength, pageNum, isPageEnd) => {
  var nextPage = pageNum
  if (isPageEnd === true) return -1
  if (status === FEED_INIT && listLength > 0) return -1
  if (status === FEED_RESET) nextPage = 0
  return nextPage
}

//------------------------------------------

export const go = async (url, body) => {
  const option = {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(),
    body: body ? JSON.stringify(body) : null
  }

  const response = await fetch(url, option)
  const responseJson = await response.json()

  return responseJson
}
