import React from 'react'
import coinsImg from '../assets/Coins.svg'
import { BannerNotification } from '../components/notifications/BannerNotification/BannerNotification'
import SmallNotification from '../components/notifications/SmallNotification'
import { GiftImage } from '../components/notifications/SmallNotification/SmallNotification.styles'
import {
  COMMENT_TYPES,
  NOTIFICATION_TYPES
} from '../constants/NotificationTypeConstants'
import ProfileImage from '../routes/Profile/components/profile/ProfileImage'
import {
  setShowPurchaseCoins,
  setShowBundles
} from '../redux/designerReducers/designerReducer'

export const getNoitificationLink = notification => {
  switch (notification.type) {
    case NOTIFICATION_TYPES.FOLLOW:
      return `/dm/${notification.sourceUser.objectId}`
    case NOTIFICATION_TYPES.POPULAR_FEED:
    case NOTIFICATION_TYPES.USER_INSPIRATION_LIKE:
    case NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT:
    case NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_LIKE:
    case NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_REPLY:
      return `/design/${notification.userInspiration.objectId}`
    case NOTIFICATION_TYPES.CHALLENGE_ENDED:
    case NOTIFICATION_TYPES.CHALLENGE_ENDED_GLOBAL:
    case NOTIFICATION_TYPES.CHALLENGE_REMINDER:
    case NOTIFICATION_TYPES.NEW_CHALLENGE_GLOBAL:
      return `/challenges/${notification.designContest.objectId}`
    case NOTIFICATION_TYPES.DAILY_TASK_REWARD:
    case NOTIFICATION_TYPES.LEVEL_UP:
    case NOTIFICATION_TYPES.BADGE_REWARD:
    case NOTIFICATION_TYPES.CHALLENGE_REWARD:
      return `/dm`
    case NOTIFICATION_TYPES.AVATAR:
      return `/dm?avatar=true`
    case NOTIFICATION_TYPES.EVENT_WINNERS_REMINDER:
    case NOTIFICATION_TYPES.EVENT_SCHEDULED:
    case NOTIFICATION_TYPES.EVENT_LIVE:
    case NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL:
    case NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL:
    case NOTIFICATION_TYPES.NEW_EVENT_DESIGNS:
      return `/event/${notification?.userEvent?.objectId}`
    case NOTIFICATION_TYPES.EVENT_WINNER:
    case NOTIFICATION_TYPES.USER_EVENT_REWARD:
    case NOTIFICATION_TYPES.DAILY_DESIGN_WINNER:
    case NOTIFICATION_TYPES.NEW_USER_DAILY_PICK:
      return `/design/${notification?.userInspiration?.objectId}`
    case NOTIFICATION_TYPES.MODIFY_EVENT:
      if (notification.userEvent.status === 'incomplete')
        return `/event/${notification?.userEvent?.objectId}/edit`

      return `/event/${notification?.userEvent?.objectId}`
    case NOTIFICATION_TYPES.NEW_USER_TOP_INFLUENCER:
      return '/leaders/newUserTopInfluencer#currentUser'
    case NOTIFICATION_TYPES.MOST_VOTES_IN_CHALLENGES:
      return '/leaders/mostVotesInChallenges#currentUser'
    case NOTIFICATION_TYPES.NEW_USER_MOST_VOTES_IN_CHALLENGES:
      return '/leaders/newUserMostVotesInChallenges#currentUser'
    case NOTIFICATION_TYPES.TOP_INFLUENCER:
      return '/leaders/topInfluencer#currentUser'
    case NOTIFICATION_TYPES.TOP_GIFTS:
      return '/leaders/topGifts#currentUser'
    case NOTIFICATION_TYPES.TOP_GIFTS_ALL_TIME:
      return '/leaders/topGiftsAllTime#currentUser'
    case NOTIFICATION_TYPES.TOP_GIFTS_NEW_USER:
      return '/leaders/topGiftsNewUser#currentUser'
    case NOTIFICATION_TYPES.TOP_BADGES:
      return '/leaders/topBadges#currentUser'
    case NOTIFICATION_TYPES.GIFT:
      return `/community/featured`
    default:
      return undefined
  }
}

export const getNotificationAction = (notification, dispatch, router) => {
  switch (notification.type) {
    case NOTIFICATION_TYPES.ITEM_BUNDLE_SALE_GLOBAL:
      dispatch(setShowPurchaseCoins(true))
      dispatch(setShowBundles(true))
      break
    case NOTIFICATION_TYPES.DAILY_DESIGN_WINNER:
      router.push(`/design/${notification.userInspiration.objectId}`)
    case NOTIFICATION_TYPES.NEW_USER:
      router.push('/challenges/newuser')
    default:
      break
  }
}

export const getNoitificationDescription = notification => {
  switch (notification.type) {
    case NOTIFICATION_TYPES.NEW_USER:
      return <>Start by creating your first design.</>
    case NOTIFICATION_TYPES.FOLLOW:
      return (
        <>
          <b>{notification.sourceUser.uniqueDisplayName || 'A new user'}</b>{' '}
          started following you!
        </>
      )
    case NOTIFICATION_TYPES.USER_INSPIRATION_LIKE:
      return (
        <>
          <b>{notification.sourceUser.uniqueDisplayName}</b> liked your design
        </>
      )
    case NOTIFICATION_TYPES.PUBLISHED_DESIGN:
      return (
        <>
          <b>{notification.sourceUser.uniqueDisplayName}</b> published a design!
        </>
      )
    case NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT:
      switch (notification.userInspirationComment.type) {
        case COMMENT_TYPES.GIFT:
          return (
            <>
              <b>{notification.sourceUser.uniqueDisplayName}</b> sent the{' '}
              <GiftImage
                src={notification.userInspirationComment.vgImageUrl}
                alt=""
              />{' '}
              gift for your design!
            </>
          )
        default:
          return (
            <>
              {notification.alert.includes('mentioned') ? (
                <>
                  <b>{notification.sourceUser.uniqueDisplayName}</b> mentioned
                  you in a comment: {notification.userInspirationComment.text}
                </>
              ) : (
                <>
                  <b>{notification.sourceUser.uniqueDisplayName}</b> left a
                  comment
                </>
              )}
            </>
          )
      }
    case NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_LIKE:
      return (
        <>
          <b>{notification.sourceUser.uniqueDisplayName}</b> liked your comment!
        </>
      )
    case NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_REPLY:
      return (
        <>
          <b>{notification.sourceUser.uniqueDisplayName}</b> wrote:{' '}
          {notification.userInspirationComment.text}
        </>
      )
    case NOTIFICATION_TYPES.EVENT_WINNER:
      return <>Click to view the winning design</>
    case NOTIFICATION_TYPES.USER_EVENT_REWARD:
      return <>Click to view the design</>
    case NOTIFICATION_TYPES.BADGE_REWARD:
      return <>Click to view reward at your profile page</>
    case NOTIFICATION_TYPES.LEVEL_UP:
    case NOTIFICATION_TYPES.BADGE_REWARD:
    case NOTIFICATION_TYPES.DAILY_TASK_REWARD:
    case NOTIFICATION_TYPES.CHALLENGE_REWARD:
    case NOTIFICATION_TYPES.WELCOME_GIFT:
      return <>Click to view reward at your profile page</>
    case NOTIFICATION_TYPES.WEEKLY_REPORT:
      return <>Click to review your weekly report</>
    case NOTIFICATION_TYPES.MODIFY_EVENT:
    case NOTIFICATION_TYPES.EVENT_SCHEDULED:
    case NOTIFICATION_TYPES.EVENT_LIVE:
    case NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL:
    case NOTIFICATION_TYPES.USER_EVENT_REWARD:
    case NOTIFICATION_TYPES.EVENT_WINNER:
      return notification.alert
    case NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL:
      return 'Tap to join the event!'
    case NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL:
      return 'Check out the event’s winning designs.'
    case NOTIFICATION_TYPES.EVENT_WINNERS_REMINDER:
      return 'Click to final select or confirm your event winners'
    case NOTIFICATION_TYPES.NEW_EVENT_DESIGNS:
      return 'Click to check your event.'
    case NOTIFICATION_TYPES.DAILY_DESIGN_WINNER:
      return notification.subTitle
    case NOTIFICATION_TYPES.MULTI_GIFT:
    case NOTIFICATION_TYPES.MULTI_LIKE:
      const text = notification.alert.split(' and ')

      return (
        <>
          <b>{text[0]}</b> and {text[1]}
        </>
      )
    default:
      return notification.alert
  }
}

export const getNotificationBannerDescription = notification => {
  switch (notification.type) {
    case NOTIFICATION_TYPES.DAILY_TASK_REWARD:
    case NOTIFICATION_TYPES.WELCOME_GIFT:
      return `+${notification.coinReward} Dcoins reward is ready as a ${notification.alert}!`
    case NOTIFICATION_TYPES.CHALLENGE_REWARD:
      return notification.alert
    case NOTIFICATION_TYPES.EVENT_WINNER:
      return (
        <>
          Congrats! You just earned
          <br />
          <strong>{notification.coinReward} Dcoins</strong> reward and <br />
          <strong>MyEvent Winner badge</strong>
        </>
      )
    case NOTIFICATION_TYPES.USER_EVENT_REWARD:
      return (
        <>
          Congrats! You just earned
          <br />
          <strong>{notification.coinReward} Dcoins</strong> from the
          <br />
          {notification?.userEvent?.title || 'user event'}!
        </>
      )
    default:
      return notification.alert
  }
}

export const getNotificationBannerIllustration = (notification, activeUser) => {
  switch (notification.type) {
    case NOTIFICATION_TYPES.DAILY_TASK_REWARD:
    case NOTIFICATION_TYPES.CHALLENGE_REWARD:
    case NOTIFICATION_TYPES.WELCOME_GIFT:
      return <img src={coinsImg} alt="coins" />
    case NOTIFICATION_TYPES.BADGE_REWARD:
      return (
        <img
          src={
            notification.badge.imageFiles[
              notification.badge.imageFiles.length - 1
            ].url
          }
          alt="Badge"
        />
      )
    default:
      return (
        <ProfileImage
          user={activeUser}
          userLevel={activeUser.userLevel}
          size={66}
        />
      )
  }
}

export const NotificationResolver = ({ onClick, notification }) => {
  const props = {
    onClick,
    notification
  }

  switch (notification.type) {
    case NOTIFICATION_TYPES.CHALLENGE_ENDED:
    case NOTIFICATION_TYPES.CHALLENGE_ENDED_GLOBAL:
    case NOTIFICATION_TYPES.CHALLENGE_REMINDER:
    case NOTIFICATION_TYPES.NEW_CHALLENGE_GLOBAL:
    case NOTIFICATION_TYPES.ITEM_BUNDLE_SALE_GLOBAL:
    case NOTIFICATION_TYPES.WEEKLY_REPORT:
    case NOTIFICATION_TYPES.RELEASE:
    case NOTIFICATION_TYPES.AVATAR:
    case NOTIFICATION_TYPES.NEW_USER:
    case NOTIFICATION_TYPES.GIFT:
      return <BannerNotification showIndicator={false} {...props} />
    case NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL:
      return (
        <BannerNotification
          showIndicator={false}
          userEventWinners={true}
          {...props}
        />
      )
    case NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL:
      return (
        <BannerNotification
          showIndicator={false}
          {...props}
          userEventLive={true}
        />
      )
    case NOTIFICATION_TYPES.LEVEL_UP:
    case NOTIFICATION_TYPES.BADGE_REWARD:
    case NOTIFICATION_TYPES.CHALLENGE_REWARD:
    case NOTIFICATION_TYPES.TOP_RANK:
    case NOTIFICATION_TYPES.NEW_USER_TOP_RANK:
    case NOTIFICATION_TYPES.TOP_INFLUENCER:
    case NOTIFICATION_TYPES.NEW_USER_TOP_INFLUENCER:
    case NOTIFICATION_TYPES.MOST_VOTES_IN_CHALLENGES:
    case NOTIFICATION_TYPES.NEW_USER_MOST_VOTES_IN_CHALLENGES:
    case NOTIFICATION_TYPES.TOP_GIFTS:
    case NOTIFICATION_TYPES.TOP_GIFTS_ALL_TIME:
    case NOTIFICATION_TYPES.TOP_GIFTS_NEW_USER:
    case NOTIFICATION_TYPES.TOP_BADGES:
    case NOTIFICATION_TYPES.WELCOME_GIFT:
    case NOTIFICATION_TYPES.MODIFY_EVENT:
    case NOTIFICATION_TYPES.EVENT_WINNERS_REMINDER:
    case NOTIFICATION_TYPES.EVENT_SCHEDULED:
    case NOTIFICATION_TYPES.EVENT_LIVE:
    case NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL:
    case NOTIFICATION_TYPES.USER_EVENT_REWARD:
    case NOTIFICATION_TYPES.EVENT_WINNER:
    case NOTIFICATION_TYPES.NEW_EVENT_DESIGNS:
      return <BannerNotification showInfo {...props} />
    case NOTIFICATION_TYPES.DAILY_DESIGN_WINNER:
    case NOTIFICATION_TYPES.NEW_USER_DAILY_PICK:
      return <BannerNotification challengeWinner {...props} />
    default:
      return <SmallNotification {...props} />
  }
}
