import Head, { default as Helmet } from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

const HelmetContainer = () => {
  const router = useRouter()

  if (router.pathname.includes('blog') || router.pathname === '/') return null

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta
          name="keywords"
          content="Online Home Decor Websites, Best Home Decor Websites, Online Home Decor Stores, Small Home Decor Items, Virtual Staging Companies, Virtual Interior Design Online, Virtual Interior Decorator, Virtual Interior Designers, Best Virtual Interior Design, Virtual Design Service, Virtual Design Index, Best Interior Design Apps, Virtual Interior Design Index, Free Decorating Index, Best Home Interior Design Index, Best Interior Decorating Index, Virtual Interior Designer Index, Kitchen Floating Shelves Décor, Small Apartment Kitchen Ideas"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="www.decormatters.com" />
        <meta
          name="twitter:image"
          content="https://s3.amazonaws.com/decormatters-dev-web/assets/seo/seo-logo@3x.png"
        />
        <meta property="og:url" content="https://www.decormatters.com" />
        <meta property="og:site_name" content="DecorMatters" />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/decormatters-dev-web/assets/seo/seo-logo@3x.png"
        />
        <meta
          name="google-site-verification"
          content="tc8tm4ArNtKEDRKnlRcx3Yci9kajD8h5x1vtRdrbOM0"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "DecorMatters",
            "url": "https://www.decormatters.com",
            "logo": "https://static.wixstatic.com/media/8f44c0_90586450211a4c29a6eba1a45449eb38~mv2_d_8170_8170_s_4_2.png/v1/fill/w_102,h_100,al_c,usm_0.66_1.00_0.01,enc_auto/Logo%20Colored_10x.png"
          }`}
        </script>
      </Head>
    </>
  )
}

export default HelmetContainer
