export const CHALLENGE_TYPES = {
  DAILY: 'daily',
  STAGING: 'staging',
  PRO: 'pro',
  SERIES: 'series',
  WEEKEND: 'weekend',
  LIMITED: 'limited',
  THROWBACK: 'throwback',
  CLASSIC: 'classic',
  SPECIAL: 'special',
  POPUP: 'popup',
  NEW_USER: 'newUser',
  GENERAL: 'design',
  LEVEL: 'level',
  VACATION: 'vacation'
}
