import { useQuery } from 'react-query'
import { NOTIFICATION_SECTIONS } from '../constants/NotificationTypeConstants'
import { getNotifications } from '../redux/apis'

export const useNewNotifications = activeTab =>
  useQuery(
    ['GET_USER_NOTIFICATIONS'],
    async () => {
      const res = await getNotifications({
        ...NOTIFICATION_SECTIONS.YOU,
        section: 'new'
      })

      return res
    },
    {
      enabled: activeTab?.type === 'you'
    }
  )
