import * as React from 'react'
import {
  QuickSearchContentWrapper,
  BlogCard,
  BlogPostCoverImage,
  BlogPostTitle,
  PopularCard,
  RecentSearchTag,
  RemoveRecentsButton,
  SearchCardsGrid,
  SearchSubtitle,
  SearchTitleLine,
  SearchResultsList,
  SearchResultLine,
  PostIcon,
  UserIcon
} from './QuickSearchContent.styles'
import removeRecentsImg from '../../assets/removeRecents.svg'
import { useEffect, useState } from 'react'
import { LOCAL_STORAGE_KEY } from '../../constants/SearchConstants'
import {
  usePopularHashtags,
  useRecommendedSearches
} from '../../queries/search'
import { useDispatch, useSelector } from 'react-redux'
import { getPopularHashtags } from '../../redux/actions/PopularHashTagsAction'
import { useRecentSearches } from '../../hooks/useRecentSearches'
import { useBlogSearch, useRecommendedBlogposts } from '../../queries/blog'
import Link from 'next/link'
import { LoadingIndicator } from '../Loading'
import { SearchSuggestions } from './SearchSuggestions'
import { RecentSearches } from './RecentSearches'
import { PopularHashtags } from './PopularHashtags'

export const LatestBlogPosts = ({ onClose }) => {
  const recommendedBlogPosts = useRecommendedBlogposts()

  return (
    <>
      <SearchTitleLine>
        <SearchSubtitle>Recommended in DecorMatters' Blog</SearchSubtitle>
      </SearchTitleLine>
      <SearchCardsGrid blogs>
        {recommendedBlogPosts.isLoading &&
          new Array(3).fill(0).map((_, i) => (
            <BlogCard key={i}>
              <BlogPostCoverImage as="div" placeholder="true" />
              <BlogPostTitle as="div" placeholder="true" />
            </BlogCard>
          ))}
        {recommendedBlogPosts.data &&
          recommendedBlogPosts.data.map(post => (
            <Link key={post.id} href={`/blog/${post.attributes.slug}`}>
              <BlogCard onClick={onClose}>
                <BlogPostCoverImage
                  src={post.attributes.cover_image.data.attributes.url}
                  alt=""
                />
                <BlogPostTitle>{post.attributes.title}</BlogPostTitle>
              </BlogCard>
            </Link>
          ))}
      </SearchCardsGrid>
    </>
  )
}
