import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_HASH_TAGS } from '../../constants/ApiEndpoints'
import { api } from '../../utils/fetchUtils'

const PAGE_LIMIT = 10

export const fetchHash = createAsyncThunk(
  'hash/fetchHash',
  async (props, { getState }) => {
    //console.log("[hashReducer][fetchHash]")
    const { page, end } = getState().hashtags
    if (end === true) return []
    const body = {
      skip: page * PAGE_LIMIT,
      pageLimit: PAGE_LIMIT
    }
    let response = await api(GET_HASH_TAGS, JSON.stringify(body))

    return response.result.hashtags
  }
)

const initialState = {
  hashtags: [],
  page: 0,
  end: false
}

const hash = createSlice({
  name: 'hash',
  initialState,
  reducers: {
    clear: (state, action) => {
      state.hashtags = []
      state.page = 0
      state.end = false
    }
  },
  extraReducers: {
    [fetchHash.fulfilled]: (state, action) => {
      if (state.end === true) return
      state.page += 1
      state.hashtags = [...state.hashtags, ...action.payload]
      if (action.payload.length < PAGE_LIMIT - 1) state.end = true
    }
  }
})

export const { clear } = hash.actions

export default hash.reducer
