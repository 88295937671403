import styled, { keyframes } from 'styled-components'
import bannerBgImg from '../../assets/membership/membershipBannerBg.svg'

const popupContentAnimation = keyframes`
  0% {
    margin-top: -110%;
  }
  100% {
    margin-top: 0;
  }
`

export const ProfilePopupContent = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -110%;
  animation: ${popupContentAnimation} 0.2s ease-in-out forwards;

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 375px;
    height: 440px;
    position: fixed;
    top: 60px;
    right: 14px;
    z-index: 100;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export const AvatarContainer = styled.button`
  border: 0;
  background: none;
  position: relative;
  width: 180px;
  height: 130px;
  transform: scale(0.5);
`

export const Username = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`

export const Email = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #888888;
  margin-top: 8px;
`

export const UserInfoSlots = styled.div`
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
`

export const UserInfoSlot = styled.div`
  padding: 18px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #000000;
  height: 100%;
  box-sizing: border-box;

  & span {
    height: 14px;
  }
`

export const UserInfoSlotDivider = styled.div`
  width: 1px;
  background: #dadce0;
  content: '';
  height: 56px;
`

export const UserSlotInfoBold = styled.span`
  margin-left: 8px;
  font-weight: 500;
`

export const UserInfoSlotIcon = styled.img`
  margin-left: 6px;
  width: 20px;
  height: 20px;
`

export const PopupLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 12px;
  width: 100%;
  text-align: center;
`

export const LogOutButton = styled.button`
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 8px 48px;
  color: #888888;
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  margin-top: 16px;
  margin-bottom: 28px;
`

export const AvatarAccessory = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  z-index: 5;
  left: -7px;
  top: -7px;
`

export const MembershipBanner = styled.div`
  width: calc(100% - 36px);
  margin: auto;
  box-sizing: border-box;
  height: 53px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  padding: 0 9px;

  color: #fff;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  background: url(${bannerBgImg}),
    linear-gradient(270deg, #ff5e6d -63.99%, #4a40bd 96.11%);
  background-size: cover;
  background-position: center left;
  cursor: pointer;

  @media (min-width: 744px) {
    flex-shrink: 0;
  }

  & img {
    margin-right: 29px;
  }
`

export const MembershipButton = styled.div`
  display: flex;
  width: 106px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background-color: #ff5e6d;

  color: #fff;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
  cursor: pointer;
`
