import React from 'react'
import topIconImg from '../../assets/Top.svg'
import {
  FloatingButtonIcon,
  FloatingButtonWrapper,
  Tooltip
} from './FloatingButtons.styles'

export const FloatingScrollButton = () => {
  return (
    <FloatingButtonWrapper
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const topBox = document.getElementById('scroll-box')
        const postsBox = document.getElementById('posts-scroll-box')
        const element = postsBox || topBox

        element?.scrollTo({ top: 0, behavior: 'smooth' })
      }}
    >
      <FloatingButtonIcon src={topIconImg} alt="" />
      <Tooltip>Back to Top</Tooltip>
    </FloatingButtonWrapper>
  )
}
