import { useState, createContext, useContext, useEffect, useCallback, useRef } from 'react'
import { getUserToken } from '../utils/fetchUtils'
import { batch, useDispatch } from 'react-redux'
import { refreshUser } from '../routes/Profile/store/profileReducer'
import { setWelcomeTasks, setWelcomeRecord, setTasks, loaded as tasksLoaded } from '../redux/communityReducers/CheckInReducer'

import { getAvailableChallengesAndUserEvents, getDailyTasks, getMe, getNewUserTaskStatuses, getNewUserTasks } from '../redux/apis'
import _ from 'lodash'

export const DecorContext = createContext()

const eventObject = {
  joined: {},
  started: {}
}

const homeUploadMyRoom = {
  membership: 'weekly',
  destination: 'myroom page url or fucntion to upload and then go to my room page'
}

const DecorProvider = props => {
  const INTERVAL = 30000
  const BACKGROUND_INTERVAL = 300000

  const dispatch = useDispatch()

  //----------------------------------------------------------------------------------------------------------------------------------
  //
  const [homeMyRoomUploadImageFile, setHomeMyRoomUploadImageFile] = useState()
  const [showMembershipAfterSignOn, setShowMembershipAfterSignOn] = useState(true)

  /*
  need
  - go to membershp after sign on/log on
  - which membership to auto show up


  */
  //----------------------------------------------------------------------------------------------------------------------------------
  //
  const [eventStatuses, setEventStatuses] = useState(_.cloneDeep(eventObject))
  const isNewUserTasksCompleted = useRef(false)

  //----------------------------------------------------------------------------------------------------------------------------------
  //
  //

  const refreshTasks = useCallback(async () => {
    //----------------------------------------------------
    // Check user new user and daily task statuses
    let nutresult = []
    let nutsresult = {}

    if (!isNewUserTasksCompleted.current) {
      // List of available new user tasks
      nutresult = await getNewUserTasks()
      //console.log(result)

      // Current statuses of new user tasks
      nutsresult = await getNewUserTaskStatuses()
      //console.log(result)
    }

    // List of available daily tasks and current statuses
    const gdtresult = await getDailyTasks()
    //console.log(result)

    return {
      nutresult,
      nutsresult,
      gdtresult
    }
  }, [])

  const checkNewUserTasksCompletion = useCallback((tasks, rewards) => {
    if (!tasks) return true
    if (!rewards?.rewardedWgIds) return true
    if (tasks.length === rewards.rewardedWgIds.length) return true
    return false
  }, [])

  //----------------------------------------------------------------------------------------------------------------------------------
  //
  //

  const activeSessionPull = useCallback(async () => {
    //----------------------------------------------------
    // Refresh user data

    const gmresult = await getMe()

    //----------------------------------------------------
    // Check user challange and user event statuses

    const gacaeuresult = await getAvailableChallengesAndUserEvents()

    let eObj = _.cloneDeep(eventObject)

    if (gacaeuresult.userEventStatuses) {
      gacaeuresult.userEventStatuses.started.forEach(entry => {
        eObj.started[entry] = 'challenge'
      })

      gacaeuresult.userEventStatuses.joined.forEach(entry => {
        eObj.joined[entry] = 'challenge'
      })
    }

    if (gacaeuresult.userChallengeStatuses) {
      gacaeuresult.userChallengeStatuses.started.forEach(entry => {
        eObj.started[entry] = 'user'
      })

      gacaeuresult.userChallengeStatuses.joined.forEach(entry => {
        eObj.joined[entry] = 'user'
      })
    }

    setEventStatuses(eObj)
    const { nutresult, nutsresult, gdtresult } = await refreshTasks()
    const welcomeCompleteStatus = checkNewUserTasksCompletion(nutresult, nutsresult)

    batch(() => {
      dispatch(refreshUser(gmresult))
      if (!isNewUserTasksCompleted.current) {
        dispatch(setWelcomeTasks(nutresult))
        dispatch(setWelcomeRecord(nutsresult))
        isNewUserTasksCompleted.current = welcomeCompleteStatus
      }
      dispatch(setTasks(gdtresult))
      dispatch(tasksLoaded())
    })
  }, [dispatch, refreshTasks, checkNewUserTasksCompletion])

  const inactiveSessionPull = useCallback(async () => {}, [])

  const pull = useCallback(async () => {
    if (!getUserToken()) await inactiveSessionPull()
    else await activeSessionPull()
  }, [activeSessionPull, inactiveSessionPull])

  //----------------------------------------------------------------------------------------------------------------------------------
  //
  //

  useEffect(() => {
    pull()

    let intervalId

    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(intervalId)
        intervalId = setInterval(pull, BACKGROUND_INTERVAL)
      } else {
        clearInterval(intervalId)
        intervalId = setInterval(pull, INTERVAL)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    handleVisibilityChange()

    return () => {
      clearInterval(intervalId)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [pull])

  //----------------------------------------------------------------------------------------------------------------------------------
  //
  //

  const manualRefreshTasks = async () => {
    const { nutresult, nutsresult, gdtresult } = await refreshTasks()
    const welcomeCompleteStatus = checkNewUserTasksCompletion(nutresult, nutsresult)

    batch(() => {
      if (!isNewUserTasksCompleted.current) {
        dispatch(setWelcomeTasks(nutresult))
        dispatch(setWelcomeRecord(nutsresult))
        isNewUserTasksCompleted.current = welcomeCompleteStatus
      }
      dispatch(setTasks(gdtresult))
      dispatch(tasksLoaded())
    })

    return
  }

  return <DecorContext.Provider value={{ showMembershipAfterSignOn, setShowMembershipAfterSignOn, homeMyRoomUploadImageFile, setHomeMyRoomUploadImageFile, eventStatuses, manualRefreshTasks }} {...props} />
}

const useDecor = () => useContext(DecorContext)
export { DecorProvider, useDecor }
