import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 60px;
  border-bottom: none;
  box-shadow: ${props =>
    props.slim ? '0px 4px 4px rgba(0, 0, 0, 0.08)' : 'none'};
  z-index: 1000;
  background: white;
  z-index: 10;
  flex-shrink: 0;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`

export const AnimationWrapper = styled.div`
  perspective: 2000px;
`

export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  & > button {
    margin-right: 10px;
  }
`

export const LogoWrapper = styled.div`
  padding-left: 15px;
  white-space: nowrap;
  margin: auto;
  padding-right: 12px;
  width: auto;
  cursor: pointer;

  & img {
    height: 30px;
    margin-bottom: -5px;
  }
`

export const HeaderLinks = styled.ul`
  padding-left: 24px;
  margin-top: ${props => (props.loggedIn ? '15px' : 'unset')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;

  @media screen and (max-width: 720px) {
    display: none;
  }
`

export const HeaderLink = styled.li`
  display: inline-flex;
  align-items: center;
  margin-right: 35px;
  height: 100%;
  border-bottom: 3px solid ${props => (props.active ? 'black' : 'transparent')};
  cursor: pointer;
  padding: 0 5px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${props => (props.active ? '#000000' : '#979797')};
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  position: relative;
  font-size: 16px;
  line-height: 1;
  transition-property: color, border-bottom;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  background-color: transparent;

  & span {
    margin-bottom: -2px;
  }

  &:hover {
    border-bottom: 3px solid black;
    color: black;
  }

  @media only screen and (max-width: 992px) {
    & a {
      font-size: 13px;
    }
  }
`

export const AvatarImage = styled.img`
  width: 40px;
  height: 40px;
  border: 2px solid ${props => (props.active ? '#FF5E6D' : 'transparent')};
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 28px;
  cursor: pointer;
`

export const NotificationsButton = styled.button`
  border: none;
  padding: 0;
  margin: 0 !important;
  cursor: pointer;
  width: 45px;
  height: 45px;
  background: ${props => (props.active ? '#fafafa' : 'transparent')};
  border-radius: 50%;
`

export const LongLogo = styled.img`
  @media screen and (max-width: 1280px) {
    display: none;
  }
`
export const ShortLogo = styled.img`
  @media screen and (min-width: 1281px) {
    display: none;
  }
`

export const SearchButton = styled.button`
  border: 0;
  background: none;
  margin-left: auto;
`

export const SignUpButton = styled.div`
  background: linear-gradient(
    270deg,
    #ff9d74 0%,
    #ff5e6d 37.4%,
    #ff4f7e 74.69%,
    #ff6d90 100%
  );
  border-radius: 37px;
  width: 148px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      linear-gradient(
        270deg,
        #ff9d74 0%,
        #ff5e6d 37.4%,
        #ff4f7e 74.69%,
        #ff6d90 100%
      );
  }
`

export const DailyRewardsButton = styled.button`
  width: 186px;
  height: 40px;
  border-radius: 34px;
  background: linear-gradient(
    270deg,
    #ff9d74 0%,
    #ff5e6d 37.4%,
    #ff4f7e 74.69%,
    #ff6d90 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  gap: 8px;
  fill: white;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      linear-gradient(
        270deg,
        #ff9d74 0%,
        #ff5e6d 37.4%,
        #ff4f7e 74.69%,
        #ff6d90 100%
      );
  }
`
