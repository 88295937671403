import Link from 'next/link'
import React from 'react'
import menuLineArrowImg from '../../assets/menuLineArrow.svg'
import questionMarkImg from '../../assets/questionMark.svg'
import questionMarkHoverImg from '../../assets/questionMarkHover.svg'
import FloatingMenuSocialIcons from '../FloatingMenuSocialIcons'
import {
  FloatingButtonIcon,
  FloatingButtonIconFocused,
  FloatingButtonWrapper,
  FloatingMenuLink,
  LinksGrid,
  LongMenuLine,
  MenuTooltip,
  Separator,
  SocialButtons
} from './FloatingButtons.styles'

export const FloatingMenuButton = () => {
  return (
    <FloatingButtonWrapper aria-label="Menu & Help" hasActiveState>
      <FloatingButtonIcon src={questionMarkImg} alt="" width={75} height={75} />
      <FloatingButtonIconFocused
        src={questionMarkHoverImg}
        alt=""
        width={75}
        height={75}
      />
      <MenuTooltip>
        <Link href="/help-center" passHref>
          <LongMenuLine>
            <span>Visit the Help Center</span>
            <img src={menuLineArrowImg} alt="" />
          </LongMenuLine>
        </Link>
        <Separator />
        <LinksGrid>
          <Link href="/community/featured" passHref>
            <FloatingMenuLink>Community</FloatingMenuLink>
          </Link>
          <Link href="/" passHref>
            <FloatingMenuLink>Create</FloatingMenuLink>
          </Link>
          <FloatingMenuLink href="https://www.company.decormatters.com/blog">
            Blog
          </FloatingMenuLink>
          <FloatingMenuLink
            href="https://www.company.decormatters.com"
            target="_blank"
          >
            About
          </FloatingMenuLink>
          <Link href="/terms" passHref>
            <FloatingMenuLink>Terms of Use</FloatingMenuLink>
          </Link>
          <Link href="/policy" passHref>
            <FloatingMenuLink>Privacy Policy</FloatingMenuLink>
          </Link>
        </LinksGrid>
        <SocialButtons>
          <FloatingMenuSocialIcons />
        </SocialButtons>
      </MenuTooltip>
    </FloatingButtonWrapper>
  )
}
