import React from 'react'
import chairAnimationImg from '../../assets/lounge.svg'
import {
  ChairElement,
  LoadingInnerElement,
  LoadingWrapper
} from './Loading.styles'

export const LoadingIndicator = ({ loading }) => {
  if (!loading) return null

  return (
    <LoadingWrapper>
      <LoadingInnerElement />
      <LoadingInnerElement />
    </LoadingWrapper>
  )
}

export const LoadingIndicator2 = ({ loading }) => {
  if (!loading) return null

  return (
    <ChairElement>
      <img src={chairAnimationImg} alt="loading"></img>
    </ChairElement>
  )
}
