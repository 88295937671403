import { useHeaderOptions } from './useHeaderOptions'

export const useCurrentRoute = () => {
  const { browserPath, ...headerOptions } = useHeaderOptions()

  if (!headerOptions) return { browserPath }

  const { currentRoute } = headerOptions

  return { navigationRoute: currentRoute, browserPath }
}
