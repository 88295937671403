import {
  ADD_FOLLOW,
  GET_FOLLOWERS_FOR_USER,
  GET_FOLLOWING_FOR_USER,
  GET_FOLLOWING_LIST,
  GET_SEARCH,
  UNFOLLOW
} from '../../constants/FollowingContants'

const initialState = {
  following: [],
  search: [],
  searchQuery: '',
  forUser: {
    following: [],
    followers: []
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOLLOWING_LIST: {
      return {
        ...state,
        following: action.list.followingIds
      }
    }
    case UNFOLLOW: {
      return {
        ...state,
        following: state.following.filter(e => e !== action.id)
      }
    }
    case ADD_FOLLOW: {
      return {
        ...state,
        following: state.following.concat(action.id)
      }
    }
    case GET_FOLLOWERS_FOR_USER: {
      return {
        ...state,
        forUser: {
          following: [],
          followers:
            state.forUser.followers.length > 0
              ? state.forUser.followers.concat(action.list)
              : action.list
        }
      }
    }
    case GET_SEARCH: {
      return {
        ...state,
        search:
          action.searchQuery === state.searchQuery
            ? state.search.concat(action.list)
            : action.list,
        searchQuery: action.searchQuery
      }
    }
    case GET_FOLLOWING_FOR_USER: {
      return {
        ...state,
        forUser: {
          followers: [],
          following:
            state.forUser.following.length > 0
              ? state.forUser.following.concat(action.list)
              : action.list
        }
      }
    }
    default:
      return state
  }
}

export default reducer
