import Link from 'next/link'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  COMMENT_TYPES,
  NOTIFICATION_TYPES
} from '../../../constants/NotificationTypeConstants'
import {
  FollowAction,
  unFollowAction
} from '../../../redux/actions/FollowingActions'
import ProfileImage from '../../../routes/Profile/components/profile/ProfileImage'
import formatDate from '../../../utils/formatDate'
import {
  getNoitificationDescription,
  getNoitificationLink
} from '../../../utils/notifications'
import FollowButton from '../../common/FollowButton'
import {
  ActionButtonsGrid,
  ActionImage,
  EventText,
  InfoPart,
  LikeButton,
  MultiNotificationAvatars,
  NotificationTime,
  ReplyButton,
  SmallNotificationWrapper,
  TextSection,
  WeeklyReportAvatar
} from './SmallNotification.styles'
import { openCommentModal } from '../../../redux/communityReducers/PostActionsReducer'
import likeCommentImg from '../../../assets/notifications/likeComment.svg'
import likedCommentImg from '../../../assets/notifications/likedComment.svg'
import weeklyReportAvatar from '../../../assets/weekly-report-avatar.png'
import weeklyReportImage from '../../../assets/weekly-report-image.png'
import LikeBtn from '../../social/LikeBtn'

const REPLY_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT,
  NOTIFICATION_TYPES.GIFT,
  NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_REPLY
]

const MULTI_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPES.MULTI_GIFT,
  NOTIFICATION_TYPES.MULTI_LIKE
]

export const SmallNotification = ({ onClick, notification }) => {
  const dispatch = useDispatch()
  const {
    sourceUser,
    createdAt,
    userInspiration,
    type,
    userInspirationComment
  } = notification
  const link = getNoitificationLink(notification)
  const { following } = useSelector(state => state.follows)
  const followState =
    following &&
    notification.sourceUser &&
    following.includes(notification.sourceUser.objectId)
      ? true
      : false
  const [isFollowed, setIsFollowed] = useState(followState)

  const handleFollow = e => {
    e.preventDefault()

    if (isFollowed) {
      dispatch(unFollowAction(notification.sourceUser.objectId))
    } else {
      dispatch(FollowAction(notification.sourceUser.objectId))
    }

    setIsFollowed(!isFollowed)

    return false
  }

  const hasActions = REPLY_NOTIFICATION_TYPES.includes(type)
  const isWeeklyReport = NOTIFICATION_TYPES.WEEKLY_REPORT_SMALL === type

  const avatarComponent = useMemo(() => {
    switch (notification.type) {
      case NOTIFICATION_TYPES.MULTI_GIFT:
      case NOTIFICATION_TYPES.MULTI_LIKE:
        return (
          <MultiNotificationAvatars>
            {notification.avatarUrls.map((avatarUrl, index) => (
              <img src={avatarUrl} key={index} />
            ))}
          </MultiNotificationAvatars>
        )
      default:
        if (isWeeklyReport)
          return <WeeklyReportAvatar src={weeklyReportAvatar} />

        return (
          <ProfileImage
            size={42}
            user={sourceUser}
            userLevel={sourceUser ? Math.floor(sourceUser?.level) : 0}
            small
            clickable
            showLevel={sourceUser && !!sourceUser.level}
          />
        )
    }
  }, [weeklyReportAvatar, sourceUser, notification.type])

  const avatarLinkHref = useMemo(() => {
    switch (notification.type) {
      case NOTIFICATION_TYPES.MULTI_LIKE:
      case NOTIFICATION_TYPES.MULTI_GIFT:
        return `/design/${notification.userInspiration.objectId}`
      default:
        return notification.sourceUser
          ? `/dm/${notification.sourceUser.objectId}`
          : '/'
    }
  }, [notification])

  return (
    <SmallNotificationWrapper isWeeklyReport={isWeeklyReport}>
      <Link href={link || ''}>
        <InfoPart onClick={onClick} hasActions={hasActions}>
          <Link href={avatarLinkHref}>{avatarComponent}</Link>
          <TextSection>
            <EventText>
              {isWeeklyReport ? (
                <>
                  <b>{notification.title}</b>
                  <br />
                  Click to review your weekly report.
                </>
              ) : (
                ''
              )}
              {getNoitificationDescription(notification)}{' '}
              <NotificationTime>{formatDate(createdAt)}</NotificationTime>
            </EventText>
            {hasActions && (
              <ActionButtonsGrid>
                <ReplyButton
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()

                    onClick()

                    dispatch(
                      openCommentModal({
                        data: userInspiration,
                        id: userInspiration.objectId
                      })
                    )
                  }}
                >
                  {type === NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT &&
                  userInspirationComment?.type === COMMENT_TYPES.GIFT
                    ? 'Say Thanks'
                    : 'Reply'}
                </ReplyButton>{' '}
                <LikeBtn
                  commentId={userInspirationComment?.objectId}
                  defaultLiked={userInspirationComment?.liked || false}
                  renderButton={({ active, likeClick }) => (
                    <LikeButton active={active} onClick={likeClick}>
                      <img
                        src={active ? likedCommentImg : likeCommentImg}
                        alt=""
                      />
                    </LikeButton>
                  )}
                />
              </ActionButtonsGrid>
            )}
          </TextSection>
        </InfoPart>
      </Link>
      {isWeeklyReport && (
        <ActionImage onClick={onClick} src={weeklyReportImage} alt="" />
      )}
      {userInspiration && userInspiration.idea && (
        <Link onClick={onClick} href={`/design/${userInspiration.objectId}`}>
          <ActionImage src={userInspiration.idea.cfThumbImageUrl} alt="" />
        </Link>
      )}
      {type === NOTIFICATION_TYPES.FOLLOW && (
        <FollowButton onClick={handleFollow} isFollowed={isFollowed} />
      )}
    </SmallNotificationWrapper>
  )
}
