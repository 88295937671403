import {
  FOLLOW_USER,
  GET_ALL_FOLLOWING_IDS,
  GET_FOLLOWERS,
  GET_FOLLOWINGS,
  SEARCH_USER,
  UNFOLLOW_USER
} from '../../constants/ApiEndpoints'
import {
  ADD_FOLLOW,
  GET_FOLLOWERS_FOR_USER,
  GET_FOLLOWING_FOR_USER,
  GET_FOLLOWING_LIST,
  GET_SEARCH,
  UNFOLLOW
} from '../../constants/FollowingContants'
import { parseUserId } from '../../utils/authLocalStorage'
import { parseHeaders } from '../../utils/fetchUtil'
import { logEvent } from '../../utils/firebase'
import { gotoLogIn } from './AuthActions'

export function getFollowList() {
  const appendant = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({
      userId: parseUserId()
    })
  }

  return async (dispatch, state) => {
    if (state().auth.isAuthenticated === false) {
      return
    }
    try {
      const response = await fetch(GET_ALL_FOLLOWING_IDS, appendant)
      const responseJson = await response.json()
      dispatch(getFollow(responseJson.result))
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}

export const getFollowersForUser = userId => {
  return async (dispatch, state) => {
    const currentState = state()

    if (currentState.auth.isAuthenticated === false) {
      return
    }

    const appendant = {
      method: 'POST',
      mode: 'cors',
      headers: parseHeaders(),
      body: JSON.stringify({
        userId,
        skip: currentState.follows.forUser.followers.length || 0
      })
    }

    try {
      const response = await fetch(GET_FOLLOWERS, appendant)
      const responseJson = await response.json()
      dispatch(getFollowers(responseJson.result.followers))
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}

export const getFollowingForUser = userId => {
  return async (dispatch, state) => {
    const currentState = state()

    if (currentState.auth.isAuthenticated === false) {
      return
    }

    const appendant = {
      method: 'POST',
      mode: 'cors',
      headers: parseHeaders(),
      body: JSON.stringify({
        userId,
        skip: currentState.follows.forUser.following.length || 0
      })
    }

    try {
      const response = await fetch(GET_FOLLOWINGS, appendant)
      const responseJson = await response.json()
      dispatch(getFollowing(responseJson.result.followings))
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}

export const searchUsers = (startsWithText, callback) => {
  return async (dispatch, state) => {
    const currentState = state()

    if (currentState.auth.isAuthenticated === false) {
      return
    }

    const appendant = {
      method: 'POST',
      mode: 'cors',
      headers: parseHeaders(),
      body: JSON.stringify({
        startsWithText,
        pageLimit: 15,
        skip:
          currentState.follows.searchQuery === startsWithText
            ? currentState.follows.search.length
            : 0
      })
    }

    try {
      const response = await fetch(SEARCH_USER, appendant)
      const responseJson = await response.json()
      if (callback) callback(responseJson)
      dispatch(getSearch(responseJson.result.users, startsWithText))
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}

export function FollowAction(userIdToFollow) {
  const appendant = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({
      userIdToFollow: userIdToFollow
    })
  }

  return async (dispatch, state) => {
    if (state().auth.isAuthenticated === false) {
      dispatch(gotoLogIn())
      return
    }
    try {
      const response = await fetch(FOLLOW_USER, appendant)
      await response.json()

      logEvent('follow', {
        content_type: 'user',
        content_id: userIdToFollow
      })

      const tag = 'design_follow_clicked'

      logEvent(tag)

      dispatch(follow(userIdToFollow))
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}
export function unFollowAction(userIdToUnfollow) {
  const appendant = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({
      userIdToUnfollow: userIdToUnfollow
    })
  }

  return async dispatch => {
    try {
      const response = await fetch(UNFOLLOW_USER, appendant)
      await response.json()

      logEvent('unfollow', {
        content_type: 'user',
        content_id: userIdToUnfollow
      })

      const tag = 'design_unfollow_clicked'

      logEvent(tag)

      dispatch(unFollow(userIdToUnfollow))
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}
export const getFollow = list => {
  return {
    type: GET_FOLLOWING_LIST,
    list: list
  }
}

export const unFollow = userIdToUnfollow => {
  return {
    type: UNFOLLOW,
    id: userIdToUnfollow
  }
}

export const follow = userIdToFollow => {
  return {
    type: ADD_FOLLOW,
    id: userIdToFollow
  }
}

export const getFollowers = list => {
  return {
    type: GET_FOLLOWERS_FOR_USER,
    list: list
  }
}

export const getFollowing = list => {
  return {
    type: GET_FOLLOWING_FOR_USER,
    list: list
  }
}

export const getSearch = (list, searchQuery) => {
  return {
    type: GET_SEARCH,
    list: list,
    searchQuery
  }
}
