import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NAV_WORKSPACE } from '../../redux/constants'
import HeaderMenu from '../HeaderMenu'
import BackButton from '../navs/BackButton'
import { AvatarImage, HeaderGroup, HeaderLink, HeaderLinks, HeaderWrapper, LongLogo, ShortLogo, LogoWrapper, NotificationsButton, SignUpButton, DailyRewardsButton } from './DesktopHeader.styles'
import { getRoute, ROUTES } from '../../constants/navigation'
import dmDLogo from '../../assets/dm-d-logo.svg'
import dmDesktopLogo from '../../assets/dm-logo-long.svg'
import searchImg from '../../assets/Search.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import { useSecondaryMenu } from '../../hooks/useSecondaryMenu'
import { DesktopSearch } from '../DesktopSearch/DesktopSearch'
import { getUserAvatar } from '../../utils/getUserAvatar'
import ProfileFloatingMenu from '../ProfileFloatingMenu'
import NotificationsMenu from '../NotificationsMenu'
import { PrimaryButton, SecondaryClearButton } from '../common/FormControls'
import { logEvent } from '../../utils/firebase'
import { gotoLogIn, gotoSignUp } from '../../redux/actions/AuthActions'
import bellImg from '../../assets/bell.svg'
import BadgeCounter from '../common/BadgeCounter'
import locationChangeEvent from '../../utils/locationChangeEvent'
import SmartLink from '../SmartLink'
import { useUserInfo } from '../../queries/users'
import { ReactComponent as CalendarIcon } from '../../assets/dailyRewards/calendar.svg'
//import { resetAndOpen } from '../../redux/communityReducers/CheckInReducer'
import { useDailyRewardsInfo } from '../../hooks/useDailyRewardsInfo'
import useDailyTasks from '../../hooks/useDailyTasks'

const createRoute = getRoute(ROUTES.HOMEPAGE)
const communityRoute = getRoute(ROUTES.COMMUNITY.FEATURED)
const blogRoute = getRoute(ROUTES.BLOG)
const aboutRoute = getRoute(ROUTES.STATIC_PAGES.ABOUT)

const DesktopHeader = () => {
  const secondaryMenu = useSecondaryMenu()
  const { openDailyTask } = useDailyTasks()
  const auth = useSelector(state => state.auth)
  const navbar = useSelector(state => state.designer.navbar)
  const profile = useSelector(state => state.profile.me)
  //const dynamicUserInfo = useUserInfo()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const [isProfileMenuShown, setIsProfileMenuShown] = useState(false)
  const [isNotificationMenuShown, setIsNotificationMenuShown] = useState(false)
  const [currentLocation, setCurrentLocation] = useState('')
  const [isSearchMode, setIsSearchMode] = useState(false)
  const dispatch = useDispatch()
  const isTablet = !useMediaQuery('desktopWorkspace')
  const { claimedTasks, tasksList, specialTaskList } = useDailyRewardsInfo()

  const isSlim = !secondaryMenu || secondaryMenu.length === 0

  const handleLogIn = () => {
    logEvent('nav_login_clicked')

    dispatch(gotoLogIn())
  }

  const handleSignUp = () => {
    logEvent('nav_signup_clicked')

    dispatch(gotoSignUp())
  }

  useEffect(() => {
    const listener = location => {
      setCurrentLocation(location)
    }

    listener(window.location.pathname)

    locationChangeEvent.subscribe(listener)

    return () => {
      locationChangeEvent.unsubscribe(listener)
    }
  }, [])

  if (navbar === NAV_WORKSPACE) return null

  return (
    <>
      <HeaderWrapper slim={isSlim}>
        <HeaderGroup>
          <LogoWrapper>
            <Link href="/">
              <a style={{ lineHeight: '30px' }}>
                <LongLogo src={dmDesktopLogo} width={154} height={30} alt="Interior Home and Room Design App - DecorMatters" loading="lazy" />
                <ShortLogo src={dmDLogo} width={30} height={30} alt="Interior Home and Room Design App - DecorMatters" loading="lazy" />
              </a>
            </Link>
          </LogoWrapper>

          {(!isSearchMode || !isTablet) && (
            <HeaderLinks loggedIn={!!auth.user}>
              <Link href={createRoute.path}>
                <HeaderLink
                  onClick={() => {
                    const path = locationChangeEvent.currentValue

                    if (path.includes('/templates') || path.includes('/challenges')) {
                      window.location.pathname = '/'
                    }
                  }}
                  active={currentLocation === '/'}
                >
                  <span>Create</span>
                </HeaderLink>
              </Link>
              <Link href={communityRoute.path}>
                <HeaderLink active={currentLocation.includes('community')}>
                  <span>Community</span>
                </HeaderLink>
              </Link>
              <Link href={blogRoute.path}>
                <HeaderLink active={currentLocation.includes(blogRoute.path)}>
                  <span>Blog</span>
                </HeaderLink>
              </Link>
              <HeaderLink
                onClick={() => {
                  window.open(aboutRoute.path)
                }}
              >
                <span>About</span>
              </HeaderLink>
            </HeaderLinks>
          )}
        </HeaderGroup>

        <DesktopSearch isSearchMode={isSearchMode} onBlur={() => setIsSearchMode(false)} />

        {isAuthenticated ? (
          <HeaderGroup>
            <DailyRewardsButton
              onClick={async () => {
                await openDailyTask()
              }}
            >
              <CalendarIcon style={{ width: 24, height: 24 }} />
              Today’s Rewards ({claimedTasks || 0}/{tasksList.length + specialTaskList.length || 8})
            </DailyRewardsButton>
            <NotificationsButton onClick={() => setIsNotificationMenuShown(true)} active={isNotificationMenuShown}>
              <BadgeCounter count={profile?.numUnseenNotifications || 0}>
                <img height="34px" src={bellImg} alt="Notifications" />
              </BadgeCounter>
            </NotificationsButton>
            <AvatarImage onClick={() => setIsProfileMenuShown(!isProfileMenuShown)} active={isProfileMenuShown} src={getUserAvatar(profile)} />
          </HeaderGroup>
        ) : (
          <HeaderGroup>
            <SignUpButton onClick={handleSignUp}>Free Sign Up</SignUpButton>
            <SecondaryClearButton type="button" onClick={handleLogIn}>
              Log In
            </SecondaryClearButton>
          </HeaderGroup>
        )}
      </HeaderWrapper>
      {isAuthenticated && <HeaderMenu secondaryMenu={secondaryMenu} />}
      {isProfileMenuShown && <ProfileFloatingMenu onClose={() => setIsProfileMenuShown(false)} />}
      {isNotificationMenuShown && <NotificationsMenu close={() => setIsNotificationMenuShown(false)} />}
    </>
  )
}

export default DesktopHeader
