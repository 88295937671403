import styled, { keyframes } from 'styled-components'
import bannerBgImg from '../../assets/membership/membershipBannerBg.svg'

const windowAnimation = keyframes`
  0% {
    transform: rotateX(-15deg);
  }
  
  100% {
    transform: rotateX(0);
  }
`

export const AnimationWrapper = styled.div`
  perspective: 2000px;
  z-index: 1000;
  position: absolute;
  right: 20px;
  top: 70px;
`

export const ProfileFloatingMenuWrapper = styled.div`
  width: 230px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  animation: ${windowAnimation} 0.1s ease-in-out forwards;
`

export const EmailSection = styled.div`
  color: #888888;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 16px;
`

export const LinksGroup = styled.ul`
  list-style: none;
  border-top: 1px solid #e5e5e5;
  margin: 0;
  padding: 0;
`

export const GroupedLink = styled.li`
  margin: 0;
  padding: 16px 16px;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #555555;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  & span {
    margin-right: 10px;
  }

  &:hover {
    background: #fafafa;
  }
`

export const LinkIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`

export const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogOutButton = styled.button`
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 8px 48px;
  color: #888888;
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  margin-top: 16px;
  margin-bottom: 28px;
  cursor: pointer;
`

export const MembershipBanner = styled.div`
  width: auto;
  box-sizing: border-box;
  height: 40px;
  background: url(${bannerBgImg}),
    linear-gradient(270deg, #ff5e6d -63.99%, #4a40bd 96.11%);
  border-radius: 5px;
  padding: 6px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 13px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  gap: 13px;
  cursor: pointer;
  padding-right: 19px;

  & img {
    height: 30px;
  }
`
