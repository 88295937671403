import styled from 'styled-components'

export const ActionButtonIcon = styled.img``

export const ActionButton = styled.button`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  width: 64px;
  height: 28px;
  padding: 4px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: ${props => (props.active ? '#FF5E6D' : '#505050')};
  border: none;
  margin-left: 8px;
  transition: background 0.3s ease-in-out;
  box-sizing: border-box;
  cursor: pointer;
  opacity: ${props => (props.dimmed && !props.active ? '0.7' : '1')};

  ${props =>
    props.displayType === 'primary'
      ? `
  
  font-weight: 500;
  background: ${props.loc === 'home' ? 'transparent' : '#F3F3F4'};
  border-radius: 10px;
  width: auto;
  min-width: 80px;
  height: 42px;
  margin-left: 0;
  margin-right: 10px;
  padding: ${props.loc === 'home' ? '0' : '9px 6px'};
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: ${props.active ? '#FF5E6D' : '#0F0D22'};

  ${ActionButtonIcon} {
    height: 24px;
    width: 24px;
    margin-right: 2px;
  }

  &:hover {
    background: #e7e7e9;
  }

  @media screen and (max-width: 700px) {
    min-width: 60px;

    & span {
      font-weight: bold;
    }
  }
  `
      : `
  &:hover {
    background: rgba(255, 255, 255, 1);
  }
  `}

  ${props =>
    props.displayType === 'DesignerMenus' &&
    `
    position: absolute;
    top: 30px;
    right: 8px;
  `}

  ${ActionButtonIcon} {
    filter: ${props =>
      props.active
        ? 'invert(60%) sepia(35%) saturate(6216%) hue-rotate(319deg) brightness(101%) contrast(101%)'
        : 'none'};
  }
`
