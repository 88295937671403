import { CHALLENGE_TYPES } from '../constants/ChallengeConstants'
import { ReactComponent as classicChallenge } from '../assets/challengeTypes/classic.svg'
import { ReactComponent as dailyChallenge } from '../assets/challengeTypes/daily.svg'
import { ReactComponent as limitedChallenge } from '../assets/challengeTypes/limited.svg'
import { ReactComponent as proChallenge } from '../assets/challengeTypes/pro.svg'
import { ReactComponent as popupChallenge } from '../assets/challengeTypes/popup.svg'
import { ReactComponent as seriesChallenge } from '../assets/challengeTypes/series.svg'
import { ReactComponent as specialChallenge } from '../assets/challengeTypes/special.svg'
import { ReactComponent as stagingChallenge } from '../assets/challengeTypes/staging.svg'
import { ReactComponent as throwbackChallenge } from '../assets/challengeTypes/throw_back.svg'
import { ReactComponent as weekendChallenge } from '../assets/challengeTypes/weekend.svg'
import { ReactComponent as levelChallenge } from '../assets/challengeTypes/level.svg'
import { ReactComponent as vacationChallenge } from '../assets/challengeTypes/vacation.svg'
import { ReactComponent as newUserChallenge } from '../assets/challengeTypes/newUser.svg'
import coinIcon from '../assets/coin.svg'
import coinsIcon from '../assets/Coins.svg'

export const getChallengeTypeFromHashtags = challenge => {
  return Object.values(CHALLENGE_TYPES).find(value =>
    challenge.hashtags.includes(value)
  )
}

export const getChallengeTypeLogo = challenge => {
  const type =
    challenge?.type === CHALLENGE_TYPES.GENERAL
      ? getChallengeTypeFromHashtags(challenge)
      : challenge?.type

  switch (type) {
    case CHALLENGE_TYPES.CLASSIC:
      return classicChallenge
    case CHALLENGE_TYPES.DAILY:
      return dailyChallenge
    case CHALLENGE_TYPES.LIMITED:
      return limitedChallenge
    case CHALLENGE_TYPES.POPUP:
      return popupChallenge
    case CHALLENGE_TYPES.PRO:
      return proChallenge
    case CHALLENGE_TYPES.SERIES:
      return seriesChallenge
    case CHALLENGE_TYPES.SPECIAL:
      return specialChallenge
    case CHALLENGE_TYPES.STAGING:
      return stagingChallenge
    case CHALLENGE_TYPES.THROWBACK:
      return throwbackChallenge
    case CHALLENGE_TYPES.WEEKEND:
      return weekendChallenge
    case CHALLENGE_TYPES.NEW_USER:
      return newUserChallenge
    case CHALLENGE_TYPES.LEVEL:
      return levelChallenge
    case CHALLENGE_TYPES.VACATION:
      return vacationChallenge
    case CHALLENGE_TYPES.GENERAL:
      return
    default:
      return
  }
}

export const getChallengePerkIcon = reward => {
  switch (reward.type) {
    case 'dcoin':
      return coinIcon
    case 'featured':
      return coinsIcon
    case 'badge':
      return reward.imageFiles[reward.imageFiles.length - 1].url
    default:
      return reward.icon
  }
}

export const getChallengePerkText = reward => {
  switch (reward.type) {
    case 'dcoin':
    case 'featured':
    case 'badge':
      return reward.description
  }

  return reward.title || reward.description
}

export const getChallengeBackgroundFromType = challenge => {
  const type =
    challenge?.type === CHALLENGE_TYPES.GENERAL
      ? getChallengeTypeFromHashtags(challenge)
      : challenge?.type

  switch (type) {
    case CHALLENGE_TYPES.CLASSIC:
      return '#E3EFFA'
    case CHALLENGE_TYPES.DAILY:
      return '#E6F7E5'
    case CHALLENGE_TYPES.LIMITED:
      return '#E7F5E0'
    case CHALLENGE_TYPES.POPUP:
      return '#FFF1DC'
    case CHALLENGE_TYPES.PRO:
      return '#FEF7D9'
    case CHALLENGE_TYPES.SERIES:
      return '#E4E6FD'
    case CHALLENGE_TYPES.SPECIAL:
      return '#EEDFFA'
    case CHALLENGE_TYPES.STAGING:
      return '#FBEADC'
    case CHALLENGE_TYPES.THROWBACK:
      return '#DAEEEF'
    case CHALLENGE_TYPES.WEEKEND:
      return '#FFF5DD'
    case CHALLENGE_TYPES.NEW_USER:
      return '#FFEDEF'
    case CHALLENGE_TYPES.LEVEL:
      return '#E9DEFC'
    case CHALLENGE_TYPES.VACATION:
      return '#FFEFDA'
    default:
      return '#FFF4F4'
  }
}
