const { createSlice } = require('@reduxjs/toolkit')

const locationStateReducer = createSlice({
  name: 'locationState',
  initialState: {
    state: null,
    hideFloatingButtons: false
  },
  reducers: {
    setLocationState: (state, action) => {
      state.state = action.payload
    },
    setHideFloatingButtons: (state, action) => {
      state.hideFloatingButtons = action.payload
    },
    clearLocationState: state => {
      state.state = null
    }
  }
})

export const { setLocationState, clearLocationState, setHideFloatingButtons } =
  locationStateReducer.actions
export default locationStateReducer.reducer
