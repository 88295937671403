import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_PURCHASE_ITEMS } from '../../constants/ApiEndpoints'
import { api, checkFeedStatus } from '../../utils/fetchUtils'
import { FEED_RESET } from '../constants'

const effectPageLimit = 15

//-------------------------------------------------------------------
// Fetch Effect
//-------------------------------------------------------------------

export const fetchEffects = createAsyncThunk(
  'effects/fetchEffects',
  async (data, { getState }) => {
    //console.log('[effectsReducer][fetchEffects]')
    const { effects, effectPage, effectPageEnd } = getState().effects

    const nextPage = checkFeedStatus(
      data,
      effects.length,
      effectPage,
      effectPageEnd
    )
    if (nextPage === -1) return null

    const body = {
      categoryIds: ['11999'],
      skip: effectPage * effectPageLimit,
      pageLimit: effectPageLimit
    }
    let response = await api(GET_PURCHASE_ITEMS, JSON.stringify(body))

    return {
      data: response.result.items,
      page: nextPage
    }
  }
)

export const fetchFilterEffects = createAsyncThunk(
  'effects/fetchFilterEffects',
  async (data, { getState }) => {
    //console.log('[effectsReducer][fetchFilterEffects]')
    const {
      filterEffectParams,
      filterEffects,
      filterEffectPage,
      filterEffectPageEnd
    } = getState().effects

    const nextPage = checkFeedStatus(
      data,
      filterEffects.length,
      filterEffectPage,
      filterEffectPageEnd
    )
    if (nextPage === -1) return null

    //11999
    const body = {
      categoryIds: ['11999'],
      skip: nextPage * effectPageLimit,
      pageLimit: effectPageLimit,
      ...filterEffectParams
    }

    let response = await api(GET_PURCHASE_ITEMS, JSON.stringify(body))

    return {
      data: response.result.items,
      page: nextPage
    }
  }
)

export const fetchPurchaseEffects = createAsyncThunk(
  'effects/fetchPurchasedEffects',
  async (data, { getState }) => {
    //console.log('[effectsReducer][fetchPurchasedEffects]')
    const { purchases, purchasePage, purchasePageEnd } = getState().effects

    const nextPage = checkFeedStatus(
      data,
      purchases.length,
      purchasePage,
      purchasePageEnd
    )
    if (nextPage === -1) return null

    //11999
    const body = {
      categoryIds: ['11999'],
      skip: nextPage * effectPageLimit,
      pageLimit: effectPageLimit
    }

    let response = await api(GET_PURCHASE_ITEMS, JSON.stringify(body))

    return {
      data: response.result.mpPurchases,
      page: nextPage
    }
  }
)

export const fetchFilterPurchaseEffects = createAsyncThunk(
  'effects/fetchFilterPurchasedEffects',
  async (data, { getState }) => {
    //console.log('[effectsReducer][fetchFilterPurchasedEffects]')
    const {
      filterPurchaseParams,
      filterPurchases,
      filterPurchasePage,
      filterPurchasePageEnd
    } = getState().effects

    const nextPage = checkFeedStatus(
      data,
      filterPurchases.length,
      filterPurchasePage,
      filterPurchasePageEnd
    )
    if (nextPage === -1) return null

    const body = {
      categoryIds: ['11999'],
      skip: filterPurchasePage * effectPageLimit,
      pageLimit: effectPageLimit,
      ...filterPurchaseParams
    }

    let response = await api(GET_PURCHASE_ITEMS, JSON.stringify(body))

    return {
      data: response.result.mpPurchases,
      page: nextPage
    }
  }
)

const initialState = {
  effects: [],
  purchases: [],
  filterEffects: [],
  filterPurchases: [],

  effectPage: 0,
  purchasePage: 0,
  filterEffectPage: 0,
  filterPurchasePage: 0,

  effectPageEnd: false,
  purchasePageEnd: false,
  filterEffectPageEnd: false,
  filterPurchasePageEnd: false,

  filterEffectParams: {},
  filterPurchaseParams: {},

  effectError: null,
  purchaseError: null,
  filterEffectError: null,
  filterPurchaseError: null,

  loading: 'pending'
}

const effects = createSlice({
  name: 'effects',
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
      if (!action.payload) {
        state.filterEffectParams = {}
        state.filterPurchaseParams = {}
        return
      }

      state.filterEffectParams = action.payload
      state.filterPurchaseParams = action.payload
    }
  },
  extraReducers: {
    [fetchEffects.pending]: (state, action) => {
      state.effectError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.effects = []
        state.effectPage = 0
        state.effectPageEnd = false
      }
      if (state.effectPage === 0) state.loading = 'pending'
    },
    [fetchEffects.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < effectPageLimit)
        state.effectPageEnd = true
      else state.effectPageEnd = false
      state.effects = [...state.effects, ...action.payload.data]
      state.effectPage = action.payload.page + 1

      if (state.effects.length === 0) state.effectError = 'empty'
      else state.effectError = null
    },
    [fetchPurchaseEffects.pending]: (state, action) => {
      state.purchaseError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.purchases = []
        state.purchasePage = 0
        state.purchasePageEnd = false
      }
      if (state.purchasePage === 0) state.loading = 'pending'
    },
    [fetchPurchaseEffects.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < effectPageLimit)
        state.purchasePageEnd = true
      else state.purchasePageEnd = false
      state.purchases = [...state.purchases, ...action.payload.data]
      state.purchasePage = action.payload.page + 1

      if (state.purchases.length === 0) state.purchaseError = 'empty'
      else state.purchaseError = null
    },
    [fetchFilterEffects.pending]: (state, action) => {
      state.filterEffectError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.filterEffects = []
        state.filterEffectPage = 0
        state.filterEffectPageEnd = false
      }
      if (state.filterEffectPage === 0) state.loading = 'pending'
    },
    [fetchFilterEffects.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < effectPageLimit)
        state.filterEffectPageEnd = true
      else state.filterEffectPageEnd = false
      state.filterEffects = [...state.filterEffects, ...action.payload.data]
      state.filterEffectPage = action.payload.page + 1

      if (state.filterEffects.length === 0) state.filterEffectError = 'empty'
      else state.filterEffectError = null
    },
    [fetchFilterPurchaseEffects.pending]: (state, action) => {
      state.filterPurchaseError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.filterPurchases = []
        state.filterPurchasePage = 0
        state.filterPurchasePageEnd = false
      }
      if (state.filterPurchasePage === 0) state.loading = 'pending'
    },
    [fetchFilterPurchaseEffects.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < effectPageLimit)
        state.filterPurchasePageEnd = true
      else state.filterPurchasePageEnd = false
      state.filterPurchases = [...state.filterPurchases, ...action.payload.data]
      state.filterPurchasePage = action.payload.page + 1

      if (state.filterPurchases.length === 0)
        state.filterPurchaseError = 'empty'
      else state.filterPurchaseError = null
    }
  }
})

export const { setFilterParams } = effects.actions

export default effects.reducer
