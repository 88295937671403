import Link from 'next/link'
import styled from 'styled-components'

export const BannerContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px 18px;
  padding-left: ${props => (props.addSpacing ? '15px' : '0')};
  cursor: pointer;

  @media screen and (max-width: 450px) {
    max-width: 100%;
  }

  &:first-of-type {
    margin-top: 14px;
  }
`

export const BannerNotificationWrapper = styled.div`
  border-radius: 16px;
  flex-grow: 1;
  max-width: 388px;
  overflow: hidden;

  @media screen and (max-width: 450px) {
    max-width: 95%;
  }
`

export const BannerPosterWrapper = styled.div`
  min-height: ${props => (props.tall ? '188px' : '120px')};
  width: 100%;
  max-width: 388px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
`

export const BannerPoster = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;
  flex-grow: 1;
  flex-shrink: 0;

  @media (max-width: 800px) {
    align-self: stretch;
    height: auto;
  }
`

export const InfoPart = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 0px 0px 16px 16px;
  padding: 14px 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const BannerAlertTitle = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 6px;
`

export const BannerAlertText = styled.p`
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 0;
  margin-bottom: 11px;
`

export const BannerTimeText = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #979797;
`

export const ArrowButton = styled.div`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 7px;
    height: 12px;
    opacity: 0.7;
  }
`

export const BannerInfoPoster = styled.div`
  background: transparent;
  width: 388px;
  display: flex;
  flex-durection: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 40px 19px 25px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;

  @media screen and (max-width: 450px) {
    max-width: 100%;
  }

  & > img {
    height: 70px;
    margin-left: 20px;
  }
`

export const BannerInfoPosterText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  flex-grow: 0;
  flex-shrink: 1;
  padding-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  & strong {
    color: #ff5e6d;
  }
`

export const BannerChallengeInfo = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 5px;

  @media (max-width: 800px) {
    align-self: center;
  }
`

export const ChallengePerks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 22px;
`

export const ChallengeAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
`

export const ChallengePerkLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
  font-weight: bold;
`

export const ChallengePerkIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

export const WeeklyReportInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const WeeklyReportTitle = styled.span`
  display: block;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  max-width: 130px;
  text-transform: uppercase;
  margin-top: 11px;
`

export const WeeklyReportInfoContainer = styled.div`
  width: 86%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BadgeIcon = styled.img`
  width: 70px !important;
  height: 70px !important;
`

export const UserEventBannerContainer = styled.div`
  position: relative;
  width: 66px;
  height: 66px;
  margin-right: 20px;

  & img {
    width: 66px;
    height: 66px;
    border-radius: 6px;
  }
`

export const UserEventBannerIcon = styled.img`
  max-width: 32px;
  max-height: 32px;
  width: auto;
  height: auto;
  position: absolute;
  bottom: -10px;
  left: -10px;
  ${props =>
    props.coins &&
    `
    max-width: 50px;
    max-height: 50px;
    bottom: -15px;
  `}
`

export const UserEventAuthor = styled.span`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #000000;
  margin-top: 2px;
`

export const WinnersGrid = styled.div`
  display: flex;
  gap: 18px;
  filter: drop-shadow(0px 4px 11px rgba(154, 0, 0, 0.19));
`

export const WinnerName = styled.div`
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
  margin: 9px auto 0;
  max-width: 69px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const WinnersBgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ChallengeWinnerInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
`

export const ChallengeWinnerInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
`

export const WinnerDesignImage = styled.img`
  width: 142px;
  height: 142px;
  border-radius: 7px;
`
