import styled from 'styled-components'

export const FloatingMenuSocialIconsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.centered ? 'center' : 'space-between')};
  box-sizing: border-box;
`

export const SocialLink = styled.a`
  text-decoration: none;
  margin-right: ${props => (props.centered ? '15px' : '30px')};
  margin-left: ${props => (props.centered ? '15px' : '0')};
`

export const SocialIcon = styled.img`
  &:hover {
    filter: invert(49%) sepia(76%) saturate(2766%) hue-rotate(323deg)
      brightness(110%) contrast(101%);
  }
`
