import { useEffect, useState } from 'react'
import { MOBILE_BREAKPOINT } from '../constants/MobileConstants'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    setIsMobile(document.body.clientWidth <= MOBILE_BREAKPOINT)

    const listener = () => {
      setIsMobile(document.body.clientWidth <= MOBILE_BREAKPOINT)
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return isMobile
}
