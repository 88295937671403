import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  APPID,
  CREATE_TEMPLATE,
  DELETE_DRAFT,
  DELETE_POST,
  DELETE_TEMPLATES,
  GET_IDEA,
  GET_INSPIRATIONS,
  GET_SIGNED_URL,
  GET_USER_TEMPLATES,
  SAVE_DRAFT,
  SAVE_POST
} from '../../constants/ApiEndpoints'
import { api, sendBase64File, getUserToken } from '../../utils/fetchUtils'
import { clearAutoSave } from '../data/designStore'
import { CHALLENGE_TYPES } from '../../constants/ChallengeConstants'
import { resetAndOpen, showReward } from '../communityReducers/CheckInReducer'

const cleanItemData = it => {
  const temp = { ...it, type: 'item' }

  delete temp.ACL
  delete temp.className
  delete temp.createdAt
  delete temp.description
  delete temp.id
  delete temp.numBookmarks
  delete temp.numPurchases
  delete temp.numUses
  delete temp.premium
  delete temp.price
  delete temp.rank
  delete temp.rank_expiration
  delete temp.subcategoryId
  delete temp.updatedAt
  delete temp.updatedScript
  delete temp.__type

  return temp
  /*
XXX ACL: {*: {…}}
baseUrl: "items.decormatters.com"
brandImageFileLoc: "brandLogo/"
categoryId: "10001"
categoryName: "accessories"
XXX className: "Item"
color: "multi"
colorDesign: "multi"
colorShop: "multi"
XXX createdAt: "2021-07-10T00:00:45.643Z"
XXX description: ""
XXX id: "pjTfC0Gl05"
imageFileLoc: "onekingslane/product/"
imageFileNames: ["pjTfC0Gl05_1.jpg"]
XXX imgurl: "https://items.decormatters.com/onekingslane/processed/pjTfC0Gl05_1.png"
XXX numBookmarks: 0
numCoins: 65
XXX numPurchases: 32
XXX numUses: 28
objectId: "pjTfC0Gl05"
origin: "decormatters-items"
originalPrice: 285
XXX premium: true
XXX price: 285
processedImageFileLoc: "onekingslane/processed/"
processedImageFileNames: ["pjTfC0Gl05_1.png"]
productRecommendation: []
productUrl: "https://www.onekingslane.com/p/4657711-purple-flowers-by-les-anderson.do?page=31&refType=&from=fn"
XXX rank: 100
XXX rank_expiration: {__type: "Date", iso: "2021-07-15T20:00:18.872Z"}
salePrice: 285
status: "active"
storeId: "onekingslane"
storeName: "onekingslane"
subCategoryId: "11053"
subCategoryName: "art"
XXX subcategoryId: "11053"
thumbImageFileLoc: "onekingslane/product/"
thumbImageFileName: "pjTfC0Gl05_1.jpg"
title: "Purple Flowers by Les Anderson"
XXX type: "i"
XXX updatedAt: "2021-07-15T17:17:55.283Z"
XXX updatedScript: "item_mproduct_update_07152021.json"
XXX __type: "Object"
XXX __proto__: Object
*/
}

const targetsToSaveFormat = targets => {
  var temp = []
  targets.forEach(n => {
    const {
      rotation,
      scaleX,
      scaleY,
      src,
      x,
      y,
      brightness,
      transform,
      item,
      crop
    } = n
    //console.log(n)
    const it =
      item.type === 'i' || item.type === 'item' ? cleanItemData(item) : item
    const obj = {
      src,
      m: [x, y, scaleX, scaleY, rotation],
      p: transform,
      f: [brightness],
      c: crop,
      item: it
    }
    temp.push(obj)
  })
  return temp
}

const cleanMydecorData = it => {
  const temp = {
    imgUrl: it.imgurl,
    objectId: it.id,
    userId: it.userId
  }
  return temp
}

const targetsToProductsWithPosition = (
  targets,
  textures,
  sceneWidth,
  sceneHeight
) => {
  //console.log(targets)
  const SCENE_WIDTH = sceneWidth || 750
  const SCENE_HEIGHT = sceneHeight || 750

  var temp = []
  targets.forEach(n => {
    const { rotation, scaleX, scaleY, x, y } = n
    const centerXRatio = x / SCENE_WIDTH
    const centerYRatio = y / SCENE_HEIGHT

    const texture = textures[n.id] || { w: 0, h: 0 }

    const sx = Math.abs((scaleX * texture.w) / SCENE_WIDTH)
    const sy = Math.abs((scaleY * texture.h) / SCENE_HEIGHT)

    var obj = {
      imageUrl: n.src,
      item: {},
      positionData: {
        brightness: 0,
        centerXRatio,
        centerYRatio,
        heightRatio: sy,
        isFlipped: scaleX < 0 ? true : false,
        perspectiveX: 0,
        perspectiveY: 0,
        rotation,
        widthRatio: sx
      }
    }

    if (n.item.type === 'i' || n.item.type === 'item') {
      const it = cleanItemData(n.item)
      obj = {
        ...obj,
        item: it
      }
    }
    if (n.item.type === 'u' || n.item.type === 'usrItem') {
      const usrItem = cleanMydecorData(n.item)
      //console.log(n)
      //console.log(usrItem)
      obj = {
        ...obj,
        usrItem
      }
    }
    temp.push(obj)
  })

  return temp
}

export const fetchMyRooms = createAsyncThunk(
  'DesignerDesigns/fetchMyRooms',
  async (data, { getState }) => {
    //console.log('[designReducer][fetchMyRooms]')
    //const { myrooms } = getState().DesignerDesigns

    //if(data === 'init' && myrooms.length > 0) return
    let response = await api(GET_USER_TEMPLATES)

    return response.result
  }
)

export const fetchUserDesign = createAsyncThunk(
  'DesignerDesigns/fetchUserDesign',
  async (objectId, { getState }) => {
    //console.log('[designReducer][fetchUserDesign]')
    //console.log("FETCH DESIGN: ", objectId)
    const body = {
      objectId
    }
    let response = await api(GET_IDEA, JSON.stringify(body))
    if (response.code) return response
    return response.result.idea
  }
)

export const uploadTemplate = createAsyncThunk(
  'DesignerDesigns/uploadTemplate',
  async (file, { getState }) => {
    const ext = file.name.split('.').pop()
    const result = await api(GET_SIGNED_URL, JSON.stringify({ ext }))
    const { signedUrl } = result.result
    const url = await sendBase64File(signedUrl, file)

    //console.log(url.config.url.split('?')[0])
    await api(
      CREATE_TEMPLATE,
      JSON.stringify({ cfImageUrl: url.config.url.split('?')[0] })
    )

    return
  }
)

export const deleteMyRoom = createAsyncThunk(
  'DesignerDesigns/deleteMyRoom',
  async (userTemplateIds, { getState }) => {
    //console.log('[designReducer][deleteMyRoom]')
    await api(DELETE_TEMPLATES, JSON.stringify({ userTemplateIds }))
    return
  }
)

//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------

export const saveDraft = createAsyncThunk(
  'DesignerDesigns/saveDraft',
  async (data, { getState, dispatch }) => {
    try {
      const state = getState()
      const { me } = state.profile
      const { state: locationState } = state.locationState
      const {
        savedId,
        design,
        scene,
        targets,
        textures,
        designData,
        sceneWidth,
        sceneHeight
      } = state.canvas

      const items = targetsToSaveFormat(targets)
      const productsWithPosition = targetsToProductsWithPosition(
        targets,
        textures,
        sceneWidth,
        sceneHeight
      )

      const username = me.uniqueDisplayName ? me.uniqueDisplayName : me.objectId

      var body = {
        items,
        productsWithPosition
      }

      var designInfo = {
        categoryName: null,
        tags: []
      }
      /******
       *
       * errors out for drafts
       * contains no designData
       *
       *
       */

      if (designData) {
        if (designData.className === 'DesignContest') {
          const name = designData.type
            ? designData.type + '-challenge'
            : 'challenge'
          designInfo = {
            categoryName: name,
            tags: designData.hashtags || []
          }
          body = {
            ...body,
            cfBgImageUrl: designData.contestImageFile.url
          }

          if (designData.objectId !== 'tutorTempId') {
            body.designContestId = designData.objectId
          }
        }
        //className: "Template"
        if (designData.className === 'Template') {
          designInfo = {
            categoryName: designData.categoryName,
            tags: designData.tags || []
          }
          body = {
            ...body,
            templateId: designData.objectId,
            cfBgImageUrl: designData.cfImageUrl
          }
        }

        if (designData.className === 'UserTemplate') {
          designInfo = {
            categoryName: username + '-design',
            tags: []
          }
          body = {
            ...body,
            cfBgImageUrl: designData.cfImageUrl
          }
        }
        //need for myroom
        //need for continue draft
      } else {
        designInfo = {
          categoryName: username + '-design',
          tags: []
        }

        const url = scene.split('?')[0]
        body = {
          ...body,
          cfBgImageUrl: url
        }
      }

      const filename = createDesignFilename(
        designInfo.categoryName,
        designInfo.tags,
        username
      )
      const key = me.objectId + '/' + filename
      const fullFilename = filename + '.jpeg'
      const blob = dataURItoBlob(design)
      const file = new File([blob], fullFilename, { type: 'image/jpeg' })

      if (designData?.className === 'UserEvent') {
        body = {
          ...body,
          userEventId: designData.objectId,
          bgImageUrl: designData.templateImage,
          cfBgImageUrl: designData.templateImage
        }
      }

      const uploadResults = await processImage(file, key)

      body = {
        ...body,
        cfThumbImageUrl: uploadResults.default,
        collection: {
          ...uploadResults
        }
      }

      if (
        locationState?.fromDraft &&
        locationState?.idea &&
        locationState?.idea?.id
      ) {
        body.ideaObjectId = locationState.idea.id
      }
      //await delay(5000)

      const response = await api(SAVE_DRAFT, JSON.stringify(body))
      clearAutoSave()

      return response
    } catch (error) {
      console.log(error)
    }
  }
)

export const processImage = async (file, key) => {
  const token = getUserToken()
  const designKey = 'DesignerDesigns/' + key
  const formData = new FormData()
  formData.append('original', file)
  formData.append('key', designKey)
  formData.append('default', '750')

  const payload = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Authorization: 'f08cad7d61789610a64b2c1179b2ec8b',
      'X-Parse-Application-Id': APPID,
      'X-Parse-Session-Token': token
    },
    body: formData
  }

  //const result = await fetch('http://127.0.0.1:5000/create', payload)
  const result = await fetch('https://images.decormatters.io/create', payload)

  if (!result) return
  const responseJson = await result.json()

  return responseJson
}

const shuffle = arr => {
  var j, x, index
  for (index = arr.length - 1; index > 0; index--) {
    j = Math.floor(Math.random() * (index + 1))
    x = arr[index]
    arr[index] = arr[j]
    arr[j] = x
  }
  return arr
}

const createDesignFilename = (cat, tags = [], username) => {
  const PICK_NUMBER_OF_TEMPLATE_TAGS = 3

  var name = 'decormatters-design'
  /*
!cat !tag = decormatters_design_by_you
cat !tag = awesome_design_by_you
!cat tag = tree_bush_by_you
cat tag = tree_bush_yard_by_you
*/
  if (cat && tags.length === 0) {
    name = cat
  }

  if (tags && tags.length > 0) {
    if (tags.length <= PICK_NUMBER_OF_TEMPLATE_TAGS) {
      name = tags.join('-')
    } else {
      const shuffled = shuffle([...tags])
      const top = shuffled.slice(0, PICK_NUMBER_OF_TEMPLATE_TAGS)
      name = top.join('-')
    }
  }

  if (cat && tags.length > 0) {
    name = name + (name ? '-' : '') + cat
  }

  if (username) {
    name = name + '-by-' + username
  }

  return name
}

const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------

export const deleteDraft = createAsyncThunk(
  'DesignerDesigns/deleteDraft',
  async (data, { getState }) => {
    //console.log('[designReducer][deleteDraft]')
    const body = { objectIds: [data] }
    await api(DELETE_DRAFT, JSON.stringify(body))
    clearAutoSave()
    return
  }
)

const extractProdIds = targets => {
  var tempp = []
  var tempm = []
  var tempi = []
  var tempu = []
  targets.forEach(n => {
    const it = n.item
    if (it.type === 'p') tempp.push(it.id)
    if (it.type === 'm') tempm.push(it.id)
    if (it.type === 'i') tempi.push(it.id)
    if (it.type === 'u') tempu.push(it.id)
    if (it.type === 'item') tempi.push(it.id)
  })
  return {
    productIds: tempp,
    marketProductIds: tempm,
    itemIds: tempi,
    userProductIds: tempu
  }
}
//invalid product with position JSON -> data.usrItem should have required property 'objectId'
export const post = createAsyncThunk(
  'DesignerDesigns/DesignerPost',
  async (data, { getState, dispatch }) => {
    const { postText, objectId, animationInfo, redesignInfo, type } = data
    const { targets, designData } = getState().canvas
    const ideaObjectId = objectId
    const { itemIds, userProductIds } = extractProdIds(targets)

    var body = {
      ideaObjectId,
      title: postText,
      itemIds,
      userProductIds
    }

    if (redesignInfo) {
      body.redesignInfo = redesignInfo
    }

    if (designData?.className === 'Template') {
    }
    if (designData?.className === 'DesignContest') {
      body = {
        ...body
      }

      if (designData.objectId !== 'tutorTempId') {
        body.designContestId = designData.objectId
      }

      if (designData?.type === 'brand') {
        body = {
          ...body,
          dailyTaskBrand: true
        }
      }
    }

    if (designData?.className === 'UserEvent') {
      body = {
        ...body,
        userEventId: designData.objectId
      }
    }

    if (animationInfo) {
      body.animationInfo = animationInfo
    }

    if (
      designData?.type === CHALLENGE_TYPES.LIMITED ||
      designData?.type === 'limited'
    ) {
      body.dailyTaskBrand = true
    }

    if (type === 'tutorial') body.type = 'tutorial'

    const response = await api(SAVE_POST, JSON.stringify(body))
    clearAutoSave()

    const { taskCoinReward } = response.result

    if (taskCoinReward) {
      dispatch(showReward({ coinReward: taskCoinReward, isSpecialTask: true }))
      dispatch(resetAndOpen())
    }

    return response.result
  }
)

export const deletePost = createAsyncThunk(
  'DesignerDesigns/deleteMyPost',
  async (objectId, { getState }) => {
    //console.log('[designReducer][deleteMyPost]')

    const body = {
      userInspirationObjectIds: [objectId]
    }

    await api(DELETE_POST, JSON.stringify(body))
    return
  }
)

const initialState = {
  drafts: [],
  posts: [],
  myroomInfo: null,
  myrooms: [],
  loading: 'pending',
  error: null
}

const designs = createSlice({
  name: 'designs',
  initialState,
  reducers: {
    reset: (state, action) => {
      state.drafts = []
      state.posts = []
      state.myroomInfo = null
      state.myrooms = []
      state.loading = 'pending'
      state.error = null
    }
  },
  extraReducers: {
    [fetchMyRooms.pending]: (state, action) => {
      state.error = null
      state.loading = 'idle'
    },
    [fetchMyRooms.fulfilled]: (state, action) => {
      if (!action.payload) return
      state.myroomInfo = action.payload
      state.myrooms = action.payload.userTemplates
    }
    // [fetchMyPosts.pending]: (state, action) => {
    //   state.error = null
    //   state.loading = 'pending'
    // },
    // [fetchMyPosts.fulfilled]: (state, action) => {
    //   state.loading = 'idle'
    //   if (!action.payload) return
    //   if (action.payload.length === 0) state.error = 'empty'
    //   else state.error = null
    //   state.posts = action.payload
    // }
  }
})

export const { reset } = designs.actions
export default designs.reducer
