import { getUserAvatar } from '../../../../../utils/getUserAvatar'
import { AvatarAccessory, Level, ProfileImageBg, ProfileImageWrapper, SmallLevel } from './ProfileImage.styles'

export const ProfileImage = ({ user, userLevel = user?.level || user?.userLevel, small, showLevel = true, ...props }) => {
  const userPhoto = getUserAvatar(user)

  userLevel = Math.floor(userLevel)

  return (
    <ProfileImageWrapper {...props}>
      <ProfileImageBg src={userPhoto} loading="lazy" alt="" />
      {user?.funAccessoriesImageUrl && <AvatarAccessory alt="" loading="lazy" size={props.size} src={user?.funAccessoriesImageUrl} />}
      {showLevel && !small && <Level>L {userLevel}</Level>}
      {showLevel && small && <SmallLevel size={props.size}>L {userLevel}</SmallLevel>}
    </ProfileImageWrapper>
  )
}
