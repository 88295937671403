import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
import {
  DropdownBodyWrapper,
  DropdownLink,
  DropdownWrapper
} from './DropdownMenu.styles'

export const DropdownMenu = ({ label, actions = [], onVisibilityChange }) => {
  const [isVisible, setIsVisible] = useState(false)
  const wrapperRef = useRef(null)

  useEffect(() => {
    const listener = e => {
      if (e && e.path && !e.path.includes(wrapperRef.current)) {
        setIsVisible(false)
        if (onVisibilityChange) onVisibilityChange(false)
      }
    }

    window.addEventListener('click', listener, false)

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [])

  if (actions.length === 0) return null

  return (
    <DropdownWrapper ref={wrapperRef}>
      <div
        onClick={() => {
          const newValue = !isVisible

          setIsVisible(newValue)
          if (onVisibilityChange) onVisibilityChange(newValue)
        }}
      >
        {label}
      </div>
      {isVisible && (
        <DropdownBodyWrapper>
          {actions.map(item => {
            const itemProps = {}

            if (item.action) {
              itemProps.onClick = () => {
                item.action()
                setIsVisible(false)
              }
            }

            if (item.to) {
              itemProps.href = item.to
              itemProps.as = Link
              itemProps.onClick = () => {
                setIsVisible(false)
              }
            }

            if (item.href) {
              itemProps.href = item.href
              itemProps.as = 'a'
              itemProps.onClick = () => {
                setIsVisible(false)
              }
            }

            return (
              <DropdownLink
                highlighted={item.highlighted}
                key={item.label}
                {...itemProps}
              >
                {item.icon && <img src={item.icon} alt="" />}
                {item.label}
              </DropdownLink>
            )
          })}
        </DropdownBodyWrapper>
      )}
    </DropdownWrapper>
  )
}
