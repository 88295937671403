import { createSlice } from '@reduxjs/toolkit'
import { MENU_PRODUCT, MENU_SNAP_WIDTH, MENU_SNAP_HEIGHT } from '../constants'

export const adjustHeight = () => {
  if (typeof window === 'undefined') return 0

  const pixelH = window.innerHeight * (MENU_SNAP_HEIGHT[1] / 100)
  return pixelH
}

export const adjustHeightByOrientation = o => {
  var side = window.innerWidth
  /*
  if(o === 0 || o == 180) {
    side = window.innerHeight
  }*/
  const pixelH = side * (MENU_SNAP_HEIGHT[1] / 100)
  return pixelH
}

const initialState = {
  uiVisible: true,
  isPosting: false,
  toolVisible: false,
  markerVisible: true,
  selectedTool: null,
  selectedMenu: MENU_PRODUCT,
  showPurchaseCoins: false,
  menuWidth: MENU_SNAP_WIDTH[2],
  isLoading: true,
  progress: 0,
  showTray: true,
  trayHeight: adjustHeight()
}

const workspace = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setUIVisible: (state, action) => {
      if (!action.payload) state.uiVisible = !state.uiVisible
      else state.uiVisible = action.payload
    },
    setToolVisible: (state, action) => {
      //if(!action.payload) state.toolVisible = !state.toolVisible
      //else state.toolVisible = action.payload
      state.toolVisible = action.payload
    },
    setMarkerVisible: (state, action) => {
      if (!action.payload) state.markerVisible = !state.markerVisible
      else state.markerVisible = action.payload
    },
    setSelectedTool: (state, action) => {
      state.selectedTool = action.payload
    },
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload

      /*
      if(state.menuWidth === MENU_SNAP_WIDTH[0]) {
        state.menuWidth = MENU_SNAP_WIDTH[2]
      }
      */
    },
    setShowTray: (state, action) => {
      if (!action.payload) state.showTray = false
      state.showTray = action.payload
    },
    setMenuWidth: (state, action) => {
      state.menuWidth = action.payload
    },
    setTrayHeight: (state, action) => {
      state.trayHeight = action.payload
    },
    setShowPurchaseCoins: (state, action) => {
      state.showPurchaseCoins = action.payload ? action.payload : false
    },
    updateProgress: (state, action) => {
      state.progress = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
      if (action.payload === false) state.progress = 0
    },
    setIsPosting: (state, action) => {
      state.isPosting = action.payload
    }
  }
})

export const {
  setUIVisible,
  setToolVisible,
  setMarkerVisible,
  setSelectedTool,
  setSelectedMenu,
  setMenuWidth,
  setIsPosting,

  setShowPurchaseCoins,
  updateProgress,
  setLoading,
  setTrayHeight,
  setShowTray
} = workspace.actions

export default workspace.reducer
