import moment from 'moment'

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    //console.log(e)
  }
}

export const truncate = (str, length, ending) => {
  if (!str) return ''
  if (length == null) {
    length = 22
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

export const timeLeft = duration => {
  if (duration < 0) {
    return 'closed'
  }

  var d = moment.duration(duration).days()
  var h = moment.duration(duration).hours()
  var m = moment.duration(duration).minutes()
  var s = moment.duration(duration).seconds()

  if (d > 0) {
    return d + 'd left'
  }
  if (h > 0) {
    return h + 'h left'
  }
  if (m > 0) {
    return m + 'm left'
  }

  return s + 's left'
}
