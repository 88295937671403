import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_ITEMS } from '../../constants/ApiEndpoints'
import { api, checkFeedStatus } from '../../utils/fetchUtils'
import { FEED_RESET } from '../constants'

const itemPageLimit = 15

//-------------------------------------------------------------------
// Fetch Items
//-------------------------------------------------------------------

export const fetchItems = createAsyncThunk(
  'items/fetchItems',
  async (data, { getState }) => {
    const { items, itemPage, itemPageEnd } = getState().items

    const nextPage = checkFeedStatus(data, items.length, itemPage, itemPageEnd)
    console.log({ nextPage })

    if (nextPage === -1) return null

    const body = {
      startCoins: 1,
      skip: nextPage * itemPageLimit,
      pageLimit: itemPageLimit
    }

    let response = await api(GET_ITEMS, JSON.stringify(body))

    return {
      data: response.result.items,
      page: nextPage
    }
  }
)

export const fetchSimilar = createAsyncThunk(
  'items/fetchSimilar',
  async (data, { getState }) => {
    const {
      items: { similar, similarPage, similarPageEnd },
      canvas: { selected, targets }
    } = getState()

    const selectedTarget = targets.find(({ id }) => id === selected)
    if (!selectedTarget || !selectedTarget?.item?.productRecommendation?.length)
      return

    const nextPage = checkFeedStatus(
      data,
      similar.length,
      similarPage,
      similarPageEnd
    )

    if (nextPage === -1) return null

    const body = {
      startCoins: 1,
      skip: nextPage * itemPageLimit,
      pageLimit: itemPageLimit,
      itemIds: selectedTarget.item.productRecommendation
    }

    let response = await api(GET_ITEMS, JSON.stringify(body))

    return {
      data: response.result.items,
      page: nextPage
    }
  }
)

export const fetchFreeItems = createAsyncThunk(
  'items/fetchFreeItems',
  async (data, { getState }) => {
    const { free, freePage, freePageEnd } = getState().items

    const nextPage = checkFeedStatus(data, free.length, freePage, freePageEnd)
    if (nextPage === -1) return null

    const body = {
      startCoins: 0,
      endCoins: 0,
      skip: nextPage * itemPageLimit,
      pageLimit: itemPageLimit
    }

    let response = await api(GET_ITEMS, JSON.stringify(body))

    return {
      data: response.result.items,
      page: nextPage
    }
  }
)

const formatFilters = (text, filters) => {
  var cats = []
  var subcats = []
  var clrs = []
  var stl = []
  var store = []

  filters.forEach(e => {
    if (e.type === 'categoryIds') {
      cats.push(e.catid)

      if (e.value) {
        if (e.catid !== e.value) subcats = subcats.concat(e.value)
      }
    }
    if (e.type === 'colorDesigns') clrs = clrs.concat(e.value)
    if (e.type === 'storeIds') store = store.concat(e.value)
    if (e.type === 'styles') stl = stl.concat(e.value)
  })

  const txt = text === '' ? undefined : text
  const categoryIds = cats.length === 0 ? undefined : cats
  const subCategoryIds = subcats.length === 0 ? undefined : subcats
  const colorDesigns = clrs.length === 0 ? undefined : clrs
  const styles = stl.length === 0 ? undefined : stl
  const storeIds = store.length === 0 ? undefined : store

  return {
    text: txt,
    categoryIds,
    subCategoryIds,
    colorDesigns,
    styles,
    storeIds,
    pageLimit: itemPageLimit
  }
}

export const fetchFilterItems = createAsyncThunk(
  'items/fetchFilterItems',
  async (data, { getState }) => {
    const { text, filters, filterItems, filterItemPage, filterItemPageEnd } =
      getState().items

    const nextPage = checkFeedStatus(
      data,
      filterItems.length,
      filterItemPage,
      filterItemPageEnd
    )
    if (nextPage === -1) return null

    const res = formatFilters(text, filters)

    const body = {
      ...res,
      skip: nextPage * itemPageLimit,
      pageLimit: itemPageLimit
    }

    let response = await api(GET_ITEMS, JSON.stringify(body))

    return {
      data: response.result.items,
      page: nextPage
    }
  }
)

export const fetchFilterFree = createAsyncThunk(
  'items/fetchFilterFree',
  async (data, { getState }) => {
    const { text, filters, filterFree, filterFreePage, filterFreePageEnd } =
      getState().items

    const nextPage = checkFeedStatus(
      data,
      filterFree.length,
      filterFreePage,
      filterFreePageEnd
    )
    if (nextPage === -1) return null

    const res = formatFilters(text, filters)

    const body = {
      ...res,
      startCoins: 0,
      endCoins: 0,
      skip: nextPage * itemPageLimit,
      pageLimit: itemPageLimit
    }

    let response = await api(GET_ITEMS, JSON.stringify(body))

    return {
      data: response.result.items,
      page: nextPage
    }
  }
)

/*
- Optional params: 
text
categoryIds
subCategoryIds
storeIds
colorShops
colorDesigns
styles
productIds
types
startPrice
endPrice
*/
const initialState = {
  items: [],
  free: [],
  filterItems: [],
  filterFree: [],
  similar: [],

  // before adding new items check if they are not already in the related list
  relatedItems: [],

  itemPage: 0,
  freePage: 0,
  filterItemPage: 0,
  filterFreePage: 0,
  similarPage: 0,

  itemPageEnd: false,
  freePageEnd: false,
  filterItemPageEnd: false,
  filterFreePageEnd: false,
  similarPageEnd: false,

  itemError: null,
  freeError: null,
  filterItemError: null,
  filterFreeError: null,
  similarError: null,

  filterParams: {},

  text: '',
  filters: [],

  loading: 'pending'
}

const items = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setText: (state, action) => {
      state.text = action.payload
    },
    setFilterText: (state, action) => {
      if (state.text.trim() === '') {
        state.filters = state.filters.filter(e => e.type !== 'text')
      } else {
        const check = state.filters.filter(e => e.type === 'text')
        if (check.length === 0) {
          state.filters.push({
            type: 'text',
            value: state.text,
            label: state.text
          })
        } else {
          state.filters.map(e => {
            if (e.type === 'text') {
              e.value = state.text
              e.label = state.text
            }
            return e
          })
        }
      }
    },
    setFilterItem: (state, action) => {
      const item = action.payload
      const check = state.filters.filter(
        e => e.type === item.type && e.value === item.value
      )
      if (check.length === 0) state.filters.push(item)
    },
    removeFilterItem: (state, action) => {
      const item = action.payload

      function intersect(a, b) {
        return a.filter(Set.prototype.has, new Set(b))
      }

      state.filters = state.filters.filter(e => {
        return (
          e.value !== item.value &&
          e.catid !== item.value &&
          (!Array.isArray(e.value) ||
            intersect(e.value, item.value).length === 0)
        )
      })
      if (item.type === 'text') state.text = ''
    },
    clearItemFilterList: (state, action) => {
      state.filterItems = []
      state.filterItemPage = 0
    },
    clearFreeFilterList: (state, action) => {
      state.filterFree = []
      state.filterFreePage = 0
    },
    clearFilters: (state, action) => {
      state.text = ''
      state.filters = []
    },
    clearSimilar: state => {
      state.similar = []
      state.similarPage = 0
      state.similarPageEnd = false
    },
    addRelatedItems: (state, action) => {
      state.relatedItems = action.payload
      state.items = [...action.payload, ...state.items]
    },
    addRelatedFilteredItems: (state, action) => {
      const { filters } = action.payload
      const relatedFilteredItems = action.payload.items.filter(item => {
        return filters.every(({ type, value }) => {
          if (type === 'categoryIds')
            return item.categoryId === value || item.subCategoryId === value
          if (type === 'colorDesigns') return item.colorDesign === value
          if (type === 'styles') return item.style === value
          if (type === 'storeIds') return item.storeId === value
          return true
        })
      })
      state.filterItems = state.filterItems = [
        ...relatedFilteredItems,
        ...state.filterItems
      ]
    },
    resetItems: (state, action) => {
      state.items = []
      state.free = []
      state.filterItems = []
      state.filterFree = []

      state.relatedItems = []

      state.itemPage = 0
      state.freePage = 0
      state.filterItemPage = 0
      state.filterFreePage = 0

      state.itemPageEnd = false
      state.freePageEnd = false
      state.filterItemPageEnd = false
      state.filterFreePageEnd = false

      state.itemError = null
      state.freeError = null
      state.filterItemError = null
      state.filterFreeError = null

      state.text = ''
      state.filters = []
      state.filterParams = {}
    }
  },
  extraReducers: {
    [fetchItems.pending]: (state, action) => {
      state.itemError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.items = []
        state.itemPage = 0
        state.itemPageEnd = false
      }
      if (state.itemPage === 0) state.loading = 'pending'
    },
    [fetchItems.fulfilled]: (state, action) => {
      try {
        if (!action.payload) return
        if (action.payload.data.length < itemPageLimit) state.itemPageEnd = true
        else state.itemPageEnd = false

        // filter out items that are already in the related list
        const filteredItems = action.payload.data.filter(
          item =>
            !(state.relatedItems || []).some(
              ({ objectId }) => objectId === item.objectId
            )
        )

        const newItems = [...state.items, ...filteredItems]

        const idsMap = {}

        // filter out items with duplicate objectIds
        const uniqueItems = newItems.filter(item => {
          if (idsMap[item.objectId]) return false

          idsMap[item.objectId] = true

          return true
        })

        state.items = uniqueItems
        state.itemPage = action.payload.page + 1

        if (state.items.length === 0) state.itemError = 'empty'
        else state.itemError = null

        state.loading = 'idle'
      } catch (error) {
        console.log({ error })
      }
    },
    [fetchSimilar.pending]: (state, action) => {
      state.similarError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.similar = []
        state.similarPage = 0
        state.similarPageEnd = false
      }
      if (state.similarPage === 0) state.loading = 'pending'
    },
    [fetchSimilar.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < itemPageLimit)
        state.similarPageEnd = true
      else state.similarPageEnd = false
      state.similar = [...state.similar, ...action.payload.data]
      state.similarPage = action.payload.page + 1

      if (state.similar.length === 0) state.similarError = 'empty'
      else state.similarError = null
    },
    [fetchFreeItems.pending]: (state, action) => {
      state.freeError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.free = []
        state.freePage = 0
        state.freePageEnd = false
      }
      if (state.freePage === 0) state.loading = 'pending'
    },
    [fetchFreeItems.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < itemPageLimit) state.freePageEnd = true
      else state.freePageEnd = false
      state.free = [...state.free, ...action.payload.data]
      state.freePage = action.payload.page + 1

      if (state.free.length === 0) state.freeError = 'empty'
      else state.freeError = null
    },
    [fetchFilterItems.pending]: (state, action) => {
      state.isFetching = true
      state.filterItemError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.filterItems = []
        state.filterItemPage = 0
        state.filterItemPageEnd = false
      }
      if (state.filterItemPage === 0) state.loading = 'pending'
    },
    [fetchFilterItems.fulfilled]: (state, action) => {
      state.loading = 'idle'
      state.isFetching = false
      if (!action.payload) return
      if (action.payload.data.length < itemPageLimit)
        state.filterItemPageEnd = true
      else state.filterItemPageEnd = false

      const newItems = [...state.filterItems, ...action.payload.data]

      const idsMap = {}

      // filter out items with duplicate objectIds
      const uniqueItems = newItems.filter(item => {
        if (idsMap[item.objectId]) return false

        idsMap[item.objectId] = true

        return true
      })

      state.filterItems = uniqueItems
      state.filterItemPage = action.payload.page + 1

      if (state.filterItems.length === 0) state.filterItemError = 'empty'
      else state.filterItemError = null
    },
    [fetchFilterFree.pending]: (state, action) => {
      state.filterFreeError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.filterFree = []
        state.filterFreePage = 0
        state.filterFreePageEnd = false
      }
      if (state.filterFreePage === 0) state.loading = 'pending'
    },
    [fetchFilterFree.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < itemPageLimit)
        state.filterFreePageEnd = true
      else state.filterFreePageEnd = false
      state.filterFree = [...state.filterFree, ...action.payload.data]
      state.filterFreePage = action.payload.page + 1

      if (state.filterFree.length === 0) state.filterFreeError = 'empty'
      else state.filterFreeError = null
    }
  }
})

export const {
  setText,
  setFilterItem,
  removeFilterItem,
  clearItemFilterList,
  clearFreeFilterList,
  setFilterText,
  clearFilters,
  resetItems,
  addRelatedItems,
  addRelatedFilteredItems,
  clearSimilar
} = items.actions

export default items.reducer
