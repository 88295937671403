import { useQuery } from 'react-query'
import { APPID, GET_ME2, GET_OTHER_USER, GET_USER_INSPIRATION } from '../constants/ApiEndpoints'
import { parseHeaders } from '../utils/fetchUtil'
import { requestHelper } from '../utils/reduxActionUtils'
import { getUserToken } from '../utils/fetchUtils'

export const getPrerenderedUserInfo = async userId => {
  const result = await fetch(GET_OTHER_USER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': APPID
    },
    body: JSON.stringify({
      userId
    })
  })

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  return await result.json()
}

export const getPrerenderedPost = async postId => {
  const result = await fetch(GET_USER_INSPIRATION, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': APPID
    },
    body: JSON.stringify({
      objectId: postId
    })
  })

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  return await result.json()
}

export const useUserInfo = query => {
  return useQuery(
    ['GET_USER_INFO'],
    async () => {
      if (!getUserToken()) return {}
      //console.log("HOLY SHIT, THS GETS CALLED JUST TO CHECK ON NOTIFICATIONS")
      const params = requestHelper()
      const res = await fetch(GET_ME2, params)
      const { result } = await res.json()
      return result
    },
    {
      refetchInterval: 30000, //10 * 1000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchIntervalInBackground: 60 * 1000
    }
  )
}
