class LocationChange {
  subscribers = []
  currentValue = null

  constructor() {
    if (typeof window !== 'undefined') {
      this.currentValue = window.location.pathname
    }
  }

  subscribe(callback) {
    this.subscribers.push(callback)
  }

  unsubscribe(callback) {
    this.subscribers = this.subscribers.filter(
      subscriber => subscriber !== callback
    )
  }

  emit(location, href) {
    this.currentValue = location
    this.subscribers.forEach(subscriber => subscriber(location, href))
  }
}

const locationChangeEvent = new LocationChange()

export default locationChangeEvent
