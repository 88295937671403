import { useQuery } from 'react-query'
import { requestHelper } from '../utils/reduxActionUtils'
import {
  GET_HASHTAGS,
  GET_SEARCH_RECOMMENDATION
} from '../constants/ApiEndpoints'

export const useRecommendedSearches = query =>
  useQuery(
    ['GET_RECOMMENDED_SEARCHES', query],
    async () => {
      const params = requestHelper({ text: query })
      const res = await fetch(GET_SEARCH_RECOMMENDATION, params)
      const { result } = await res.json()

      return result
    },
    { enabled: Boolean(query) }
  )

export const usePopularHashtags = () =>
  useQuery('GET_POPULAR_HASHTAGS', async () => {
    const response = await fetch(
      GET_HASHTAGS,
      requestHelper({ pageLimit: 8, skip: 0 })
    )
    const { result } = await response.json()

    return result
  })
