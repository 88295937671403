import styled, { keyframes } from 'styled-components'

export const DropdownWrapper = styled.div`
  position: relative;
  perspective: 2000px;
  z-index: 100;
`

const windowAnimation = keyframes`
  0% {
    transform: rotateX(-15deg);
  }
  
  100% {
    transform: rotateX(0);
  }
`

export const DropdownBodyWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  padding: 5px 4px;
  width: 188px;
  right: 0px;
  top: 30px;
  z-index: 1;
  transform-origin: center -100px;
  transform: rotateX(-15deg);
  transition: transform 0.3s;
  animation: ${windowAnimation} 0.1s ease-in-out forwards;
`

export const DropdownLink = styled.button`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  color: ${props => (props.highlighted ? '#FF5E6D' : '#0f0d22')};
  width: 100% !important;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  min-height: 42px;
  box-sizing: border-box;
  background: white;

  & img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  &:hover {
    background: #f5f5f5;
  }
`
