import { createSlice } from '@reduxjs/toolkit'
import { COMMUNITY_CATEGORIES_LIST } from '../../constants/CommunityCategoriesConstants'

const dm = createSlice({
  name: 'dm',
  initialState: {
    designBrowseCount: 0,
    designMaxBrowseCount: 5,
    modalOpen: false,
    showPurchaseCoins: false,
    purchaseModalProps: {},
    selectedCategory: COMMUNITY_CATEGORIES_LIST[0]
  },
  reducers: {
    reset: (state, action) => {
      state.designBrowseCount = 0
    },
    setSelectedCommunityCategory: (state, action) => {
      state.selectedCategory = action.payload
    },
    count: (state, action) => {
      state.designBrowseCount = state.designBrowseCount + 1
    },
    setModalOpen: (state, action) => {
      state.modalOpen = true
    },
    setModalClose: (state, action) => {
      state.modalOpen = false
    },
    setShowPurchaseCoins: (state, action) => {
      state.showPurchaseCoins = action.payload ? action.payload : false
    },
    setPurchaseModalProps: (state, action) => {
      state.purchaseModalProps = action.payload
    }
  }
})

export const {
  reset,
  count,
  setModalOpen,
  setModalClose,
  setShowPurchaseCoins,
  setPurchaseModalProps,
  setSelectedCommunityCategory
} = dm.actions

export default dm.reducer
