import React, { useEffect, useRef, useState } from 'react'
import {
  SearchBarDropdownControl,
  SearchBarInput,
  SearchBarInputWrapper,
  SearchBarWrapper,
  SearchContainer,
  SearchFloatingWindow
} from './DesktopSearch.styles'
import searchImg from '../../assets/Search.svg'
import arrowDownImg from '../../assets/arrow-down.svg'
import { DropdownMenu } from '../common/DropdownMenu/DropdownMenu'
import QuickSearchContent from '../QuickSearchContent'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { SEARCH_MODES } from '../../constants/SearchConstants'
import locationChangeEvent from '../../utils/locationChangeEvent'

export const DesktopSearch = ({ isSearchMode, onBlur }) => {
  const OPTIONS = {
    ALL: {
      label: 'All',
      mode: SEARCH_MODES.ALL,
      action: () => {
        setSearchMode(OPTIONS.ALL)
      }
    },
    COMMUNITY: {
      label: 'Community',
      mode: SEARCH_MODES.COMMUNITY,
      action: () => {
        setSearchMode(OPTIONS.COMMUNITY)
      }
    },
    BLOG: {
      label: 'Blog',
      mode: SEARCH_MODES.BLOG,
      action: () => {
        setSearchMode(OPTIONS.BLOG)
      }
    }
  }

  const [searchMode, setSearchMode] = useState(OPTIONS.ALL)
  const [isFocused, setIsFocused] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const wrapperRef = useRef(null)

  useOnClickOutside(wrapperRef, () => {
    setIsFocused(false)
    onBlur()
  })

  useEffect(() => {
    const listener = location => {
      if (location.includes('/blog')) {
        setSearchMode(OPTIONS.BLOG)
      }

      if (
        location.includes('/community') ||
        location.includes('/dm') ||
        location.includes('/design')
      ) {
        setSearchMode(OPTIONS.COMMUNITY)
      }

      if (location === '/') {
        setSearchMode(OPTIONS.ALL)
      }
    }

    locationChangeEvent.subscribe(listener)

    return () => {
      locationChangeEvent.unsubscribe(listener)
    }
  }, [])

  return (
    <SearchContainer ref={wrapperRef}>
      {isFocused && (
        <SearchFloatingWindow>
          <QuickSearchContent
            searchMode={searchMode.mode}
            searchQuery={searchQuery}
            onClose={() => {
              setIsFocused(false)
              onBlur()
            }}
          />
        </SearchFloatingWindow>
      )}
      <SearchBarWrapper onFocus={() => setIsFocused(true)}>
        <SearchBarInputWrapper
          as="form"
          action={searchMode.mode === SEARCH_MODES.BLOG ? '/blog' : '/search'}
          active={isFocused}
        >
          <SearchBarInput
            name="q"
            autoComplete="off"
            placeholder="Search Interior Designs, Users, Hashtags, Blogs..."
            value={searchQuery}
            autoFocus={isSearchMode}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <img width={36} height={36} src={searchImg} alt={''} />
        </SearchBarInputWrapper>
        <DropdownMenu
          label={
            <SearchBarDropdownControl>
              <span>{searchMode.label}</span>
              <img width={14} height={14} src={arrowDownImg} alt="" />
            </SearchBarDropdownControl>
          }
          actions={Object.values(OPTIONS)}
        />
      </SearchBarWrapper>
    </SearchContainer>
  )
}
