export default function formatDate(val) {
  const date1 = new Date(val)
  const date2 = new Date()
  const diffTime = Math.abs(date1 - date2)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  let minutes = Math.ceil(diffTime / 60000) - 2
  let hours = Math.ceil(diffTime / (1000 * 60 * 60))
  if (minutes < 1) {
    return '1m'
  } else if (minutes < 60) {
    return `${minutes}m`
  } else if (minutes < 1200) {
    return `${hours}h`
  } else {
    return `${diffDays}d`
  }
}

function convertDigit(val) {
  return val > 9 ? val : '0' + val
}

export const getMembershipExpirationDate = me => {
  if (!me?.subscriberRecord?.subscriptions) return
  if (Object.keys(me.subscriberRecord.subscriptions).length === 0) return

  let renewDate =
    me?.subscriberRecord &&
    me?.subscriberRecord?.entitlements &&
    me?.subscriberRecord?.entitlements &&
    Object.values(me.subscriberRecord.entitlements).find(item =>
      item?.expires_date ? new Date(item?.expires_date) > new Date() : false
    )

  if (Object.keys(me.subscriberRecord.subscriptions).length > 0) {
    renewDate = Object.values(me.subscriberRecord.subscriptions).find(item =>
      item?.expires_date ? new Date(item?.expires_date) > new Date() : false
    )
  }

  if (!renewDate) return null

  const exp = Date.parse(renewDate?.expires_date)
  return formatDateToLocalizedString(new Date(exp))
}

export const formatDateToLocalizedString = date => {
  try {
    const eye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
    const emo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
    const eda = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)

    return `${emo} ${eda}, ${eye}`
  } catch (error) {
    return date?.toISOString()
  }
}
