import * as React from 'react'
import {
  PopularCard,
  SearchCardsGrid,
  SearchSubtitle,
  SearchTitleLine
} from './QuickSearchContent.styles'
import { usePopularHashtags } from '../../queries/search'
import Link from 'next/link'
import { useBlogCategories } from '../../queries/blog'

export const BlogCategories = ({ onClose }) => {
  const popularHashtags = usePopularHashtags()
  const blogCategories = useBlogCategories()

  return (
    <>
      <SearchTitleLine>
        <SearchSubtitle>Trends in DecorMatters</SearchSubtitle>
      </SearchTitleLine>
      <SearchCardsGrid>
        {blogCategories.data &&
          blogCategories.data.map(item => {
            return (
              <Link
                key={item.objectId}
                href={`/blog/category/${item.attributes.slug}`}
              >
                <PopularCard
                  wider
                  onClick={onClose}
                  background={
                    item.attributes.meta.metaImage.data.attributes.url
                  }
                >
                  <span>{item.attributes.title}</span>
                </PopularCard>
              </Link>
            )
          })}
        {blogCategories.isLoading &&
          new Array(6)
            .fill(0)
            .map((_, i) => <PopularCard key={i} wider placeholder="true" />)}
      </SearchCardsGrid>
    </>
  )
}
