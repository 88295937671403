import styled, { keyframes } from 'styled-components'

export const SearchBarWrapper = styled.div`
  background: #f5f5f5;
  border-radius: 64px;
  margin: auto;
  height: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  perspective: 2000px;
`

export const SearchBarInputWrapper = styled.div`
  background: #f5f5f5;
  border: 2px solid ${props => (props.active ? '#ff5e6d' : 'transparent')};
  border-radius: 64px;
  flex-grow: 1;
  height: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-right: 10px;
`

export const SearchBarInput = styled.input`
  border: none;
  background: #f5f5f5;
  border-radius: 64px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: black;
  padding: 13px 27px;

  &::placeholder {
    color: #8b8b8b;
  }
`

export const SearchBarDropdownControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  font-weight: 700;
  font-size: 14px;
  color: #333333;
  background: #ececec;
  border-radius: 100px;
  width: 124px;
  height: 44px;
  box-sizing: border-box;
  cursor: pointer;

  ${props =>
    props.active &&
    `
    background: white;
  `}

  & span {
    margin-right: 3px;
  }
`

export const SearchContainer = styled.div`
  position: relative;
  z-index: 1000;
  flex-grow: 1;
  margin-right: 10px;
`

const windowAnimation = keyframes`
  0% {
    transform: rotateX(-15deg);
  }
  
  100% {
    transform: rotateX(0);
  }
`

export const SearchFloatingWindow = styled.div`
  width: 100%;
  max-width: 720px;
  min-width: 720px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  position: absolute;
  top: 60px;
  padding: 20px;
  transform-origin: center -100px;
  transform: rotateX(-15deg);
  transition: transform 0.3s;
  animation: ${windowAnimation} 0.1s ease-in-out forwards;

  @media (max-width: 1350px) {
    right: 0;
  }
`
