import styled from 'styled-components'

export const ChallengeCardWrapper = styled.div`
  width: 300px;
  height: 300px;
  filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  @media (max-width: 1200px) {
    width: 240px;
    height: 240px;
  }

  @media (max-width: 1024px) {
    width: ${props => (props.wide ? '300px' : '240px')};
    height: ${props => (props.wide ? '300px' : '240px')};
  }

  @media (max-width: 800px) {
    width: ${props => (props.wide ? '46.5vw' : '140px')};
    height: ${props => (props.wide ? '46.5vw' : '140px')};
  }
`

export const ChallengeCardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 1%,
    rgba(0, 0, 0, 0) 50%
  );
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  box-sizing: border-box;

  &:hover {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 1%,
      rgba(0, 0, 0, 0) 70%
    );
  }

  @media (max-width: 800px) {
    padding: 8px 7px;
  }
`

export const ChallengeTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 70px;
    overflow: hidden;
  }
`

export const ChallengeTimeLeft = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;

  & img {
    filter: invert(1);
    width: 24px;
    height: 24px;
  }

  @media (max-width: 800px) {
    font-size: 8px;
    line-height: 10px;

    & img {
      width: 13.5px;
      height: 13.5px;
    }
  }
`

export const ButtonsOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 800px) {
    display: none;
    touch-actions: none;
  }
`

export const JoinNowButton = styled.div`
  user-select: none;
  text-decoration: none;
  text-align: center;
  padding: 8px 16px;
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
  color: white;
  background-color: #ff5e6d;
  border: 2px solid #ff5e6d;

  ${props =>
    props.disabled &&
    `
    opacity: 1 !important;
    color: #555;
    background-color: #f5f5f5;
    border: 2px solid #f5f5f5;
  `}

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 1200px) {
    transform: scale(0.95);
  }
`

export const MoreInfoButton = styled(JoinNowButton)`
  background-color: transparent;
  color: white;
  border: 2px solid white;

  &:hover {
    opacity: 1;
    background: #ff5e6d;
    border-color: #ff5e6d;
  }

  @media (max-width: 1200px) {
    transform: scale(0.95);
  }
`

export const ChallengeCardTypeIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 800px) {
    top: 10px;
    left: 10px;

    & svg {
      width: 30px;
      height: 30px;
    }
  }
`
