import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APPID } from '../../constants/ApiEndpoints'
import { DEV_ENDPOINT, PROD_ENDPOINT } from '../constants'
import { go } from '../../utils/fetchUtil'
import { getBrands } from './BrandSlice'

export const getItemsByIds = createAsyncThunk('design/getItemsByIds', async (d, { getState, dispatch }) => {
  const { brands } = getState().brand
  dispatch(setLoading(true))
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  var url = ep + 'getItems2'

  if (brands.length === 0) await dispatch(getBrands())

  const result = await go(url, { itemIds: d })

  return result.items
})

export const getItemsByIdeaId = createAsyncThunk('design/getItemsByIdeaId', async (objectId, { getState, dispatch }) => {
  dispatch(setLoading(true))
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  var url = ep + 'getIdea1'

  const result = await go(url, { objectId })

  //const items = result.idea.items || result.idea.productsWithPosition
  const items = result.idea.productsWithPosition
  items.map(item => item.item || item.usrItem || item)

  return items
})

export const getSimilarItemsByIds = createAsyncThunk('design/getSimilarItemsByIds', async (d, { getState, dispatch }) => {
  dispatch(setLoading(true))
  dispatch(setInitialSimilar())
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  var url = ep + 'getItems2'

  const result = await go(url, { itemIds: d })

  if (result.code) return null

  return {
    ids: d,
    items: result.items
  }
})

const item = createSlice({
  name: 'item',
  initialState: {
    items: [],
    similar: [],
    loading: false
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    clearItems: (state, { payload }) => {
      state.items = []
    },
    setInitialSimilar: (state, { payload }) => {
      state.similar = []
    }
  },
  extraReducers: {
    [getItemsByIds.fulfilled]: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    [getItemsByIdeaId.fulfilled]: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    [getSimilarItemsByIds.fulfilled]: (state, action) => {
      state.loading = false
      //Reorg to place selected item to top
      if (!action.payload) return
      const ids = action.payload.ids
      var order = []

      ids.forEach(e => {
        const it = action.payload.items.find(i => i.objectId === e)
        if (it) order.push(it)
      })
      let unique = [...new Set(order)]
      state.similar = unique
    }
  }
})

export const { setLoading, setInitialSimilar, clearItems } = item.actions

export default item.reducer
