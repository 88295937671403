import React from 'react'
import {
  FloatingMenuSocialIconsWrapper,
  SocialIcon,
  SocialLink
} from './FloatingMenuSocialIcons.styles'
import facebookIcon from '../../assets/floating-social/facebook.svg'
import instagramIcon from '../../assets/floating-social/instagram.svg'
import pinterestIcon from '../../assets/floating-social/pinterest.svg'
import tiktokIcon from '../../assets/floating-social/tiktok.svg'
import twitterIcon from '../../assets/floating-social/twitter.svg'

export const FloatingMenuSocialIcons = ({ centered }) => {
  return (
    <FloatingMenuSocialIconsWrapper centered={centered}>
      <SocialLink
        href="https://www.instagram.com/decormattersapp/"
        target="_blank"
        centered={centered}
      >
        <SocialIcon src={instagramIcon} alt="Instagram" />
      </SocialLink>
      <SocialLink
        centered={centered}
        href="https://www.tiktok.com/@decormatters"
        target="_blank"
      >
        <SocialIcon src={tiktokIcon} alt="TikTok" />
      </SocialLink>
      <SocialLink
        centered={centered}
        href="https://www.pinterest.com/DecorMatters"
        target="_blank"
      >
        <SocialIcon src={pinterestIcon} alt="Pinterest" />
      </SocialLink>
      <SocialLink
        centered={centered}
        href="https://twitter.com/decormattersapp/"
        target="_blank"
      >
        <SocialIcon src={twitterIcon} alt="Twitter" />
      </SocialLink>
      <SocialLink
        centered={centered}
        href="https://www.facebook.com/decormattersapp/"
        target="_blank"
      >
        <SocialIcon src={facebookIcon} alt="Facebook" />
      </SocialLink>
    </FloatingMenuSocialIconsWrapper>
  )
}
