import { STRIPE_ENDPOINT } from '../constants/ApiEndpoints'

export const createStripeAction =
  (action = '', method = 'GET', separator = '/') =>
  async (itemId = '', data = {}) => {
    const requestOptions = {
      method,
      headers: {}
    }

    if (method === 'POST') {
      requestOptions.body =
        typeof data === 'object' ? JSON.stringify(data) : data
      requestOptions.headers['Content-Type'] =
        'application/x-www-form-urlencoded; charset=utf-8'
    }

    try {
      const request = await fetch(
        `/api/payments/${action}${separator}${itemId}`,
        requestOptions
      )
      const result = await request.json()

      return result
    } catch (error) {
      console.log({ paymentApiError, error })
    }
  }
