export const FIREBASE_CONFIG_DEV = {
  apiKey: 'AIzaSyA1x4QBroclL6ATMzU5eGQmGdWAFFiG5Xk',
  authDomain: 'decormatters-web.firebaseapp.com',
  projectId: 'decormatters-web',
  storageBucket: 'decormatters-web.appspot.com',
  messagingSenderId: '11378377269',
  appId: '1:11378377269:web:a8af589dfb94e63262e77c',
  measurementId: 'G-JN2DL091YG'
}

export const FIREBASE_CONFIG_STAGE = {
  apiKey: 'AIzaSyA1x4QBroclL6ATMzU5eGQmGdWAFFiG5Xk',
  authDomain: 'decormatters-web.firebaseapp.com',
  projectId: 'decormatters-web',
  storageBucket: 'decormatters-web.appspot.com',
  messagingSenderId: '11378377269',
  appId: '1:11378377269:web:fa5799501e65f02962e77c',
  measurementId: 'G-YT3J9PJVV0'
}

export const FIREBASE_CONFIG_PROD = {
  apiKey: 'AIzaSyA1x4QBroclL6ATMzU5eGQmGdWAFFiG5Xk',
  authDomain: 'decormatters-web.firebaseapp.com',
  projectId: 'decormatters-web',
  storageBucket: 'decormatters-web.appspot.com',
  messagingSenderId: '11378377269',
  appId: '1:11378377269:web:5bed619aeb041a1762e77c',
  measurementId: 'G-KRPWZYFP7P'
}
