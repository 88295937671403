import { CoinsProgress, DailyRewardsButton, DailyRewardsButtonPlacer, ProgressBar, ProgressBarInside, RewardsInfo } from './FloatingButtons.styles'
import { ReactComponent as CoinIcon } from '../../assets/coin.svg'
import { useDailyRewardsInfo } from '../../hooks/useDailyRewardsInfo'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//import { resetAndOpen } from '../../redux/communityReducers/CheckInReducer'
import { useRouter } from 'next/router'
import useDailyTasks from '../../hooks/useDailyTasks'

const SHOW_PAGES = ['/', '/challenges', '/templates', '/community/explore', '/community/featured', '/leaders']

const arr = []

export const FloatingDailyRewards = () => {
  const { openDailyTask } = useDailyTasks()
  const router = useRouter()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const rewardedDtIds = useSelector(state => state.checkIn?.taskRecord?.rewardedDtIds) || arr
  const rewardedWgIds = useSelector(state => state.checkIn?.welcomeRecord?.rewardedWgIds) || arr
  const { active: checkInActive } = useSelector(state => state.checkIn)

  const { tasksList, specialTaskList } = useDailyRewardsInfo()

  const rewardedIds = useMemo(() => {
    return [...rewardedDtIds, ...rewardedWgIds]
  }, [rewardedDtIds, rewardedWgIds])

  const claimedAmound = useMemo(() => {
    if (!tasksList) return 0

    return tasksList.reduce((acc, task) => {
      if (rewardedIds.includes(task.objectId)) {
        acc += task.coinReward
      }

      return acc
    }, 0)
  }, [tasksList, rewardedIds])

  const totalAmount = useMemo(() => {
    if (!tasksList) return 0

    const specialTaskRewards = specialTaskList.reduce((acc, task) => {
      acc += task.taskCoinReward

      return acc
    }, 0)

    return (
      specialTaskRewards +
      tasksList.reduce((acc, task) => {
        acc += task.coinReward

        return acc
      }, 0)
    )
  }, [tasksList, specialTaskList])

  const progressBarPercentage = useMemo(() => {
    return (claimedAmound * 100) / totalAmount || 0
  }, [claimedAmound, totalAmount])

  if (!isAuthenticated || !SHOW_PAGES.includes(router.pathname) || checkInActive) return null

  return (
    <DailyRewardsButtonPlacer>
      <DailyRewardsButton
        complete={progressBarPercentage > 99}
        onClick={async () => {
          await openDailyTask()
          //dispatch(resetAndOpen())
          console.log('YOOOO')
        }}
      >
        <CoinIcon />
        <RewardsInfo>
          <span>Today's Rewards</span>
          <CoinsProgress>
            <span>{claimedAmound}</span>
            <ProgressBar>
              <ProgressBarInside percent={progressBarPercentage} />
            </ProgressBar>
            <span>{totalAmount}</span>
          </CoinsProgress>
        </RewardsInfo>
      </DailyRewardsButton>
    </DailyRewardsButtonPlacer>
  )
}
