import moment from 'moment'
import {
  GET_NOTIFICATIONS,
  GET_REWARD_NOTIFICATIONS,
  GET_WEEKLY_REPORTS,
  NOTIFICATIONS_MARK_ALL_READ,
  NOTIFICATIONS_RESET
} from '../../constants/ApiEndpoints'
import {
  LOAD_NOTIFICATIONS_LIST,
  LOAD_WEEKLY_REPORTS,
  NOTIFICATION_TYPES,
  RESET_NOTIFICATIONS_LIST,
  SET_NOTIFICATION_LOADING
} from '../../constants/NotificationTypeConstants'
import { loadMe } from '../../routes/Profile/store/profileReducer'
import { createApiAction } from '../../utils/reduxActionUtils'
import {
  getChallengeById,
  getCommentLikeStatusesByIds,
  getUsersById
} from '../apis'
import { setCommentLikesFromIds } from '../communityReducers/PostActionsReducer'
import { logout } from './AuthActions'

export const loadNotifications = createApiAction({
  endpoint: GET_NOTIFICATIONS,
  beforeRequest: ({ props }) => {
    return setNotificationsLoading(true, props.skip > 0)
  },
  errorHandler: console.log,
  handler: async ({ props, result, dispatch }) => {
    const {
      code,
      result: { notifications, pageLimit, onboardNotif }
    } = result
    const canLoadMore = notifications.length === pageLimit

    if (code) {
      dispatch(logout())
      return
    }

    let userIds = []

    notifications.forEach(notification => {
      if (notification.type === NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL) {
        userIds = userIds.concat(notification.users)
      }
    })

    const users = await getUsersById(userIds)

    const notificationsWithUsers = notifications.map(notification => {
      if (notification.type === NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL) {
        return {
          ...notification,
          users: notification.users.map(userId => {
            return users.find(user => user.objectId === userId)
          })
        }
      }

      return notification
    })

    const commentIds = []
    let likedComments = []

    notificationsWithUsers.forEach(notification => {
      if (
        notification.type === NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT ||
        notification.type === NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_REPLY
      ) {
        commentIds.push(notification.userInspirationComment.objectId)
      }
    })

    if (commentIds.length > 0) {
      const comments = await getCommentLikeStatusesByIds(commentIds)

      likedComments = comments.map(
        comment => comment?.userInspirationComment?.objectId
      )

      dispatch(setCommentLikesFromIds(likedComments))
    }

    if (onboardNotif && !props.isPersonal) {
      notificationsWithUsers.unshift(onboardNotif)
    }

    return getNotificationObj(
      notificationsWithUsers,
      pageLimit,
      props.skip > 0,
      canLoadMore
    )
  }
})

export const loadRewardNotifications = createApiAction({
  endpoint: GET_REWARD_NOTIFICATIONS,
  beforeRequest: ({ props }) => {
    return setNotificationsLoading(true, props.skip > 0)
  },
  handler: async ({ props, result }) => {
    const {
      result: { notifications, pageLimit }
    } = result
    const canLoadMore = notifications.length === pageLimit

    let challengeIds = []

    notifications.forEach(notification => {
      if (notification.type === NOTIFICATION_TYPES.CHALLENGE_REWARD) {
        challengeIds = challengeIds.concat(
          notification?.userInspiration?.designContest?.objectId
        )
      }
    })

    const challenges = {}

    for (const id of challengeIds) {
      const challenge = await getChallengeById(id)

      if (challenge) {
        challenges[id] = challenge
      }
    }

    const notificationsWithChallenges = notifications.map(notification => {
      if (notification.type === NOTIFICATION_TYPES.CHALLENGE_REWARD) {
        return {
          ...notification,
          designContest:
            challenges[notification?.userInspiration?.designContest?.objectId]
        }
      }

      return notification
    })

    return getNotificationObj(
      notificationsWithChallenges,
      pageLimit,
      props.skip > 0,
      canLoadMore
    )
  }
})

const startOfWeek = date => {
  var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)

  return new Date(date.setDate(diff))
}

export const loadWeeklyReports = createApiAction({
  endpoint: GET_WEEKLY_REPORTS,
  beforeRequest: ({ props }) => {
    return setNotificationsLoading(true, props.skip > 0)
  },
  errorHandler: console.log,
  handler: ({ props, result }) => {
    const {
      result: { weeklyReports }
    } = result

    weeklyReports.forEach(report => {
      const weekEndDate = new Date(report.endDate.iso)
      const weekEnd = moment(report.endDate.iso).format('MM.DD')
      const weekStart = moment(startOfWeek(weekEndDate)).format('MM.DD')

      const period = `${weekStart} - ${weekEnd}`

      report.type = NOTIFICATION_TYPES.WEEKLY_REPORT
      report.title = `Weekly Report (${period})`
      report.period = period
    })

    return {
      type: LOAD_WEEKLY_REPORTS,
      weeklyReports
    }
  }
})

export const resetUnreadCount = createApiAction({
  endpoint: NOTIFICATIONS_RESET,
  mapPropsToBody: props => ({ tabName: props.tabName }),
  handler: ({ props }) => loadMe({ history: props.history })
})

export const markAllNotificationsRead = createApiAction({
  endpoint: NOTIFICATIONS_MARK_ALL_READ,
  handler: () => resetNotifications()
})

export const resetNotifications = () => ({
  type: RESET_NOTIFICATIONS_LIST
})

export const setNotificationsLoading = (isLoading, isLoaded) => ({
  type: SET_NOTIFICATION_LOADING,
  isLoading,
  isLoaded
})

export const getNotificationObj = (
  notifications,
  pageLimit,
  append,
  canLoadMore
) => {
  return {
    type: LOAD_NOTIFICATIONS_LIST,
    notifications: notifications,
    pageLimit,
    append,
    canLoadMore
  }
}
