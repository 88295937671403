import { APPID } from '../constants/ApiEndpoints'
import { logoutExpired } from '../redux/actions/AuthActions'
import { parseToken } from './authLocalStorage'

export const parseHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Parse-Application-Id': APPID
  }

  const ptoken = parseToken() ? parseToken() : undefined

  if (ptoken) {
    headers['X-Parse-Session-Token'] = ptoken
  }

  return headers
}

export const awsHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Api-Key': AWS_API_KEY
  }

  return headers
}

export const go = async (url, body, dispatch) => {
  const option = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: body ? JSON.stringify(body) : null
  }

  const response = await fetch(url, option)
  const responseJson = await response.json()

  if (responseJson.code === 209) {
    dispatch(logoutExpired())

    return
  }

  return responseJson.result
}
