import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Helvetica', 'Roboto', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
*::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 46px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  body {
    padding-bottom: var(--sab);
    overflow: hidden;
  }
}
`

export const AreasWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`

export const ContentBox = styled.div`
  flex: 1;
  width: 100vw;
  height: 100%;
  overflow: auto !important;

  ${props => (props.disableOverscroll ? 'overflow: hidden !important;' : '')}
`
