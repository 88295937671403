import styled, { keyframes } from 'styled-components'

const modalBgKeyframes = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const MobileModalBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1003;
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};

  ${props =>
    props.respectHeader &&
    `
    top: 60px;
    height: calc(100vh - 60px);
    overflow: hidden;
  `}
`

export const ModalTouchable = styled.div`
  flex: 1;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.6);
  animation: ${modalBgKeyframes} 0.3s ease-in-out forwards;

  ${props =>
    props.hideOnDesktop &&
    `
    @media (min-width: 744px) {
      background: transparent;
    }
  `}
`
