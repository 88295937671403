import styled, { keyframes } from 'styled-components'

const drawerMenuKeyframes = keyframes`
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0;
  }
`

export const MobileDrawer = styled.div`
  width: 250px;
  height: 100%;
  background: #ffffff;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  margin-left: -100%;
  animation: ${drawerMenuKeyframes} 0.3s ease-in-out forwards;
`

export const DMLogo = styled.img`
  width: 134px;
  margin-top: 27px;
  margin-left: 15px;
  margin-bottom: 24px;
`

export const MenuLinks = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`

export const MenuLinkLine = styled.li`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  & img {
    width: 24px;
    height: 24px;
  }

  ${props =>
    props.underlined &&
    `
    border-bottom: 1px solid #DADCE0;
    margin-bottom: 8px;
    padding-bottom: 20px;
  `}
`

export const MenuLinkName = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #555555;
  margin-left: 12px;
`

export const GetAppButtonWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`
