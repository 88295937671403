import styled from 'styled-components'

export const TooltipContainer = styled.div`
  background: #ff6674;
  box-shadow: 0px 2px 14px rgba(255, 94, 109, 0.5);
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 42px;
  box-sizing: border-box;
  border-radius: 6px;
  width: 314px;
  position: relative;

  @media (max-width: 1000px) {
    padding: 26px 21px;
    font-size: 15px;
    line-height: 18px;
  }
`

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
  align-items: center;
  justify-content: center;
  position: relative;
  ${props => props.top && `top: ${props.top}px;`}
  ${props => props.left && `left: ${props.left}px;`}
  ${props => props.bottom && `bottom: ${props.bottom}px;`}
  ${props => props.right && `right: ${props.right}px;`}
`

export const TooltipSkipButton = styled.button`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
  border: none;
  background: transparent;
  position: absolute;
  top: 0;
  left: 30px;
  cursor: pointer;
`

export const TooltipSubtitle = styled.div`
  position: absolute;
  bottom: 5px;
  right: 12px;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #ffffff;
`
