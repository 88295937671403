import Parse from 'parse'
import { LINK_USER, LOGIN_WITH_EMAIL, LOGOUT, REGISTER_WITH_EMAIL2, REQUEST_PASSWORD_RESET, RESEND_VERIFICATION_EMAIL, VERIFY } from '../../constants/ApiEndpoints'
import { REGISTER_USER, RETURN_FROM_AUTH, SIGNING_IN, SIGNING_IN_VERIFIED, SIGNING_UP, USER_INVALID_SESSION_INVALID, USER_UPDATE, USER_VALID_SESSION_INVALID, USER_VALID_SESSION_VALID } from '../../constants/AuthActionsConstants'
import { loadMe, setRegSource } from '../../routes/Profile/store/profileReducer'
import { deleteAuthStorage, user as getUserName, setAuthStorage, setTokenUserId } from '../../utils/authLocalStorage'
import { parseHeaders } from '../../utils/fetchUtil'
import { logEvent, setUserId } from '../../utils/firebase'
import { getDailyTasks, getWelcomeTasks, welcomeGiftStatus } from '../communityReducers/CheckInReducer'
import { closeQuest, setIntroQuest, startQuest } from './QuestActions'

const anonymousCredentials = (dispatch, history) => {
  dispatch({ type: USER_INVALID_SESSION_INVALID })
  if (history) {
    window.location.href = '/'
  }
}

export const gotoLogIn = () => {
  return dispatch => {
    dispatch({ type: SIGNING_IN })
  }
}

export const gotoLogInAsVerified = () => {
  return dispatch => {
    dispatch({ type: SIGNING_IN_VERIFIED })
  }
}

export const gotoSignUp = (email = '') => {
  return dispatch => {
    dispatch({ type: SIGNING_UP, email })
  }
}

export const returnFromAuth = () => {
  return dispatch => {
    dispatch({ type: RETURN_FROM_AUTH })
  }
}

export const resendVerificationEmail = email => {
  const params = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({
      username: email
    })
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(RESEND_VERIFICATION_EMAIL, params)
      const responseJson = await response.json()
      logEvent('verification_email_sent')
      if (responseJson.error) {
        return reject(responseJson.error)
      } else {
        const result = responseJson.result
        //console.log(result)
        return resolve(result)
      }
    } catch (error) {
      return reject('server')
    }
  })
}

const parseVerify = username => {
  const params = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({
      username: username,
      emailVerified: true
    })
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(VERIFY, params)
      const responseJson = await response.json()
      if (responseJson.error) {
        return reject(responseJson.error)
      } else {
        const result = responseJson.result
        //console.log(result)
        return resolve(result)
      }
    } catch (error) {
      return reject('server')
    }
  })
}

export const loginParse = ({ email, password, history }, callback) => {
  //const { manualRefreshTasks } = useDecor()

  const params = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({
      username: email,
      password: password
    })
  }

  return dispatch => {
    return fetch(LOGIN_WITH_EMAIL, params)
      .then(response => response.json())
      .then(json => {
        if (json.code === 101) {
          throw json
        }

        const puser = json.result

        setUserId(puser.objectId)

        const user = {
          puser,
          cuser: null,
          userConfirmed: puser.emailVerified
        }

        setTokenUserId(puser.objectId, puser.sessionToken)
        setAuthStorage(puser.objectId, puser.sessionToken, null, null, user)

        Promise.all([dispatch(loadMe({ puser, history })), dispatch(getDailyTasks()), dispatch(getWelcomeTasks()), dispatch(welcomeGiftStatus())]).then(r => {
          if (puser.emailVerified === true) {
            dispatch({ type: USER_VALID_SESSION_VALID })
          } else {
            dispatch({ type: USER_VALID_SESSION_INVALID })
          }

          dispatch(returnFromAuth())
          callback()
        })
      })
  }
}

export const logout = history => async (dispatch, state) => {
  // await Auth.signOut()
  const params = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({})
  }

  sessionStorage.removeItem('openedDailyModal')

  if (!state().auth.user) {
    await fetch(LOGOUT, params)
  } else if (state().auth.user.puser.fb && state().auth.user.puser.fb === true) {
    //using this so it clears localstorage cause of fb
    document.location.reload()
  } else {
    await fetch(LOGOUT, params)
  }

  deleteAuthStorage()
  anonymousCredentials(dispatch, history)
  logEvent('logout')
}

export const logoutExpired = history => async dispatch => {
  deleteAuthStorage()
  anonymousCredentials(dispatch, history)
  // logEvent('logoutExpired')
}

export const registerParse = ({ nickname, email, password, history }, router) => {
  const params = {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify({
      username: email,
      password: password,
      uniqueDisplayName: nickname
    })
  }

  return dispatch => {
    return fetch(REGISTER_WITH_EMAIL2, params)
      .then(response => response.json())
      .then(json => {
        if (json.error) {
          throw json
        }

        const puser = json.result

        logEvent('sign_up')
        setUserId(puser.objectId)

        const user = {
          puser: puser.user,
          cuser: null,
          userConfirmed: false
        }

        setAuthStorage(puser.objectId, puser.sessionToken, null, null, user)

        dispatch(updateUser())

        Promise.all([dispatch(loadMe({ puser, history })), dispatch(getDailyTasks()), dispatch(getWelcomeTasks()), dispatch(welcomeGiftStatus())]).then(r => {
          dispatch(setRegSource())
          dispatch({ type: REGISTER_USER, user: user })
          const newToQuest = setIntroQuest(puser.user.objectId)
          if (newToQuest === true) dispatch(startQuest(puser.user.objectId))
          else dispatch(closeQuest())

          dispatch(returnFromAuth())
          if (router) {
            router.push('/')
          }
        })
      })
  }
}

export const forgotPassword = username => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders(),
        body: JSON.stringify({ email: username })
      }

      const response = await fetch(REQUEST_PASSWORD_RESET, params)
      const responseJson = await response.json()

      if (responseJson.error) {
        return reject(responseJson.error)
      } else {
        logEvent('reset_password_email_sent')
        const user = responseJson.result
        return resolve(user)
      }
    } catch (error) {
      return reject('server')
    }
  })
}

export const facebookLogin = async dispatch => {
  try {
    // TODO: update
    const user = await Parse.FacebookUtils.logIn()
    const fbUser = await new Promise(resolve => window.FB.api('/me', fbUser => resolve(fbUser)))

    const puser = {
      uniqueDisplayName: 'DM_User_' + user.id.slice(-2),
      username: fbUser.name.replace(' ', '_').toLowerCase(),
      fb: true,
      emailVerified: true,
      objectId: user.id,
      cfTbImageUrl: 'https://graph.facebook.com/' + fbUser.id + '/picture',
      ...user.attributes
    }

    logEvent('fb_login_success')
    loginUserInit(puser, dispatch)
    dispatch(loadMe({ history: {} }))
  } catch (error) {
    console.log(error)
    logEvent('fb_login_cancelled')
    alert('You cancelled the Facebook login or did not fully authorize.')
  }
}

export const googleLogin = googleUser => async dispatch => {
  try {
    const profile = googleUser.getBasicProfile()
    const authData = {
      id: profile.getId(),
      id_token: googleUser.getAuthResponse().id_token,
      email: profile.getEmail()
    }
    const options = {
      authData: authData,
      // email: profile.getEmail(),
      providerName: 'google'
    }

    console.log(profile)
    console.log(options)
    const params = {
      method: 'POST',
      mode: 'cors',
      headers: parseHeaders(),
      body: JSON.stringify(options)
    }
    const googleUserLinked = await new Promise((resolve, reject) => {
      fetch(LINK_USER, params)
        .then(response => {
          return response.json()
        })
        .then(resp => {
          if (resp.error) {
            alert(resp.error)
            return
          }

          const puser = {
            // //     //uniqueDisplayName: fbUser.name.replace(' ', '_').toLowerCase(),
            uniqueDisplayName: 'DM_User_' + resp.result.user.objectId.slice(-2),
            username: googleUser.profileObj.name,
            google: true,
            email: googleUser.profileObj.email,
            emailVerified: true,
            objectId: resp.result.user.objectId,
            cfTbImageUrl: googleUser.profileObj.imageUrl,
            sessionToken: resp.result.user.sessionToken,
            ...resp.result.user
          }
          logEvent('google_login_success')
          loginUserInit(puser, dispatch)
        })
        .catch(console.log)
    })
  } catch (error) {
    logEvent('google_login_cancelled')
    //   console.log(error)
    //   alert('You cancelled the Facebook login or did not fully authorize.')
  }
}

/* Used for google and fb login to setup init state */
const loginUserInit = async (puser, dispatch) => {
  setUserId(puser.objectId)
  setAuthStorage(puser.objectId, puser.sessionToken, null, null, {
    puser: puser
  })

  const user = await dispatch(loadMe({ history: {} }))

  dispatch(
    user_valid({
      puser: {
        ...puser,
        ...user.payload
      }
    })
  )
  dispatch(setRegSource())
}

/* end loginInit */

export const set_username = (username, dispatch) => {
  let userData = getUserName()

  const user = {
    ...userData.puser,
    uniqueDisplayName: username
  }

  setAuthStorage(null, null, null, null, { puser: user })

  dispatch(user_valid({ puser: user }))
}
export const user_valid = user => {
  return {
    type: USER_VALID_SESSION_VALID,
    user: user
  }
}

export const updateUser = user => {
  return {
    type: USER_UPDATE,
    user: user
  }
}
