import { gotoLogIn, logout } from '../redux/actions/AuthActions'
import { parseHeaders } from './fetchUtil'
import { logEvent } from './firebase'

export const requestHelper = body => {
  return {
    method: 'POST',
    mode: 'cors',
    headers: parseHeaders(),
    body: JSON.stringify(body)
  }
}

export const createAnalyticsEvent = (name, props) => {
  if (!name) return null

  return { name, props }
}

export const inpirationAnalyticsEventProps = props => ({
  content_type: 'inspiration',
  content_id: props.designId
})

export const createApiAction =
  ({
    endpoint,
    beforeRequest,
    handler,
    mapPropsToBody = props => props,
    errorHandler = error => {},
    ignoreAuth = false,
    redirectToAuth = false,
    analyticsEvent = createAnalyticsEvent()
  }) =>
  props => {
    const appendant = requestHelper(mapPropsToBody(props))

    return async (dispatch, state) => {
      const currentState = state()

      if (!ignoreAuth && currentState.auth.isAuthenticated === false) {
        if (redirectToAuth) dispatch(gotoLogIn())
        return false
      }

      if (!ignoreAuth && currentState?.auth?.isAuthenticated === false) {
        return false
      }

      try {
        if (beforeRequest) {
          const beforeAction = beforeRequest({ dispatch, currentState, props })
          if (beforeAction) dispatch(beforeAction)
        }

        const response = await fetch(endpoint, appendant)
        const result = await response.json()

        if (result.code && result.code === 209) {
          dispatch(logout())

          return false
        }

        if (analyticsEvent) {
          logEvent(
            analyticsEvent.name,
            analyticsEvent.props ? analyticsEvent.props(props) : undefined
          )
        }

        const finalAction = await handler({
          dispatch,
          currentState,
          response,
          result,
          props
        })

        if (typeof finalAction === 'object') {
          return dispatch(finalAction)
        }

        return true
      } catch (error) {
        if (errorHandler) errorHandler(error)
      }
    }
  }
