import classNames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import noNotificationsImg from '../../assets/No-notification.svg'
import {
  NOTIFICATION_SECTIONS,
  NOTIFICATION_TYPES
} from '../../constants/NotificationTypeConstants'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import {
  loadNotifications,
  loadRewardNotifications,
  loadWeeklyReports,
  markAllNotificationsRead,
  resetNotifications,
  resetUnreadCount
} from '../../redux/actions/NotificationActions'
import { NotificationResolver } from '../../utils/notifications'
import { LoadingIndicator } from '../Loading'
import {
  BackArrow,
  BackArrowButton,
  CategoryButton,
  EmptyState,
  EmptyStateButton,
  NotificationBlock,
  NotificationPeriodDate,
  NotificationsHeaderMobile,
  NotificationsList,
  NotificationsMenuContainer,
  NotificationTab,
  NotificationTabs,
  PopupContainer,
  PopupHeader,
  PopupHeaderBackButton,
  PopupHeaderTitle,
  TabCategories,
  TabContent
} from './NotificationsMenu.styles'
import { ReactComponent as LiveNowImg } from '../../assets/notifications/liveNow.svg'
import { ReactComponent as WinnersImg } from '../../assets/notifications/winners.svg'
import { ReactComponent as NewsImg } from '../../assets/notifications/news.svg'
import { ReactComponent as LikesImg } from '../../assets/notifications/likes.svg'
import { ReactComponent as GiftsImg } from '../../assets/notifications/gifts.svg'
import { ReactComponent as CommentsImg } from '../../assets/notifications/comments.svg'
import { ReactComponent as FollowersImg } from '../../assets/notifications/followers.svg'
import backImg from '../../assets/notifications/back.svg'

import { useRouter } from 'next/router'
import { groupNotificationsByPeriod } from '../../utils/groupNotificationsByPeriod'
import { WeeklyReportDetailed } from './WeeklyReportDetailed'
import { useNewNotifications } from '../../queries/notifications'

const ALL_GLOBAL_TYPES = [
  NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL,
  NOTIFICATION_TYPES.NEW_CHALLENGE_GLOBAL,
  NOTIFICATION_TYPES.NEW_USER_DAILY_PICK,
  NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL,
  NOTIFICATION_TYPES.MONTHLY_WINNERS,
  NOTIFICATION_TYPES.DAILY_DESIGN_WINNER,
  NOTIFICATION_TYPES.RELEASE,
  NOTIFICATION_TYPES.ITEM_BUNDLE_SALE_GLOBAL,
  NOTIFICATION_TYPES.NEW_PRODUCTS_GLOBAL,
  NOTIFICATION_TYPES.AVATAR,
  NOTIFICATION_TYPES.GIFT
]

const YOU_SUB_CATEGORIES = {
  LIKES: {
    type: 'likes',
    name: 'Likes',
    img: LikesImg,
    modalTypes: [
      NOTIFICATION_TYPES.USER_INSPIRATION_LIKE,
      NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_LIKE,
      NOTIFICATION_TYPES.MULTI_LIKE
    ]
  },
  GIFTS: {
    type: 'gifts',
    name: 'Gifts',
    img: GiftsImg,
    modalTypes: [
      NOTIFICATION_TYPES.GIFT,
      NOTIFICATION_TYPES.COIN_GIFT,
      NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT,
      NOTIFICATION_TYPES.MULTI_GIFT
    ]
  },
  FOLLOWERS: {
    type: 'followers',
    name: 'Followers',
    img: FollowersImg,
    modalTypes: [NOTIFICATION_TYPES.FOLLOW]
  },
  COMMENTS: {
    type: 'comments',
    name: 'Comments',
    img: CommentsImg,
    modalTypes: [
      NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT,
      NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_REPLY
    ]
  }
}

const OFFICIAL_SUB_CATEGORIES = {
  LIVE_NOW: {
    type: 'liveNow',
    name: 'Live Now',
    img: LiveNowImg,
    globalTypes: [
      NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL,
      NOTIFICATION_TYPES.NEW_CHALLENGE_GLOBAL
    ]
  },
  WINNERS: {
    type: 'winners',
    name: 'Winners',
    img: WinnersImg,
    globalTypes: [
      NOTIFICATION_TYPES.NEW_USER_DAILY_PICK,
      NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL,
      NOTIFICATION_TYPES.MONTHLY_WINNERS,
      NOTIFICATION_TYPES.DAILY_DESIGN_WINNER
    ]
  },
  NEWS: {
    type: 'news',
    name: 'News',
    img: NewsImg,
    globalTypes: [
      NOTIFICATION_TYPES.RELEASE,
      NOTIFICATION_TYPES.ITEM_BUNDLE_SALE_GLOBAL,
      NOTIFICATION_TYPES.NEW_PRODUCTS_GLOBAL,
      NOTIFICATION_TYPES.AVATAR,
      NOTIFICATION_TYPES.GIFT
    ]
  }
}

const tabsList = [
  {
    type: 'you',
    name: 'Personal',
    filters: NOTIFICATION_SECTIONS.YOU,
    subCategories: YOU_SUB_CATEGORIES
  },
  {
    type: 'rewards',
    name: 'Exclusive',
    filters: NOTIFICATION_SECTIONS.REWARDS
  },
  {
    type: 'official',
    name: 'Official',
    filters: NOTIFICATION_SECTIONS.OFFICIAL,
    subCategories: OFFICIAL_SUB_CATEGORIES
  }
]

const NotificationsMenu = props => {
  const dispatch = useDispatch()
  const { notifications, isLoaded, weeklyReports, isLoading, canLoadMore } =
    useSelector(state => state.notifications)
  const [activeTab, setActiveTab] = useState(tabsList[2])
  const [showWeekly, setShowWeekly] = useState(false)
  const [detailedReport, setDetailedReport] = useState(null)
  const currentFilter = useMemo(
    () => activeTab.filters || NOTIFICATION_SECTIONS.YOU,
    [activeTab]
  )
  const { close } = props
  const scrollListRef = useRef(null)
  const wrapperRef = useRef(null)
  const [subCategory, setSubCategory] = useState(null)
  const router = useRouter()

  const newNotifications = useNewNotifications(activeTab)

  useOnClickOutside(wrapperRef, close)

  useEffect(() => {
    if (activeTab.type === 'you') newNotifications.refetch()
  }, [activeTab, newNotifications.refetch])

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      if (currentFilter === NOTIFICATION_SECTIONS.REWARDS) {
        dispatch(loadRewardNotifications({}))

        return
      }

      let globalTypes = currentFilter.globalTypes
      let types = currentFilter.types

      if (activeTab.type === 'official' || activeTab.type === 'you') {
        if (subCategory) globalTypes = subCategory.globalTypes || []
        else globalTypes = ALL_GLOBAL_TYPES

        if (subCategory?.modalTypes) {
          types = subCategory?.modalTypes
        }
      }

      dispatch(
        loadNotifications({
          types: types,
          globalTypes,
          isPersonal: activeTab.type === 'you',
          skip: 0
        })
      )
    }
  }, [subCategory, currentFilter, isLoaded, isLoading, dispatch])

  useEffect(() => {
    if (activeTab.type !== 'you') return

    dispatch(
      loadWeeklyReports({
        skip: 0
      })
    )
  }, [dispatch, activeTab.type])

  let notificationsList = notifications || []
  const notificationsCount = notificationsList.length

  useEffect(() => {
    if (notificationsCount === 0) return

    const ref = scrollListRef.current

    const listener = event => {
      const el = event.target

      if (
        el.scrollTop !== 0 &&
        el.scrollHeight - el.scrollTop - el.clientHeight < 100 &&
        !isLoading &&
        canLoadMore
      ) {
        if (currentFilter === NOTIFICATION_SECTIONS.REWARDS) {
          dispatch(loadRewardNotifications({ skip: notificationsCount }))

          return
        }

        let globalTypes = currentFilter.globalTypes
        let types = currentFilter.types

        if (activeTab.type === 'official' || activeTab.type === 'you') {
          if (subCategory) globalTypes = subCategory.globalTypes || []
          else globalTypes = ALL_GLOBAL_TYPES

          if (subCategory?.modalTypes) {
            types = subCategory?.modalTypes
          }
        }

        dispatch(
          loadNotifications({
            types,
            globalTypes,
            isPersonal: activeTab.type === 'you',
            skip: notificationsCount
          })
        )
      }
    }
    if (ref) ref.addEventListener('scroll', listener, false)

    return () => {
      if (ref) ref.removeEventListener('scroll', listener)
    }
  }, [
    scrollListRef,
    currentFilter,
    isLoading,
    dispatch,
    notificationsCount,
    canLoadMore,
    activeTab
  ])

  const handleTabChange = tab => {
    if (tab === activeTab) return
    dispatch(resetNotifications())

    if (tab.subCategories) {
      setSubCategory(null)
    }

    setActiveTab(tab)

    if (activeTab === tabsList[0]) newNotifications.refetch()
  }

  const handleCategoryChange = category => {
    setSubCategory(category)
    dispatch(resetNotifications())
  }

  useEffect(() => {
    return () => {
      // Remove notifications when window is closed
      dispatch(resetUnreadCount({ tabName: activeTab.type, history: {} }))
      dispatch(markAllNotificationsRead())
    }
  }, [])

  const notificationPeriods = useMemo(() => {
    return groupNotificationsByPeriod(
      notificationsList,
      activeTab.type === 'you' ? weeklyReports : []
    )
  }, [notificationsList, weeklyReports])

  const notificationListRendered = (
    <>
      {notificationsList.length === 0 &&
        weeklyReports.length === 0 &&
        isLoaded && (
          <EmptyState withoutPadding={subCategory && subCategory?.modalTypes}>
            <img src={noNotificationsImg} alt="" />
            <p>No notifications yet</p>
            <span>Start creating designs to earn rewards</span>
            <EmptyStateButton
              onClick={() => {
                router.push('/')
                close()
              }}
            >
              Start Designing
            </EmptyStateButton>
          </EmptyState>
        )}
      <NotificationsList ref={scrollListRef}>
        {newNotifications?.data?.length > 0 &&
          activeTab === tabsList[0] &&
          subCategory === null && (
            <React.Fragment>
              <NotificationPeriodDate>New</NotificationPeriodDate>
              {newNotifications.data.map((notification, index) => {
                return (
                  <NotificationBlock key={notification.objectId}>
                    <NotificationResolver
                      key={index}
                      onClick={
                        notification.type ===
                        NOTIFICATION_TYPES.WEEKLY_REPORT_SMALL
                          ? () => setShowWeekly(true)
                          : close
                      }
                      notification={notification}
                    />
                  </NotificationBlock>
                )
              })}
            </React.Fragment>
          )}
        {notificationPeriods.map((period, index) => {
          if (period.notifications.length === 0) return null

          return (
            <React.Fragment key={period.title}>
              <NotificationPeriodDate>{period.title}</NotificationPeriodDate>
              {period.notifications.map((notification, index) => {
                return (
                  <NotificationBlock key={notification.objectId}>
                    <NotificationResolver
                      key={index}
                      onClick={
                        notification.type ===
                        NOTIFICATION_TYPES.WEEKLY_REPORT_SMALL
                          ? () => setShowWeekly(true)
                          : close
                      }
                      notification={notification}
                    />
                  </NotificationBlock>
                )
              })}
            </React.Fragment>
          )
        })}
        <li>
          <LoadingIndicator loading={isLoading} />
        </li>
      </NotificationsList>
    </>
  )

  const renderWeeklyReports = () => {
    if (detailedReport) {
      return (
        <WeeklyReportDetailed handleClose={close} report={detailedReport} />
      )
    }
    return (
      <NotificationsList>
        {weeklyReports.map((report, idx) => (
          <NotificationResolver
            key={idx}
            onClick={() => {
              setDetailedReport(report)
            }}
            notification={report}
          />
        ))}
      </NotificationsList>
    )
  }

  return (
    <div ref={wrapperRef}>
      <NotificationsMenuContainer>
        {showWeekly ? (
          <>
            <NotificationTabs>
              <NotificationsHeaderMobile show>
                <BackArrowButton
                  onClick={() => {
                    if (detailedReport) {
                      setDetailedReport(null)
                    } else {
                      setShowWeekly(false)
                    }
                  }}
                >
                  <BackArrow />
                </BackArrowButton>{' '}
                Weekly Report
              </NotificationsHeaderMobile>
            </NotificationTabs>
            {renderWeeklyReports()}
          </>
        ) : (
          <>
            <NotificationsHeaderMobile>
              <BackArrowButton onClick={close}>
                <BackArrow />
              </BackArrowButton>
              Notifications
            </NotificationsHeaderMobile>
            <NotificationTabs>
              {tabsList.map(tab => (
                <NotificationTab
                  key={tab.type}
                  className={classNames({
                    active: activeTab === tab
                  })}
                >
                  <TabContent
                    hasUpdates={false}
                    onClick={() => handleTabChange(tab)}
                  >
                    {tab.name}
                  </TabContent>
                </NotificationTab>
              ))}
            </NotificationTabs>
            {activeTab.subCategories && (
              <TabCategories>
                {Object.keys(activeTab.subCategories).map(key => {
                  const category = activeTab.subCategories[key]

                  const ImgComponent = category.img

                  return (
                    <CategoryButton
                      key={category.type}
                      onClick={() => {
                        handleCategoryChange(category)
                      }}
                      selected={subCategory === category}
                    >
                      <ImgComponent />
                      {category.name}
                    </CategoryButton>
                  )
                })}
              </TabCategories>
            )}
            {notificationListRendered}
            {subCategory && subCategory?.modalTypes && (
              <PopupContainer>
                <PopupHeader>
                  <PopupHeaderBackButton
                    onClick={() => {
                      dispatch(resetNotifications())
                      handleTabChange(tabsList[0])
                      setSubCategory(null)
                    }}
                  >
                    <img src={backImg} alt="" />
                  </PopupHeaderBackButton>
                  <PopupHeaderTitle>{subCategory.name}</PopupHeaderTitle>
                </PopupHeader>
                {notificationListRendered}
              </PopupContainer>
            )}
          </>
        )}
      </NotificationsMenuContainer>
    </div>
  )
}

export { NotificationsMenu }
