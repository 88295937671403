import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APPID } from '../../constants/ApiEndpoints'
import { DEV_ENDPOINT, PROD_ENDPOINT } from '../constants'
import { go } from '../../utils/fetchUtil'

export const getBrands = createAsyncThunk(
  'design/getBrands',
  async (d, { dispatch }) => {
    const result = await fetch(
      APPID === '1'
        ? 'https://decormatters-dev.s3-us-west-1.amazonaws.com/store.json'
        : 'https://decormatters-prod.s3-us-west-1.amazonaws.com/store.json'
    )

    const json = result.json()

    return json
  }
)

const brand = createSlice({
  name: 'brand',
  initialState: {
    brands: [],
    brandsByKeys: {}
  },
  reducers: {},
  extraReducers: {
    [getBrands.fulfilled]: (state, { payload }) => {
      if (!payload) return
      //state.loading = false
      state.brands = payload

      var obj = {}
      payload.forEach(e => {
        obj[e.indixStoreId] = {
          ...e
        }
      })
      state.brandsByKeys = obj
    }
  }
})

//export const { setLoading, clearItems } = brand.actions

export default brand.reducer
