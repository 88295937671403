import {
  AUTH_STATE,
  REGISTER_USER,
  RETURN_FROM_AUTH,
  SIGNING_IN,
  SIGNING_IN_VERIFIED,
  SIGNING_UP,
  USER_INVALID_SESSION_INVALID,
  USER_UPDATE,
  USER_VALID_SESSION_INVALID,
  USER_VALID_SESSION_VALID
} from '../../constants/AuthActionsConstants'

const initialState = {
  isAuthenticated: false,
  showAuth: false,
  verified: true,
  authState: AUTH_STATE.NONE,
  presetEmail: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNING_IN: {
      return {
        ...state,
        showAuth: true,
        authState: AUTH_STATE.LOGIN
      }
    }
    case SIGNING_IN_VERIFIED: {
      return {
        ...state,
        showAuth: true,
        authState: AUTH_STATE.LOGIN
      }
    }
    case SIGNING_UP: {
      return {
        ...state,
        showAuth: true,
        authState: AUTH_STATE.SIGNUP,
        presetEmail: action.email
      }
    }
    case RETURN_FROM_AUTH: {
      return {
        ...state,
        showAuth: false
      }
    }
    case USER_UPDATE: {
      return {
        ...state
      }
    }
    case USER_VALID_SESSION_VALID: {
      return {
        ...state,
        //user: action.user,
        showAuth: false,
        verified: true,
        isAuthenticated: true
      }
    }
    case USER_VALID_SESSION_INVALID: {
      return {
        ...state,
        //user: action.user,
        verified: false,
        isAuthenticated: true
      }
    }
    case USER_INVALID_SESSION_INVALID: {
      return {
        ...state,
        verified: true,
        isAuthenticated: false
      }
    }
    case REGISTER_USER: {
      return {
        ...state,
        verified: false,
        isAuthenticated: true
      }
    }
    default:
  }

  return state
}
