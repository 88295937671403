import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APPID } from '../constants/ApiEndpoints'
import { DEV_ENDPOINT, PROD_ENDPOINT } from './constants'
import { go } from '../utils/fetchUtil'
import { setLikes } from './actions/LikeAction'
import { FEED_HARD_RESET, FEED_RESET } from './constants'
// Cache
const countersByName = {}
const pageLimit = 15

export const checkFeedEnd = (status, pageNum, isPageEnd) => {
  var nextPage = pageNum
  if (status === FEED_HARD_RESET) return 0
  if (isPageEnd === true && status !== FEED_RESET) return -1
  if (status === FEED_RESET) nextPage = 0
  return nextPage
}

export const fetchFeed = sliceName =>
  createAsyncThunk(
    `${sliceName}/fetchFeed`,
    async ({ status }, { getState, dispatch }) => {
      const { page, end, endpoint, attr } = getState()[sliceName]

      const nextPage = checkFeedEnd(status, page, end)

      if (nextPage === -1) return null

      dispatch(setLoading(sliceName)('pending'))

      var body = {
        skip: nextPage * pageLimit,
        pageLimit: pageLimit
      }

      const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
      var url = ep + endpoint

      if (status !== FEED_HARD_RESET) {
        if (attr && attr.replace) {
          body = {
            ...attr.replace
          }
        }

        if (attr && attr.addition) {
          body = {
            ...body,
            ...attr.addition
          }
        }

        if (attr && attr.url) url = attr.url
      }

      const result = await go(url, body, dispatch)

      if (result.currentUserlikes) {
        dispatch(setLikes(result))
      }

      if (result.expire) {
        return { expire: result.expire }
      }
      return {
        data: result,
        page: nextPage,
        status
      }
    }
  )

export const createGenericSlice = (sliceName, loc, endpoint) => {
  return createSlice({
    name: sliceName,
    initialState: {
      loc,
      endpoint,
      feed: [],
      page: 0,
      end: false,
      error: null,
      loading: 'idle',
      attr: null,
      preloaded: false
    },
    reducers: {
      setAttr: (state, { payload }) => {
        state.attr = payload
        state.feed = []
        state.end = false
        state.page = 0
      },
      setLoc: (state, { payload }) => {
        state.loc = payload
      },
      setLoading: (state, { payload }) => {
        state.loading = payload
      },
      updateFeedElement: (state, { payload }) => {
        const index = state.feed.findIndex(
          item => item.objectId === payload.objectId
        )

        if (index !== -1) {
          state.feed[index] = payload
        }
      },
      setPreloadedFeed: (state, { payload }) => {
        state.preloaded = true
        state.feed = payload.userInspirations
        state.page = Math.floor(
          payload.userInspirations.length / payload.pageLimit
        )
        state.loading = 'idle'
      }
    },
    extraReducers: {
      [fetchFeed(sliceName).pending]: (state, action) => {
        state.error = null
        if (action.meta && action.meta.arg === FEED_RESET) {
          state.feed = []
          state.page = 0
          state.end = false
        }
        if (state.page === 0) state.loading = 'pending'
      },
      [fetchFeed(sliceName).fulfilled]: (state, { payload }) => {
        state.loading = 'idle'
        if (!payload) return
        if (payload.expire) {
          state.error = 'expire'
          return
        }
        if (
          Object.keys(payload.data).length === 0 &&
          payload.data.constructor === Object
        ) {
          state.end = true
          state.feed = []
        } else {
          if (state.attr && state.attr.loc) {
            state.end = true
            if (state.attr.single) {
              state.feed = [payload.data[state.attr.loc]]
            } else {
              state.feed = [...payload.data[state.attr.loc]]
            }
          } else {
            if (
              payload &&
              payload.data[state.loc] &&
              payload.data[state.loc].length + payload.data.numBlocked <
                pageLimit
              // || !payload.data[state.loc] // fix infinite scroll on profile dtafts
            ) {
              state.end = true
            } else if (payload.data?.length < pageLimit) {
              state.end = true
            } else state.end = false

            const data = payload.data[state.loc] || payload.data

            if (
              ((payload &&
                payload.page === 0 &&
                payload.data &&
                payload.data[state.loc]) ||
                payload.status === FEED_RESET ||
                payload.status === FEED_HARD_RESET) &&
              !state.preloaded
            ) {
              state.feed = [...data]
            } else {
              state.feed = [...state.feed, ...data]
            }
          }
        }

        state.page += 1
        if (state.feed.length === 0) state.error = 'empty'
        else state.error = null
      },
      [fetchFeed(sliceName).rejected]: (state, { error }) => {
        console.log('test thunk rejected ')
      }
    }
  })
}

export const setLoc = feedName => countersByName[feedName].actions.setLoc
export const setAttr = feedName => countersByName[feedName].actions.setAttr
export const updateFeedElement = feedName =>
  countersByName[feedName].actions.updateFeedElement
export const setLoading = feedName =>
  countersByName[feedName].actions.setLoading
export const setPreloadedFeed = feedName =>
  countersByName[feedName].actions.setPreloadedFeed

const createInfiniteFeed = (name, loc, endpoint) => {
  const slice = createGenericSlice(name, loc, endpoint)
  countersByName[name] = {
    actions: slice.actions
  }

  return slice.reducer
}

export default createInfiniteFeed
