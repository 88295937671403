import * as React from 'react'
import { QuickSearchContentWrapper } from './QuickSearchContent.styles'
import { SEARCH_MODES } from '../../constants/SearchConstants'
import { SearchSuggestions } from './SearchSuggestions'
import { RecentSearches } from './RecentSearches'
import { PopularHashtags } from './PopularHashtags'
import { LatestBlogPosts } from './LatestBlogPosts'
import { BlogCategories } from './BlogCategories'

export const QuickSearchContent = ({ onClose, searchQuery, searchMode }) => {
  return (
    <QuickSearchContentWrapper>
      <SearchSuggestions searchQuery={searchQuery} onClose={onClose} />
      {!searchQuery && searchMode === SEARCH_MODES.ALL && (
        <>
          <RecentSearches />
          <PopularHashtags onClose={onClose} />
          <LatestBlogPosts onClose={onClose} />
        </>
      )}
      {!searchQuery && searchMode === SEARCH_MODES.COMMUNITY && (
        <>
          <RecentSearches />
          <PopularHashtags onClose={onClose} />
        </>
      )}
      {!searchQuery && searchMode === SEARCH_MODES.BLOG && (
        <>
          <RecentSearches />
          <BlogCategories />
          <LatestBlogPosts onClose={onClose} />
        </>
      )}
    </QuickSearchContentWrapper>
  )
}
