import {
  getAnalytics,
  logEvent as logEventToFirebase,
  setUserId as setUserIdToFirebase
} from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue
} from 'firebase/remote-config'
import { IS_PRODUCTION } from '../constants/ApiEndpoints'
import {
  FIREBASE_CONFIG_DEV,
  FIREBASE_CONFIG_PROD,
  FIREBASE_CONFIG_STAGE
} from '../constants/FirebaseConstants'
import RemoteConfigDefaults from '../constants/RemoteConfigDefaults'

class Firebase {
  app = null
  remoteConfig = null
  analytics = null

  constructor() {
    if (typeof window !== 'undefined') {
      this.app = initializeApp(this.getFirebaseConfig())
      this.remoteConfig = getRemoteConfig(this.app)
      this.analytics = getAnalytics(this.app)

      this.setupRemoteConfig()
      this.fetchAndActivate()
    }
  }

  getFirebaseConfig = () => {
    try {
      if (IS_PRODUCTION) return FIREBASE_CONFIG_PROD

      if (window.location.host.includes('stage')) return FIREBASE_CONFIG_STAGE

      return FIREBASE_CONFIG_DEV
    } catch (error) {
      return FIREBASE_CONFIG_PROD
    }
  }

  setupRemoteConfig = () => {
    this.remoteConfig.settings.minimumFetchIntervalMillis = 3600000
    this.remoteConfig.defaultConfig = RemoteConfigDefaults
  }

  getConfigValue = key => {
    const defaultReturn = {
      asBoolean: () => {
        return RemoteConfigDefaults[key] || RemoteConfigDefaults[key]
      },
      asNumber: () => Number(RemoteConfigDefaults[key]),
      asString: () => RemoteConfigDefaults[key]
    }

    try {
      if (this.remoteConfig && typeof window !== 'undefined')
        return getValue(this.remoteConfig, key)

      return defaultReturn
    } catch (error) {
      return defaultReturn
    }
  }

  logEvent = (...props) => {
    if (props) logEventToFirebase(this.analytics, ...props)
  }

  setUserId = (...props) => {
    if (props && this.analytics) setUserIdToFirebase(this.analytics, ...props)
  }

  fetchAndActivate = (callback = () => {}, errorCallback = () => {}) => {
    fetchAndActivate(this.remoteConfig).then(callback).catch(errorCallback)
  }
}

const firebaseInstance = new Firebase()
export const { logEvent, setUserId, getConfigValue } = firebaseInstance
