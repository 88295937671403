import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  GET_DESIGN_GAMES
  // GET_DID_JOIN_USER_EVENT,
  // GET_JOIN_CHALLENGE,
  // GET_OTHER_USER
} from '../../constants/ApiEndpoints'
import { api, checkFeedStatus } from '../../utils/fetchUtils'
import { FEED_RESET } from '../constants'

const pageLimit = 15

export const fetchDesignGames = createAsyncThunk(
  'designGames/fetchDesignGames',
  async (data, { getState }) => {
    const { designGames, designGamesPage, designGamesPageEnd } =
      getState().designGames

    const nextPage = checkFeedStatus(
      data,
      designGames.length,
      designGamesPage,
      designGamesPageEnd
    )
    if (nextPage === -1) return null

    const body = {
      skip: nextPage * pageLimit,
      pageLimit,
      filter: 'active'
    }

    let response = await api(GET_DESIGN_GAMES, JSON.stringify(body))

    return {
      status,
      data: response.result,
      page: nextPage
    }
  }
)

const designGames = createSlice({
  name: 'designGames',
  initialState: {
    designGames: [],
    designGamesPage: 0,
    designGamesPageEnd: false,
    designGamesError: null,
    loading: 'pending',
    status: {
      joined: [],
      started: []
    }
  },
  reducers: {},
  extraReducers: {
    [fetchDesignGames.pending]: (state, action) => {
      state.designGamesError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.designGames = []
        state.designGamesPage = 0
        state.designGamesPageEnd = false
      }
      if (state.designGamesPage === 0) state.loading = 'pending'
    },
    [fetchDesignGames.fulfilled]: (state, action) => {
      state.loading = 'idle'

      if (!action.payload) return

      if (action?.payload?.data?.length < pageLimit)
        state.designGamesPageEnd = true
      else state.designGamesPageEnd = false

      const response = action.payload.data

      // state.designGames = [...state.designGames, ...response.content]
      state.designGames = [...response.content]
      state.designGamesPage = action.payload.page + 1

      state.status.joined = [
        ...(response?.userChallengeStatuses?.joined || []),
        ...(response?.userEventStatuses?.joined || [])
      ]

      state.status.started = [
        ...(response?.userChallengeStatuses?.started || []),
        ...(response?.userEventStatuses?.started || [])
      ]

      if (state.designGames.length === 0) state.designGamesError = 'empty'
      else state.designGamesError = null
    }
  }
})

export default designGames.reducer
