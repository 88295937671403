import React from 'react'
import { getRoute } from '../../constants/navigation'
import Link from 'next/link'

export const SmartLink = ({ route, children, ...rest }) => {
  const routeInfo = getRoute(route)
  const props = routeInfo.props || {}

  if (routeInfo.outside)
    return (
      <a href={routeInfo.path} {...props}>
        {children}
      </a>
    )

  return (
    <Link href={routeInfo.path} {...props} {...rest}>
      {children}
    </Link>
  )
}
