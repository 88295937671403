import moment from 'moment'

const getItemPrice = (data = {}) => {
  const result = {
    price: data.numCoins,
    discount: data.discountPrice
      ? {
          price: data.discountPrice,
          percent: data.discountPercentage
        }
      : null,
    extraDiscount: null,
    finalPrice: data.discountPrice || data.numCoins
  }

  const { limitedDiscount, discountExtraPrice, discountExtraPercentage } = data

  if (
    !!limitedDiscount &&
    !moment(limitedDiscount?.expiresAt).isBefore(moment())
  ) {
    result.discount = {
      price: limitedDiscount?.numCoins,
      percent: limitedDiscount?.percent
    }

    result.finalPrice = limitedDiscount?.numCoins

    if (!!discountExtraPrice) {
      result.extraDiscount = {
        price: discountExtraPrice,
        percent: result.discount.percent + discountExtraPercentage
      }

      result.finalPrice = discountExtraPrice
    }
  }
  return result
}

export default getItemPrice
