import styled from 'styled-components'

export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.background || 'transparent'};
  z-index: -1;
`

export const Circle = styled.div`
  position: absolute;
  top: ${props => props.top || 'unset'};
  left: ${props => props.left || 'unset'};
  right: ${props => props.right || 'unset'};
  bottom: ${props => props.bottom || 'unset'};
  width: 100px;
  height: 100px;
  content: '';
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  transform: scale(${props => props.scale || 1});
`
