import {
  LIKE_COMMENT,
  LIKE_INSPIRATION,
  UNLIKE_COMMENT,
  UNLIKE_INSPIRATION
} from '../../constants/ApiEndpoints'
import {
  ALREADY_LIKED,
  ALREADY_UNLIKED,
  GET_LIKES,
  LIKE_CLICKED,
  LIKE_UNCLICKED,
  RESET_LIKES,
  SET_LIKES
} from '../../constants/LikeConstants'
import {
  createAnalyticsEvent,
  createApiAction,
  inpirationAnalyticsEventProps
} from '../../utils/reduxActionUtils'
import {
  likeClicked,
  likeUnclicked
} from '../communityReducers/PostActionsReducer'

export const likeUserInspiration = createApiAction({
  endpoint: LIKE_INSPIRATION,
  redirectToAuth: true,
  mapPropsToBody: props => ({
    userInspirationObjectId: props.designId
  }),
  beforeRequest: ({ props }) =>
    contentIsLiked(props.designId, props.likeCount + 1),
  handler: ({ props, response, result }) => {
    if (result.code === 141)
      return alreadyLiked(props.designId, props.likeCount)
    if (result && response.status !== 200)
      return contentIsUnLiked(props.designId, props.likeCount)
  },
  analyticsEvent: createAnalyticsEvent('like', inpirationAnalyticsEventProps)
})

export const unlikeUserInspiration = createApiAction({
  endpoint: UNLIKE_INSPIRATION,
  mapPropsToBody: props => ({
    userInspirationObjectId: props.designId
  }),
  beforeRequest: ({ props }) =>
    contentIsUnLiked(props.designId, props.likeCount - 1),
  handler: ({ props, response, result }) => {
    if (result.code === 141)
      return alreadyUnLiked(props.designId, props.likeCount)
    if (result && response.status !== 200)
      return contentIsUnLiked(props.designId, props.likeCount)
  },
  analyticsEvent: createAnalyticsEvent('unlike', inpirationAnalyticsEventProps)
})

export const postActions = createApiAction({
  endpoint: LIKE_COMMENT,
  redirectToAuth: true,
  mapPropsToBody: props => ({
    userInspirationCommentId: props.commentId
  }),
  beforeRequest: ({ props, dispatch }) => {
    dispatch(contentIsLiked(props.commentId, props.likeCount + 1))
    dispatch(likeClicked(props.commentId))
  },
  handler: ({ props, response, result, dispatch }) => {
    if (result.code === 141) {
      dispatch(likeClicked(props.commentId))
      return alreadyLiked(props.commentId, props.likeCount)
    }
    if (result && response.status !== 200) {
      dispatch(likeUnclicked(props.commentId))
      return contentIsUnLiked(props.commentId, props.likeCount)
    }
  },
  analyticsEvent: createAnalyticsEvent('like', inpirationAnalyticsEventProps)
})

export const unpostActions = createApiAction({
  endpoint: UNLIKE_COMMENT,
  mapPropsToBody: props => ({
    userInspirationCommentId: props.commentId
  }),
  beforeRequest: ({ props, dispatch }) => {
    dispatch(contentIsUnLiked(props.commentId, props.likeCount - 1))
    dispatch(likeUnclicked(props.commentId))
  },
  handler: ({ props, response, result, dispatch }) => {
    if (result.code === 141) {
      dispatch(likeUnclicked(props.commentId))
      return alreadyUnLiked(props.commentId, props.likeCount)
    }
    if (result && response.status !== 200) {
      dispatch(likeUnclicked(props.commentId))
      return contentIsUnLiked(props.commentId, props.likeCount)
    }
  },
  analyticsEvent: createAnalyticsEvent('unlike', inpirationAnalyticsEventProps)
})

export const contentIsLiked = (id, numLikes) => {
  return {
    id: id,
    numLikes: numLikes,
    type: LIKE_CLICKED
  }
}

export const contentIsUnLiked = (id, numLikes) => {
  return {
    id: id,
    numLikes: numLikes,
    type: LIKE_UNCLICKED
  }
}

export const alreadyLiked = (id, numLikes) => {
  return {
    id: id,
    numLikes: numLikes,
    type: ALREADY_LIKED
  }
}

export const alreadyUnLiked = (id, numLikes) => {
  return {
    id: id,
    numLikes: numLikes,
    type: ALREADY_UNLIKED
  }
}
export const getLikes = likes => {
  return {
    data: likes,
    type: GET_LIKES
  }
}

export const setLikes = likes => {
  return {
    data: likes,
    type: SET_LIKES
  }
}

export const resetLikes = () => {
  return {
    type: RESET_LIKES
  }
}
