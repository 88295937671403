const itemScales = require('../redux/data/itemscaling.json')

export const createDragObject = (src, imgW, imgH, scale, item) => {
  return {
    src,
    width: imgW,
    height: imgH,
    scale,
    item
  }
}

export const adjustSizingForStage = (
  stageWidth,
  stageHeight,
  imgWidth,
  imgHeight,
  reduce = 0.7
) => {
  const srw = stageWidth * reduce
  const srh = stageHeight * reduce
  //console.log(srw,srh,imgWidth, imgHeight)
  if (imgWidth <= srw && imgHeight <= srh) return [imgWidth, imgHeight, 1.0]

  var per, nw, nh
  if (imgWidth > imgHeight) {
    per = Math.round((srw / imgWidth) * 1e2) / 1e2
  } else {
    per = Math.round((srw / imgHeight) * 1e2) / 1e2
  }

  //console.log(per)
  nw = imgWidth * per
  nh = imgHeight * per
  return [nw, nh, per]
}

export const adjustSizingForStageAdvance = (
  stageWidth,
  stageHeight,
  imgWidth,
  imgHeight,
  categoryId = null,
  subcategoryId = null,
  reduce = 0.7
) => {
  if (!categoryId && !subcategoryId)
    return adjustSizingForStage(stageWidth, stageHeight, imgWidth, imgHeight)
  const srw = stageWidth * reduce
  //console.log(srw,srh,imgWidth, imgHeight)
  //if (imgWidth <= srw && imgHeight <= srh) return [imgWidth, imgHeight, 1.0]

  //console.log(categoryId, subcategoryId)
  //const scaleTo = itemScales[categoryId][subcategoryId]
  //if(itemScales[categoryId] && itemScales[categoryId][subcategoryId]) console.log(itemScales[categoryId][subcategoryId])
  var per, nw, nh, scaleTo

  if (itemScales[categoryId]) {
    scaleTo = itemScales[categoryId][subcategoryId]
    if (scaleTo) {
      //console.log(scaleTo)
      if (imgWidth < imgHeight) {
        per = Math.round((scaleTo.w / imgWidth) * 1e2) / 1e2
        //console.log("Width: " + per)
      } else {
        per = Math.round((scaleTo.h / imgHeight) * 1e2) / 1e2
        //console.log("Height: " + per)
      }

      nw = imgWidth * per
      nh = imgHeight * per

      return [nw, nh, per]
    }
  }

  //Scale to fit stage

  if (imgWidth > imgHeight) {
    per = Math.round((srw / imgWidth) * 1e2) / 1e2
  } else {
    per = Math.round((srw / imgHeight) * 1e2) / 1e2
  }

  //console.log(per)
  nw = imgWidth * per
  nh = imgHeight * per
  return [nw, nh, per]
}

export const moveArrayPosition = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr // for testing
}

export const createNodeId = (title, posX, posY) => {
  title = title.split(' ').join('').toLowerCase().substring(0, 4)
  posX = posX.toFixed(0)
  posY = posY.toFixed(0)
  const id = title + '_' + posX + '_' + posY

  return id
}

export const randomNodeId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

export const radiansToDegrees = radians => {
  var pi = Math.PI
  return radians * (180 / pi)
}

export const getLongestWidthHeight = points => {
  const ts = Math.abs(points[2]) + Math.abs(points[0])
  const bs = Math.abs(points[6]) + Math.abs(points[4])

  const ls = Math.abs(points[5]) + Math.abs(points[1])
  const rs = Math.abs(points[7]) + Math.abs(points[3])

  const lw = ts > bs ? ts : bs
  const lh = ls > rs ? ls : rs

  //console.log(lw,lh)
  return [lw, lh]
}

export const getBound = points => {
  const ts = Math.abs(points[2] - points[0])
  const bs = Math.abs(points[6] - points[4])

  const ls = Math.abs(points[5] - points[1])
  const rs = Math.abs(points[7] - points[3])

  const lw = ts > bs ? ts : bs
  const lh = ls > rs ? ls : rs

  return [lw, lh]
}

export const getBoundingBox = pts => {
  const tx = Math.min(pts[0], pts[6])
  const ty = Math.min(pts[1], pts[3])
  const bx = Math.max(pts[2], pts[4])
  const by = Math.max(pts[5], pts[7])

  return [tx, ty, bx, by]
}

export const translateToZero = pts => {
  const ox = pts[0]
  const oy = pts[1]

  return [pts[0] - ox, pts[1] - oy, pts[2] - ox, pts[3] - oy]
}

export const getShiftX = (prev, curr) => {
  const diffX = Math.abs(prev[0] - curr[0])
  return diffX
}

export const getShiftY = (prev, curr) => {
  const diffY = prev[1] - curr[1]
  return diffY
}

export const scaleModifier = (pts, sx = 1, sy = 1) => {
  return pts.map((e, idx) => (idx % 2 ? e * sy : e * sx))
}

export const convertCanvasToImage = canvas => {
  var image = new Image()
  image.src = canvas.toDataURL()
  return image
}

export const trimCanvas = canvas => {
  const rowBlank = (imageData, width, y) => {
    for (var x = 0; x < width; ++x) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false
    }
    return true
  }

  const columnBlank = (imageData, width, x, top, bottom) => {
    for (var y = top; y < bottom; ++y) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false
    }
    return true
  }

  var ctx = canvas.getContext('2d')
  var width = canvas.width
  var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
  var top = 0,
    bottom = imageData.height,
    left = 0,
    right = imageData.width

  while (top < bottom && rowBlank(imageData, width, top)) ++top
  while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom
  while (left < right && columnBlank(imageData, width, left, top, bottom))
    ++left
  while (
    right - 1 > left &&
    columnBlank(imageData, width, right - 1, top, bottom)
  )
    --right

  var trimmed = ctx.getImageData(left, top, right - left, bottom - top)
  var copy = canvas.ownerDocument.createElement('canvas')
  var copyCtx = copy.getContext('2d')
  copy.width = trimmed.width
  copy.height = trimmed.height
  copyCtx.putImageData(trimmed, 0, 0)

  return copy
}

export const trimCanvas2 = c => {
  var ctx = c.getContext('2d'),
    copy = document.createElement('canvas').getContext('2d'),
    pixels = ctx.getImageData(0, 0, c.width, c.height),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x,
    y

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % c.width
      y = ~~(i / 4 / c.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  // Calculate the height and width of the content
  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

  copy.canvas.width = trimWidth
  copy.canvas.height = trimHeight
  copy.putImageData(trimmed, 0, 0)
  // Return trimmed canvas
  return copy.canvas
}

export const trimImage = image => {
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.save()
  ctx.canvas.width = image.width
  ctx.canvas.height = image.height
  ctx.drawImage(image, 0, 0, image.width, image.height)
  ctx.restore()
  var pixels = ctx.getImageData(0, 0, image.width, image.height)
  var l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x,
    y

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % image.width
      y = ~~(i / 4 / image.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  // Calculate the height and width of the content
  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

  var copy = document.createElement('canvas').getContext('2d')
  copy.canvas.width = trimWidth
  copy.canvas.height = trimHeight

  copy.save()
  copy.putImageData(trimmed, 0, 0)
  copy.restore()
  //console.log(trimmed)
  //const newimg = new Image()
  //newimg.src = copy.canvas.toDataURL()
  // Return trimmed canvas
  return copy
}

export const trimRect = image => {
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.save()
  ctx.canvas.width = image.width
  ctx.canvas.height = image.height
  ctx.drawImage(image, 0, 0, image.width, image.height)
  ctx.restore()
  var pixels = ctx.getImageData(0, 0, image.width, image.height)
  var l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x,
    y

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % image.width
      y = ~~(i / 4 / image.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  const trimHeight = bound.bottom - bound.top
  const trimWidth = bound.right - bound.left

  return [bound.left, bound.top, trimWidth, trimHeight]
  /*
  return {
    x: bound.left,
    y: bound.top,
    width: trimWidth,
    height: trimHeight
  }*/
}

export const trim = image => {
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.save()
  ctx.canvas.width = image.width
  ctx.canvas.height = image.height
  ctx.drawImage(image, 0, 0, image.width, image.height)
  ctx.restore()
  var pixels = ctx.getImageData(0, 0, image.width, image.height)
  var l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x,
    y

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % image.width
      y = ~~(i / 4 / image.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  const trimHeight = bound.bottom - bound.top
  const trimWidth = bound.right - bound.left
  const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

  const copy = document.createElement('canvas').getContext('2d')
  copy.canvas.width = trimWidth
  copy.canvas.height = trimHeight
  copy.putImageData(trimmed, 0, 0)
  const newimg = copy.canvas.toDataURL('image/png')

  return newimg

  //return [bound.left, bound.top, trimWidth, trimHeight]
  /*

  var ctx = c.getContext('2d'),
    copy = document.createElement('canvas').getContext('2d'),
    pixels = ctx.getImageData(0, 0, c.width, c.height),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x,
    y

  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % c.width
      y = ~~(i / 4 / c.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

  copy.canvas.width = trimWidth
  copy.canvas.height = trimHeight
  copy.putImageData(trimmed, 0, 0)

  // open new window with trimmed image:
  return copy.canvas
  */
}
export const removeShadowsFromCanvas = (ctx, w, h) => {
  //const ctx = document.createElement('canvas').getContext('2d')
  //ctx.canvas.width = image.width
  //ctx.canvas.height = image.height
  //ctx.drawImage(image, 0, 0, image.width, image.height)

  //Conver solid to white
  /*
  ctx.globalCompositeOperation = "source-in";
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, image.width, image.height);
  */

  var pixels = ctx.getImageData(0, 0, w, h)

  for (var i = 0; i < pixels.data.length; i += 4) {
    if (pixels.data[i + 3] < 255) {
      //pixels.data[i] = 255
      //pixels.data[i + 1] = 255
      pixels.data[i + 3] = 0
    }
  }
  /*
  var copy = document.createElement('canvas').getContext('2d')
  copy.canvas.width = image.width
  copy.canvas.height = image.height
  */
  ctx.putImageData(pixels, 0, 0)

  //return copy.canvas
}

export const removeShadowsFromImage = image => {
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.canvas.width = image.width
  ctx.canvas.height = image.height
  ctx.drawImage(image, 0, 0, image.width, image.height)

  //Conver solid to white
  /*
  ctx.globalCompositeOperation = "source-in";
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, image.width, image.height);
  */

  var pixels = ctx.getImageData(0, 0, image.width, image.height)

  for (var i = 0; i < pixels.data.length; i += 4) {
    if (pixels.data[i + 3] < 255) {
      //pixels.data[i] = 255
      //pixels.data[i + 1] = 255
      pixels.data[i + 3] = 0
    }
  }

  var copy = document.createElement('canvas').getContext('2d')
  copy.canvas.width = image.width
  copy.canvas.height = image.height
  copy.putImageData(pixels, 0, 0)

  return copy.canvas
}
