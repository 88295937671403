import React, { useEffect, useRef } from 'react'
import { OverlayContent, OverlayWrapper } from './ModalOverlay.styles'

export const ModalOverlay = ({
  children,
  centered,
  fullscreen,
  wrapperRef = {},
  disableBodyScroll,
  closeOnClickOutside = true,
  onClose,
  bottomSheet
}) => {
  const contentRef = useRef(null)

  useEffect(() => {
    if (disableBodyScroll) {
      document.body.classList.add('no-scroll')

      return () => {
        document.body.classList.remove('no-scroll')
      }
    }
  }, [disableBodyScroll]) // only cleanup on dismount

  const handleBackdropClick = e => {
    if (!closeOnClickOutside) return

    if (
      e?.nativeEvent?.path &&
      e?.nativeEvent?.path[0] === contentRef.current &&
      onClose
    )
      onClose()
  }

  return (
    <OverlayWrapper fullscreen={fullscreen}>
      <OverlayContent
        bottomSheet={bottomSheet}
        centered={centered}
        ref={ref => {
          wrapperRef.current = ref
          contentRef.current = ref
        }}
        onClick={handleBackdropClick}
        fullscreen={fullscreen}
      >
        {children}
      </OverlayContent>
    </OverlayWrapper>
  )
}
