import styled, { keyframes } from 'styled-components'

export const LoadingWrapper = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  margin: 0 auto;
`

const loadingIndicator = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`

export const LoadingInnerElement = styled.div`
  position: absolute;
  border: 4px solid #ff5e6d;
  opacity: 1;
  border-radius: 50%;
  animation: ${loadingIndicator} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  &:nth-child(2) {
    animation-delay: -0.5s;
  }
`

const bounceOut = keyframes`
0% {
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
  }
`

export const ChairElement = styled(LoadingWrapper)`
  animation: ${bounceOut} 0.5s;
  animation-timing-function: cubic-bezier(0.5, 0.05, 0.5, 0.5);
  animation-iteration-count: infinite;
  margin: 15% auto;

  @media (max-width: 600px) {
    margin: 50% auto;
  }
`
