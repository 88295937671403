import { createSlice } from '@reduxjs/toolkit'
import { changeS3Url } from '../../utils/fetchUtils'

/* -------------------------------------------------------------------
 * SCRUB
 * ----------------------------------------------------------------- */

export const scrubItem = data => {
  //const lck = data.numCoins && data.numCoins > 0 ? true : false
  if (!data.processedImageFileNames) return
  const url =
    'https://' +
    data.baseUrl +
    '/' +
    data.processedImageFileLoc +
    data.processedImageFileNames[0]
  //const url = 'https://decormatters-items.s3.amazonaws.com/' + data.processedImageFileLoc + data.processedImageFileNames[0]
  //console.log(data)
  var price = 0
  if (data.originalPrice) price = data.originalPrice
  if (data.salePrice) price = data.salePrice

  return {
    ...data,
    imgurl: url,
    title: data.title,
    id: data.objectId,
    type: 'i',
    numCoins: data.numCoins,
    price,
    categoryId: data.categoryId,
    subcategoryId: data.subCategoryId,
    storeId: data.storeId,
    color: data.colorDesign
  }
}

export const scrubMydecor = data => {
  return {
    imgurl: data?.imageFile?.url || '',
    title: 'MyDecor',
    id: data?.objectId,
    userId: data?.user?.objectId,
    type: 'u',
    price: 0,
    categoryId: null,
    subcategoryId: null
  }
}

export const scrubProduct = data => {
  return {
    imgurl: changeS3Url(data.processedImageUrls[0]),
    title: data.title,
    id: data.objectId,
    type: 'p',
    numCoins: data.numCoins || 0,
    price: data.originalPrice,
    categoryId: data.categoryId,
    subcategoryId: data.subCategoryId
  }
}

export const scrubMarket = data => {
  //const lck = data.numCoins && data.numCoins > 0 ? true : false

  var url = ''

  if (data.imageUrl) url = 'https://' + data.imageUrl
  if (data.imageFile) url = data.imageFile.url

  url = changeS3Url(url)

  return {
    imgurl: url,
    title: data.title,
    id: data.objectId,
    type: 'm',
    numCoins: data.numCoins,
    price: data.originalPrice,
    categoryId: data.categoryId,
    subcategoryId: data.subCategoryId
  }
}

const scrubMarketPurchase = data => {
  //const lck = data.marketProduct.numCoins && data.marketProduct.numCoins > 0 ? true : false

  var url = ''

  if (data.marketProduct.imageUrl) url = 'https://' + data.imageUrl
  if (data.marketProduct.imageFile) url = data.imageFile.url

  url = changeS3Url(url)

  return {
    imgurl: url,
    title: data.marketProduct.title,
    id: data.marketProduct.objectId,
    type: 'm',
    numCoins: data.marketProduct.numCoins,
    price: data.marketProduct.originalPrice,
    categoryId: data.categoryId,
    subcategoryId: data.subCategoryId
  }
}

/* -------------------------------------------------------------------
 * SCRUB ASSETS
 * ----------------------------------------------------------------- */

export const scrubItemAssets = data => {
  if (data.processedImageFileNames && data.processedImageFileNames.length > 0) {
    const assets = []

    data.processedImageFileNames.forEach(e => {
      const base = 'https://' + data.baseUrl + '/' + data.processedImageFileLoc
      const url =
        'https://' + data.baseUrl + '/' + data.processedImageFileLoc + e
      //const url = 'https://decormatters-items.s3.amazonaws.com/' + data.processedImageFileLoc + e
      //const url = "https://" + data.baseUrl + "/" + data.processedImageFileLoc + data.processedImageFileNames
      const obj = {
        base,
        original: url,
        file: e
      }
      assets.push(obj)
    })

    return assets
  }

  const base = 'https://' + data.baseUrl + '/' + data.thumbImageFileLoc
  const url =
    'https://' +
    data.baseUrl +
    '/' +
    data.thumbImageFileLoc +
    data.thumbImageFileName

  const obj = {
    base,
    original: url,
    file: data.thumbImageFileName
  }

  return [obj]
}

const scrubProductAssets = data => {
  if (!data.processedImageUrls) return []
  if (data.processedImageUrls.length === 0) return []

  const assets = []
  data.processedImageUrls.forEach(e => {
    const url = changeS3Url(e)
    assets.push(url)
  })

  return assets
}

const assets = createSlice({
  name: 'assets',
  initialState: {
    assets: [],
    item: {},
    show: false
  },
  reducers: {
    selectItem: (state, action) => {
      if (!action.payload) return

      const d = action.payload
      var item = {}
      var assets = []
      var url = ''

      if (d.className === 'Item' || !d.className) {
        item = scrubItem(d)
        assets = scrubItemAssets(d)
      }
      if (d.className === 'UserProduct') {
        item = scrubMydecor(d)
        const obj = {
          base: null,
          original: changeS3Url(d.cfImageUrl),
          file: null
        }
        assets = [obj]
      }
      if (d.className === 'Product') {
        item = scrubProduct(d)
        assets = scrubProductAssets(d)
      }
      if (d.className === 'MarketProduct') {
        item = scrubMarket(d)

        if (d.imageUrl) url = 'https://' + d.imageUrl
        if (d.imageFile) url = d.imageFile.url

        url = changeS3Url(url)
        assets = [url]
      }
      if (d.className === 'MarketplacePurchase') {
        item = scrubMarketPurchase(d)

        if (d.marketProduct.imageUrl) url = 'https://' + d.imageUrl
        if (d.marketProduct.imageFile) url = d.imageFile.url

        url = changeS3Url(url)

        assets = [url]
      }

      state.item = item
      state.assets = assets
      if (assets.length > 0) state.show = true
    },
    hide: (state, action) => {
      state.show = false
    }
  },
  extraReducers: {}
})

export const { selectItem, hide } = assets.actions

export default assets.reducer
