import styled from 'styled-components'

export const QuickSearchContentWrapper = styled.div``

export const SearchSubtitle = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  display: block;
`

export const SearchTitleLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
`

export const RemoveRecentsButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  width: 16px;
  height: 16px;
  margin: 0 0 0 11px;
`

export const RecentSearchTag = styled.button`
  background: #f5f5f5;
  border-radius: 50px;
  height: 34px;
  letter-spacing: 0.01em;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  border: none;
  padding: 8px 18px;
  margin-right: 12px;
  margin-bottom: 6px;
`

export const SearchCardsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;

  @media (min-width: 600px) {
    justify-content: ${props =>
      props.inline ? 'flex-start' : 'space-between'};
  }
`

export const PopularCard = styled.button`
  width: auto;
  min-width: 110px;
  height: 56px;
  border-radius: 5px;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  border: none;
  margin-bottom: 10px;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  ${props =>
    props.placeholder &&
    `
    content: '';
    background: #FAFAFA;
  `}

  & span {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 600px) {
    width: ${props => (props.wider ? '216px' : '160px')};
    flex-shrink: 0;
    flex-grow: 0;
  }
`

export const BlogCard = styled.div`
  width: 110px;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;

  @media (min-width: 600px) {
    width: auto;
    margin-right: ${props => (props.marginRight ? '16px' : '0')};
  }
`

export const BlogPostCoverImage = styled.img`
  width: 110px;
  height: 70px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 4px;
  object-fit: cover;

  ${props =>
    props.placeholder &&
    `
    content: '';
    background: #FAFAFA;
  `}

  @media (min-width: 600px) {
    width: 100%;
    width: 216px;
    height: 120px;
    flex: 1;
  }
`

export const BlogPostTitle = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  color: #333333;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${props =>
    props.placeholder &&
    `
    content: '';
    background: #FAFAFA;
    width: 110px;
    height: 33px;
    border-radius: 5px;
  `}

  @media (min-width: 600px) {
    font-size: 13px;
    line-height: 150%;
    color: #333333;
    max-width: 216px;
  }
`

export const SearchResultsList = styled.ul`
  list-style: none;
  padding-left: 0;
`

export const SearchResultLine = styled.li`
  margin: 0;
  padding: 12px 0;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  border-bottom: 1px solid #fafafa;
  cursor: pointer;

  & span {
    margin-bottom: -4px;
  }
`

export const PostIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #f5f5f5;
  margin-right: 10px;
  content: '';
  flex-shrink: 0;
`

export const UserIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f5f5f5;
  margin-right: 10px;
  content: '';
  flex-shrink: 0;
`
