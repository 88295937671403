import classNames from 'classnames'
import { useRouter } from 'next/router'
import React from 'react'
import backBtnNavImg from '../../assets/Back.svg'
import backBtnImg from '../../assets/BackNavBtn.svg'
import * as styles from './backbutton.module.css'

/* Show backbutton mobile on these pages */
/* Show web will display button on desktop */
const showPages = [
  { uri: '/design/', showWeb: 1 },
  { uri: '/settings', showWeb: 1 },
  { uri: 'dm/', showWeb: 1 },
  { uri: 'dm', showWeb: 1 },
  { uri: 'blog/', showWeb: 1 },
  { uri: '/create/userEvent', showWeb: 1 },
  { uri: '/challenges/', showWeb: 1 },
  { uri: '/event', showWeb: 1 },
  { uri: 'leaders', showWeb: 1 }
]

const isActive = location => {
  const pathName = location.pathname

  const result = showPages.filter(word => pathName.indexOf(word.uri) > -1)

  if (result.length > 0 && result[0].showWeb !== 1) {
    return false
  }

  if (result.length > 0) return true
  return false
}

const BackButton = ({ link, autoActive = true, autoPosition = true }) => {
  const router = useRouter()

  //only show on design page for now.
  const goBack = () => {
    if (link) {
      router.push(link)
    } else {
      router.back()
    }
  }

  const isVisible = isActive(router) || link || !autoActive

  return (
    <div
      className={classNames(styles.backBtnContainer, {
        [styles.active]: isVisible,
        [styles.hide]: !isVisible
      })}
    >
      <img
        src={backBtnImg}
        alt=""
        onClick={goBack}
        className={classNames(styles.backBtn, {
          [styles.autoPosition]: autoPosition,
          [styles.active]: isVisible,
          [styles.hide]: !isVisible
        })}
      />
      <img
        src={backBtnNavImg}
        alt=""
        onClick={goBack}
        className={classNames(styles.backBtnNav, {
          [styles.autoPosition]: autoPosition,
          [styles.active]: isVisible,
          [styles.hide]: !isVisible
        })}
      />
    </div>
  )
}

export default BackButton
