import styled from 'styled-components'

export const DevToolsWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  left: 50px;
  z-index: 9999;
`

export const DevToolsButton = styled.button`
  margin-right: 5px;
`
