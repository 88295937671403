import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { saveAutoSave } from '../data/designStore'
import { moveArrayPosition, randomNodeId } from '../../utils/nodeUtils'
import {
  ARRANGE_FRONT,
  EDIT_MODE_MOVE,
  FLIP_X,
  ROTATE_CLOCK_45,
  ROTATE_COUNTER_45,
  ROTATE_RESET
} from '../constants'
import { APP_CACHE } from '../../constants/ApiEndpoints'

export const convertItemsToTargets = items => {
  const targets = []

  items?.forEach(i => {
    const target = {
      id: randomNodeId(),
      x: i.m[0],
      y: i.m[1],
      scaleX: i.m[2],
      scaleY: i.m[3],
      rotation: i.m[4],
      src: i.src,
      brightness: i.f && i.f[0] ? i.f[0] : 1,
      transform: i.p ? i.p : [0, 0, 0, 0, 0, 0, 0, 0],
      crop: i.c || null,
      t: i.t || { x: 0, y: 0 },
      item: {
        ...i.item,
        id: i.item.objectId || i.item.id
      },
      starter: i.starter || false
    }
    targets.push(target)
  })

  return targets
}

const checkMarkers = ({ markers, targets }) => {
  if (!markers) return markers
  if (!targets) return markers
  if (markers.length === 0) return markers
  var m = [...markers]
  m.forEach(n => (n.complete = false))
  for (var i = 0; i < targets.length; i++) {
    //console.log(targets[i].starter)
    if (targets[i]?.starter) continue
    for (var j = 0; j < m.length; j++) {
      //console.log("1: ", targets[i].item.categoryId, targets[i].item.subcategoryId )
      //console.log("2: ", m[j].categoryId , m[j].subcategoryId )
      //subCategoryId -
      if (m[j].id === targets[i].bubble?.id && m[j].complete === false) {
        if (targets[i].item.subcategoryId === m[j].subcategoryId) {
          m[j].complete = true
          break
        }

        if (targets[i].item.subCategoryId === m[j].subcategoryId) {
          m[j].complete = true
          break
        }

        //Theres no subcat
        if (m[j].categoryId === m[j].subcategoryId) {
          m[j].complete = true
          break
        }
      }
    }
  }
  return m
}

const checkRequirements = ({ requirements, targets }) => {
  if (!requirements) return requirements
  if (!targets) return requirements
  if (requirements.length === 0) return requirements
  var r = [...requirements]
  r.forEach(n => {
    n.count = 0
    n.error = false
  })

  for (var i = 0; i < targets.length; i++) {
    for (var j = 0; j < r.length; j++) {
      var validKind = false

      switch (r[j].kind) {
        case 'category':
          validKind = true
          break
        case 'color':
          if (!targets[i].item.color) break
          if (r[j].attr.color === targets[i].item.color) validKind = true
          break
        case 'brand':
          if (!targets[i].item.storeId) break
          if (r[j].attr.storeId === targets[i].item.storeId) validKind = true
          break
        default:
          break
      }

      //Second check if CAT/SUB is satisfied
      if (validKind) {
        if (r[j].item.categoryId === targets[i].item.categoryId) {
          var added = false
          if (targets[i].item.subcategoryId === r[j].item.subCategoryId) {
            added = true
            r[j].count++
          }

          if (targets[i].item.subCategoryId === r[j].item.subCategoryId) {
            if (added === false) r[j].count++
          }

          //Theres no subcat
          if (!r[j].item.subCategoryId) {
            if (added === false) r[j].count++
          }
        } else {
          r[j].count++
        }
      }
    }
  }

  return r
}

const canvas = createSlice({
  name: 'canvas',
  initialState: {
    scene: null,
    controlWidth: 750,
    controlHeight: 750,
    controlZoom: 1.0,
    sceneWidth: 999,
    sceneHeight: 999,
    sceneZoom: 1.0,
    sceneZoomWidth: 750,
    sceneZoomHeight: 750,
    selected: null,
    targets: [],
    markers: [],
    requirements: [],
    itemReference: {},
    mode: EDIT_MODE_MOVE,
    undos: [],
    redos: [],
    designId: null,
    design: null,
    textures: {},
    designData: null,
    savedId: null,
    selectedBubble: null
  },
  reducers: {
    initialize: (state, action) => {
      if (!action.payload) return

      const d = action.payload
      const it = {}
      console.log(d.targets)
      d.targets.forEach(t => {
        //if (t.item.premium === true) {
        //console.log(t.item)
        const id = t.item.id
        if (!it[id]) it[id] = { ids: [t.id], item: t.item }
        else it[id] = { ids: [...it[id].ids, t.id], item: t.item }
        //}
      })

      state.itemReference = it
      state.undos = []
      state.redos = []
      state.requirements = []
      state.scene = d.template
      state.targets = d.targets

      const sw = d.width || 750
      const sh = d.height || 750
      state.sceneWidth = sw
      state.sceneHeight = sh
      //console.log("HEY ", d.width, d.height)
      //console.log(sw, sh)
      //console.log(state.sceneWidth, state.sceneHeight)
      const modzm = state.controlZoom / (sw / state.controlWidth)

      state.sceneZoom = modzm
      state.sceneZoomWidth = sw * modzm
      state.sceneZoomHeight = sh * modzm

      //state.sceneZoomWidth = state.sceneZoom * (sw/(sw/state.controlWidth))
      //state.sceneZoomHeight = state.sceneZoom * (sh/(sh/state.controlHeight))

      //state.designId = d.designId && d.designId !== 'new' ? d.designId : d.id && d.id !== 'new' ? d.id : null
      state.savedId = d.savedId
      state.undos.push(JSON.stringify(state.targets))

      state.designData = d.designData

      saveAutoSave(state.scene, state.targets)
    },
    initializeMarkers: (state, action) => {
      if (!action.payload) return

      state.markers = action.payload

      state.markers = checkMarkers({
        markers: state.markers,
        targets: state.targets
      })
    },
    initializeRequirements: (state, action) => {
      if (!action.payload) return

      const req = [...action.payload]

      for (var i = 0; i < req.length; i++) {
        const obj = { ...req[i] }
        obj.count = 0
        obj.error = false
        req[i] = obj
      }
      state.requirements = checkRequirements({
        requirements: req,
        targets: state.targets
      })

      //state.requirements = checkMarkers({markers:state.markers, targets: state.targets})
    },
    updateTargetVisibility: (state, action) => {
      const id = action.payload.id
      const visible = action.payload.visible

      state.targets = state.targets.map(item => {
        if (item.id !== id) {
          return item
        }

        return {
          ...item,
          visible
        }
      })
    },
    setSelectedBubble: (state, action) => {
      state.selectedBubble = action.payload
    },
    updateTarget: (state, action) => {
      const id = action.payload.id
      const attrs = action.payload.attrs
      const bypass = action.payload.bypassAutoSave
      var fi = -1

      state.targets = state.targets.map((t, i) => {
        if (t.id !== id) {
          return t
        }
        if (attrs.x && attrs.y) {
          const dim = action.payload.dimensions
          const top = attrs.y + dim.height / 2
          const bottom = attrs.y - dim.height / 2
          const left = attrs.x + dim.width / 2
          const right = attrs.x - dim.width / 2

          if (
            top < 0 ||
            left < 0 ||
            right > state.sceneWidth ||
            bottom > state.sceneHeight
          ) {
            attrs.x = state.sceneWidth / 2
            attrs.y = state.sceneHeight / 2

            state.selected = null
            fi = i
          }
        }
        return {
          ...t,
          ...attrs
        }
      })

      if (fi !== -1) {
        const lyr = state.targets.length - 1
        state.targets = moveArrayPosition(state.targets, fi, lyr)
      }

      if (attrs.editing === true) return
      if (bypass) return

      state.redos = []
      state.undos.push(JSON.stringify(state.targets))
      saveAutoSave(state.scene, state.targets)

      //Check Challenge Markers
      //state.markers = checkMarkers({markers:state.markers, targets: state.targets})
    },
    changeTargetImage: (state, action) => {
      if (!action.payload) return
      const d = action.payload
      const select = state.selected

      if (!select) return

      const fi = state.targets.findIndex(e => e.id === select)
      if (fi !== -1) {
        //exit if incoming image is same
        if (state.targets[fi].src === d.src) return
      }

      const tobj = {
        src: d.src,
        item: d.item
      }

      state.targets = state.targets.map((t, i) => {
        if (t.id !== select) {
          return t
        }
        return {
          ...t,
          ...tobj
        }
      })

      state.selected = null
      state.redos = []
      state.undos.push(JSON.stringify(state.targets))
      saveAutoSave(state.scene, state.targets)
    },
    addTarget: (state, action) => {
      if (!action.payload) return

      const d = action.payload
      const rid = randomNodeId()

      const scale = d.isTutorial ? 1 : Math.max(d.scaleX, d.scaleY)

      const tobj = {
        id: rid,
        x: d.x,
        y: d.y,
        rotation: 0,
        src: d.src,
        scaleX: scale || d.scale,
        scaleY: scale || d.scale,
        width: d.width,
        height: d.height,
        brightness: 1,
        transform: [0, 0, 0, 0, 0, 0, 0, 0],
        item: d.item,
        bubble: d.bubble
      }

      //if (d.item.premium === true) {
      const it = state.itemReference
      const itemId = d.item.id
      if (!it[itemId]) it[itemId] = { ids: [tobj.id], item: d.item }
      else it[itemId] = { ids: [...it[itemId].ids, tobj.id], item: d.item }
      //}

      state.targets.push(tobj)

      state.redos = []
      state.undos.push(JSON.stringify(state.targets))
      saveAutoSave(state.scene, state.targets)

      state.markers = checkMarkers({
        markers: state.markers,
        targets: state.targets
      })
      state.requirements = checkRequirements({
        requirements: state.requirements,
        targets: state.targets
      })
    },
    deleteTarget: (state, action) => {
      if (!state.selected) return

      const select = state.selected
      const fi = state.targets.findIndex(e => e.id === select)
      if (fi !== -1) {
        state.selected = null

        state.targets.splice(fi, 1)

        for (const [key] of Object.entries(state.itemReference)) {
          const index = state.itemReference[key].ids.indexOf(select)
          if (index > -1) {
            state.itemReference[key].ids.splice(index, 1)
          }
        }

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)
      }

      state.markers = checkMarkers({
        markers: state.markers,
        targets: state.targets
      })
      state.requirements = checkRequirements({
        requirements: state.requirements,
        targets: state.targets
      })
    },
    deleteTargetById: (state, action) => {
      if (!action.payload) return

      const id = action.payload
      const fi = state.targets.findIndex(e => e.id === id)

      if (fi !== -1) {
        state.selected = null
        state.targets.splice(fi, 1)

        for (const [key] of Object.entries(state.itemReference)) {
          const index = state.itemReference[key].ids.indexOf(id)
          if (index > -1) {
            state.itemReference[key].ids.splice(index, 1)
          }
        }

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)
      }

      state.markers = checkMarkers({
        markers: state.markers,
        targets: state.targets
      })
      state.requirements = checkRequirements({
        requirements: state.requirements,
        targets: state.targets
      })
    },
    deleteTargetByObjectId: (state, action) => {
      if (!action.payload) return

      const objectId = action.payload

      const tar = [...state.targets]
      var i = tar.length
      var have = false
      while (i--) {
        if (tar[i].item.id === objectId) {
          have = true
          state.selected = null
          for (const [key] of Object.entries(state.itemReference)) {
            const index = state.itemReference[key].ids.indexOf(tar.id)
            if (index > -1) {
              state.itemReference[key].ids.splice(index, 1)
            }
          }

          tar.splice(i, 1)
        }
      }

      state.targets = [...tar]

      saveAutoSave(state.scene, state.targets)

      if (have) {
        state.redos = []
        state.undos = []
      }

      state.markers = checkMarkers({
        markers: state.markers,
        targets: state.targets
      })
      state.requirements = checkRequirements({
        requirements: state.requirements,
        targets: state.targets
      })
    },
    arrangeTarget: (state, action) => {
      if (!state.selected) return

      const select = state.selected
      const dir = action.payload
      const fi = state.targets.findIndex(e => e.id === select)

      if (fi !== -1) {
        const lyr =
          dir === ARRANGE_FRONT
            ? Math.min(fi + 1, state.targets.length - 1)
            : Math.max(fi - 1, 0)
        state.targets = moveArrayPosition(state.targets, fi, lyr)

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)
      }
    },
    arrangeEndTarget: (state, action) => {
      if (!state.selected) return

      const select = state.selected
      const dir = action.payload
      const fi = state.targets.findIndex(e => e.id === select)

      if (fi !== -1) {
        const lyr = dir === ARRANGE_FRONT ? state.targets.length - 1 : 0
        state.targets = moveArrayPosition(state.targets, fi, lyr)

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)
      }
    },
    selectTarget: (state, action) => {
      state.selected = action.payload
    },
    duplicateTarget: (state, action) => {
      if (!state.selected) return

      const select = state.selected
      const fi = state.targets.findIndex(e => e.id === select)

      if (fi !== -1) {
        const toDup = state.targets[fi]
        const id = randomNodeId()
        const nx = toDup.x + 10
        const ny = toDup.y + 10
        const x = nx < state.sceneWidth ? nx : toDup.x - 10
        const y = ny < state.sceneHeight ? ny : toDup.y - 10

        const target = {
          ...toDup,
          id,
          x,
          y
        }

        //if (toDup.item.premium === true) {
        const it = state.itemReference
        const itemId = toDup.item.id
        if (!it[itemId]) it[itemId] = { ids: [id], item: toDup.item }
        else it[itemId] = { ids: [...it[itemId].ids, id], item: toDup.item }
        //}

        state.targets.push(target)

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)

        state.markers = checkMarkers({
          markers: state.markers,
          targets: state.targets
        })
        state.requirements = checkRequirements({
          requirements: state.requirements,
          targets: state.targets
        })
      }
    },
    flipTarget: (state, action) => {
      if (!state.selected) return

      const select = state.selected
      const dir = action.payload

      const fi = state.targets.findIndex(e => e.id === select)

      if (fi !== -1) {
        if (dir === FLIP_X) {
          state.targets[fi].scaleX = -state.targets[fi].scaleX
        } else {
          state.targets[fi].scaleY = -state.targets[fi].scaleY
        }

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)
      }
    },
    rotateTarget: (state, action) => {
      if (!state.selected) return

      const select = state.selected
      const dir = action.payload

      const fi = state.targets.findIndex(e => e.id === select)
      const rad45 = (45 * Math.PI) / 180

      if (fi !== -1) {
        if (dir === ROTATE_CLOCK_45) {
          state.targets[fi].rotation = state.targets[fi].rotation + rad45
        } else if (dir === ROTATE_COUNTER_45) {
          state.targets[fi].rotation = state.targets[fi].rotation - rad45
        } else if (dir === ROTATE_RESET) {
          state.targets[fi].rotation = 0
        }

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)
      }
    },
    revertTarget: (state, action) => {
      if (!state.selected) return

      const select = state.selected
      const fi = state.targets.findIndex(e => e.id === select)

      if (fi !== -1) {
        //console.log(JSON.stringify(state.targets[fi]))
        state.targets[fi].rotation = 0
        //state.targets[fi].crop = null
        state.targets[fi].brightness = 1
        state.targets[fi].transform = [0, 0, 0, 0, 0, 0, 0, 0]
        /*
        if(dir === ROTATE_CLOCK_45) {
          state.targets[fi].rotation = state.targets[fi].rotation + rad45
        } else if (dir === ROTATE_COUNTER_45) {
          state.targets[fi].rotation = state.targets[fi].rotation - rad45
        } else if (dir === ROTATE_RESET) {
          state.targets[fi].rotation = 0
        }

        state.redos = []
        state.undos.push(JSON.stringify(state.targets))
        saveAutoSave(state.scene, state.targets)
        */
      }
    },
    clearAllTargets: (state, action) => {
      state.selected = null
      state.targets = []
    },
    clearAll: (state, action) => {
      state.scene = null
      state.selected = null
      state.targets = []
      state.markers = []
    },
    changeMode: (state, action) => {
      state.mode = action.payload
    },
    undo: (state, action) => {
      if (state.undos.length <= 1) return
      state.selected = null

      //const prev = state.targets
      const previous = JSON.parse(state.undos[state.undos.length - 2])
      state.targets = previous

      const it = {}

      previous.forEach(t => {
        //if (t.item.premium === true) {
        const id = t.item.id
        if (!it[id]) it[id] = { ids: [t.id], item: t.item }
        else it[id] = { ids: [...it[id].ids, t.id], item: t.item }
        //}
      })
      state.itemReference = it

      state.redos.push(state.undos.pop())
      saveAutoSave(state.scene, state.targets)

      state.markers = checkMarkers({
        markers: state.markers,
        targets: state.targets
      })
      state.requirements = checkRequirements({
        requirements: state.requirements,
        targets: state.targets
      })
    },
    redo: (state, action) => {
      if (state.redos.length <= 0) return
      state.selected = null
      const u = state.redos.pop()
      state.undos.push(u)

      const next = JSON.parse(u)
      state.targets = next

      const it = {}

      next.forEach(t => {
        //if (t.item.premium === true) {
        const id = t.item.id
        if (!it[id]) it[id] = { ids: [t.id], item: t.item }
        else it[id] = { ids: [...it[id].ids, t.id], item: t.item }
        //}
      })

      state.itemReference = it

      saveAutoSave(state.scene, state.targets)

      state.markers = checkMarkers({
        markers: state.markers,
        targets: state.targets
      })
      state.requirements = checkRequirements({
        requirements: state.requirements,
        targets: state.targets
      })
    },
    capture: (state, action) => {
      state.design = action.payload
    },
    zoom: (state, action) => {
      const zm = action.payload

      const modzm = zm / (state.sceneWidth / state.controlWidth)

      state.controlZoom = zm
      state.sceneZoom = modzm
      state.sceneZoomWidth = state.sceneWidth * modzm
      state.sceneZoomHeight = state.sceneHeight * modzm
      //console.log(zm * (state.sceneWidth/(state.sceneWidth/state.controlWidth)))
      //state.sceneZoomWidth = zm * (state.sceneWidth/(state.sceneWidth/state.controlWidth))
      //state.sceneZoomHeight = zm * (state.sceneHeight/(state.sceneHeight/state.controlHeight))
    },
    validateRequirements: (state, action) => {
      const req = [...state.requirements]

      req.forEach(r => {
        if (r.count >= r.qty) {
          r.error = false
        } else {
          r.error = true
        }
      })

      state.requirements = req
    },
    setRequirementStatus: (state, { payload }) => {
      const req = [...state.requirements]

      if (req[payload.index])
        req[payload.index].isCompleted = payload?.isCompleted

      state.requirements = req
    },
    updateTextures: (state, action) => {
      const text = action.payload

      state.textures[text.id] = {
        ...text
      }
    },
    updateScene: (state, { payload }) => {
      state.scene = payload
      state.designData.cfImageUrl = payload
      state.designData.className = 'UserTemplate'
    }
  }
})

export const {
  initialize,
  initializeMarkers,
  initializeRequirements,
  updateTarget,
  changeTargetImage,
  addTarget,
  deleteTarget,
  deleteTargetById,
  deleteTargetByObjectId,
  arrangeTarget,
  arrangeEndTarget,
  selectTarget,
  duplicateTarget,
  flipTarget,
  rotateTarget,
  clearAll,
  changeMode,
  undo,
  redo,
  capture,
  zoom,
  validateRequirements,
  setRequirementStatus,
  updateTextures,
  updateTargetVisibility,
  updateScene,
  setSelectedBubble
} = canvas.actions
export default canvas.reducer
