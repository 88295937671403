import React, { useRef } from 'react'
import {
  AnimationWrapper,
  BottomSection,
  EmailSection,
  GroupedLink,
  LinkIcon,
  LinksGroup,
  LogOutButton,
  MembershipBanner,
  ProfileFloatingMenuWrapper
} from './ProfileFloatingMenu.styles'
import { useDispatch, useSelector } from 'react-redux'
import coinIcon from '../../assets/coin.svg'
import { getMembershipType } from '../../utils/getMembershipType'
import addPostIcon from '../../assets/navbar/addDesign.svg'
import cogIcon from '../../assets/navbar/cog.svg'
import blogIcon from '../../assets/navbar/blog.svg'
import profileIcon from '../../assets/navbar/profile.svg'
import helpIcon from '../../assets/navbar/help.svg'
import decorateIcon from '../../assets/navbar/decorate.svg'
import designIcon from '../../assets/navbar/design.svg'
import joinIcon from '../../assets/navbar/join.svg'
import myEventDesktopIcon from '../../assets/navbar/myEventDesktop.svg'
import playIcon from '../../assets/navbar/play.svg'
import { getRoute, ROUTES } from '../../constants/navigation'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import { logout } from '../../redux/actions/AuthActions'
import { SmartLink } from '../SmartLink/SmartLink'
import beachImg from '../../assets/membership/beach.svg'
import {
  setShowMembershipModal,
  setShowPurchaseCoins
} from '../../redux/designerReducers/designerReducer'

export const ProfileFloatingMenu = ({ onClose }) => {
  const profile = useSelector(state => state.profile.me)
  const wrapperRef = useRef()
  const dispatch = useDispatch()

  useOutsideAlerter(wrapperRef, () => onClose(false))

  if (!profile) return null

  const membershipInfo = getMembershipType(profile)

  return (
    <AnimationWrapper ref={wrapperRef}>
      <ProfileFloatingMenuWrapper>
        <EmailSection>
          {profile.email || profile.username || profile.uniqueDisplayName}
        </EmailSection>
        <LinksGroup>
          <GroupedLink
            onClick={() => {
              dispatch(setShowPurchaseCoins(true))
              onClose()
            }}
          >
            <span>Coin Balance:</span>
            <LinkIcon src={coinIcon} alt="" />
            <span>{profile.numCoins}</span>
          </GroupedLink>
          <SmartLink route={ROUTES.USER.PROFILE}>
            <GroupedLink onClick={onClose}>
              <span>Member:</span>
              <LinkIcon src={membershipInfo.icon} alt="" />
              <span>{membershipInfo.membershipName}</span>
            </GroupedLink>
          </SmartLink>
          {!membershipInfo.isMember && (
            <MembershipBanner
              onClick={e => {
                dispatch(setShowMembershipModal(true))
              }}
            >
              <img src={beachImg} alt="" />
              <span>Free Designing?</span>
            </MembershipBanner>
          )}
        </LinksGroup>
        <LinksGroup>
          <SmartLink route={ROUTES.CREATE.CHALLENGES}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={decorateIcon} alt="" />
              <span>Play Design Games</span>
            </GroupedLink>
          </SmartLink>
          <SmartLink route={ROUTES.CREATE.TEMPLATES}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={designIcon} alt="" />
              <span>Design on Templates</span>
            </GroupedLink>
          </SmartLink>
          <SmartLink route={ROUTES.CREATE.DECORATE_MY_ROOM}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={decorateIcon} alt="" />
              <span>Decorate My Rooms</span>
            </GroupedLink>
          </SmartLink>
          <SmartLink route={ROUTES.CREATE.MY_EVENTS}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={joinIcon} alt="" />
              <span>Join User Events</span>
            </GroupedLink>
          </SmartLink>
        </LinksGroup>
        <LinksGroup>
          <SmartLink route={ROUTES.BLOG}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={blogIcon} alt="" />
              <span>Blog</span>
            </GroupedLink>
          </SmartLink>
          <SmartLink route={ROUTES.USER.MY_EVENTS}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={myEventDesktopIcon} alt="" />
              <span>View MyEvent</span>
            </GroupedLink>
          </SmartLink>
          <SmartLink route={ROUTES.USER.PROFILE}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={profileIcon} alt="" />
              <span>My Profile</span>
            </GroupedLink>
          </SmartLink>
          <SmartLink route={ROUTES.USER.SETTINGS}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={cogIcon} alt="" />
              <span>Account Settings</span>
            </GroupedLink>
          </SmartLink>
          <SmartLink route={ROUTES.HELP_CENTER}>
            <GroupedLink onClick={onClose}>
              <LinkIcon src={helpIcon} alt="" />
              <span>Help Center</span>
            </GroupedLink>
          </SmartLink>
        </LinksGroup>
        <BottomSection>
          <LogOutButton
            onClick={() => {
              onClose()
              dispatch(logout({}))
            }}
          >
            Log Out
          </LogOutButton>
        </BottomSection>
      </ProfileFloatingMenuWrapper>
    </AnimationWrapper>
  )
}
