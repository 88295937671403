import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import locationChangeEvent from '../utils/locationChangeEvent'

export const useHeaderOptions = () => {
  const [path, setPath] = useState(locationChangeEvent.currentValue || '')
  const { asPath, components, pathname } = useRouter()
  const currentComponent = components
    ? components[asPath] || components[pathname]
    : null

  useEffect(() => {
    const handler = (location, href) => {
      setPath(href || location)
    }

    handler(window.location.pathname)

    locationChangeEvent.subscribe(handler)

    return () => {
      locationChangeEvent.unsubscribe(handler)
    }
  }, [])

  if (!currentComponent) return { browserPath: path }

  const { headerOptions } = currentComponent?.Component

  return { ...headerOptions, browserPath: path }
}
