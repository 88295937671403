import dynamic from 'next/dynamic'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseShareBox } from '../../redux/actions/ShareAction'
import { loadMe } from '../../routes/Profile/store/profileReducer'
import DevTools from '../DevTools'
import MainHeader from '../headers/MainHeader'
import HelmetContainer from '../helmet/Helmet'
import { AreasWrapper, ContentBox } from './PageWrapper.styles'
import { getUserId } from '../../utils/authLocalStorage'
import { USER_VALID_SESSION_VALID } from '../../constants/AuthActionsConstants'
import ReactJoyride from 'react-joyride'
import { TutorialTooltip } from '../TutorialTooltip/TutorialTooltip'
//import { resetAndOpen } from '../../redux/communityReducers/CheckInReducer'
import moment from 'moment'
import { getUserToken } from '../../utils/fetchUtils'
import useDailyTasks from '../../hooks/useDailyTasks'

const Auth = dynamic(() => import('../auth/Auth'))
const PageModals = dynamic(() => import('./PageModals'))

export const PageInsideWrapper = ({ children, disableModals, disableOverscroll, inApp }) => {
  const { openDailyTask } = useDailyTasks()
  const dispatch = useDispatch()
  const authenticated = useSelector(state => state.auth.isAuthenticated)
  const showAuth = useSelector(state => state.auth.showAuth)

  useEffect(() => {
    const sessionMark = sessionStorage.getItem('openedDailyModal')

    const sessionMarkLessThan24Hours = sessionMark && moment(sessionMark).isAfter(moment().subtract(24, 'hours'))

    if (location.search || sessionMarkLessThan24Hours) return

    sessionStorage.setItem('openedDailyModal', new Date().toISOString())

    /*
    const openDaily = async () => {
      console.log("OPEN DAILY")

    //dispatch(resetAndOpen())
    await openDailyTask()
    }
    openDaily()
    */
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', () => {
      dispatch(CloseShareBox())
    })
  }, [])

  useEffect(() => {
    if (getUserId()) dispatch({ type: USER_VALID_SESSION_VALID })
    dispatch(loadMe({ history: {} }))

    const listener = () => {
      //console.log("[FOUND IT] loadMe")
      if (document.visibilityState === 'visible') {
        //console.log(getUserToken())
        if (!getUserToken()) return
        dispatch(loadMe({ history: {} }))
      }
    }

    window.addEventListener('visibilitychange', listener)

    return () => {
      window.removeEventListener('visibilitychange', listener)
    }
  }, [dispatch])

  return (
    <>
      <HelmetContainer />
      <AreasWrapper>
        {!inApp && <MainHeader />}
        <ContentBox disableOverscroll={disableOverscroll} id="scroll-box">
          {children}
        </ContentBox>
      </AreasWrapper>
      {(!disableModals || authenticated) && !inApp && <PageModals />}
      {showAuth && <Auth />}
      {typeof window !== 'undefined' && window.location.hostname === 'localhost' && <DevTools />}
    </>
  )
}
