import { useDispatch } from 'react-redux'
import { useDecor } from '../context/decor'
import { openModal } from '../redux/communityReducers/CheckInReducer'

const useDailyTasks = () => {
  const { manualRefreshTasks } = useDecor()
  const dispatch = useDispatch()

  const openDailyTask = async () => {
    await manualRefreshTasks()
    dispatch(openModal())
  }

  return { openDailyTask }
}

export default useDailyTasks
