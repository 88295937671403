import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  PopularCard,
  SearchCardsGrid,
  SearchSubtitle,
  SearchTitleLine
} from './QuickSearchContent.styles'
import { usePopularHashtags } from '../../queries/search'
import Link from 'next/link'
import { useIsMobile } from '../../hooks/useIsMobile'
import { gotoSignUp } from '../../redux/actions/AuthActions'

export const PopularHashtags = ({ onClose }) => {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const popularHashtags = usePopularHashtags()

  const dataSource =
    popularHashtags.data && popularHashtags.data.popularHashtags
      ? popularHashtags.data.popularHashtags.slice(0, isMobile ? 6 : 9)
      : []

  return (
    <>
      <SearchTitleLine>
        <SearchSubtitle>Popular in DecorMatters</SearchSubtitle>
      </SearchTitleLine>
      <SearchCardsGrid>
        {dataSource.map(item => {
          const cleanTitle = item.hashtag.title.replaceAll('#', '').trim()

          const card = (
            <PopularCard
              key={item.hashtag.title}
              onClick={
                isAuthenticated
                  ? onClose
                  : () => {
                      onClose()
                      dispatch(gotoSignUp())
                    }
              }
              background={item.hashtag.previewImageUrl}
            >
              <span>{`#${cleanTitle}`}</span>
            </PopularCard>
          )

          if (isAuthenticated)
            return (
              <Link key={item.objectId} href={`/hashtag/${cleanTitle}`}>
                {card}
              </Link>
            )

          return card
        })}
        {popularHashtags.isLoading &&
          new Array(isMobile ? 6 : 8)
            .fill(0)
            .map((_, i) => <PopularCard key={i} placeholder="true" />)}
      </SearchCardsGrid>
    </>
  )
}
