import React from 'react'
import {
  DMLogo,
  GetAppButtonWrapper,
  MenuLinkLine,
  MenuLinkName,
  MenuLinks,
  MobileDrawer
} from './MobileDrawerMenu.styles'
import textLogoImg from '../../assets/text-logo.png'
import addPostIcon from '../../assets/navbar/addDesign.svg'
import homeIcon from '../../assets/navbar/home.svg'
import blogIcon from '../../assets/navbar/blog.svg'
import myEventIcon from '../../assets/navbar/myEvent.svg'
import notificationIcon from '../../assets/navbar/bell.svg'
import profileIcon from '../../assets/navbar/profile.svg'
import helpIcon from '../../assets/navbar/help.svg'
import termsIcon from '../../assets/navbar/security.svg'
import aboutIcon from '../../assets/navbar/info.svg'
import securityIcon from '../../assets/navbar/terms-and-conditions.svg'
import GetAppButton from '../GetAppButton'
import Link from 'next/link'
import { MobileModal } from '../MobileModal/MobileModal'
import { getRoute, ROUTES } from '../../constants/navigation'

export const MobileDrawerMenu = ({
  onClose,
  showNotifications,
  isAuthenticated
}) => {
  return (
    <MobileModal onClose={onClose} horizontal>
      <MobileDrawer>
        <DMLogo src={textLogoImg} alt="DecorMatters" />
        <GetAppButtonWrapper>
          <GetAppButton />
        </GetAppButtonWrapper>
        <MenuLinks>
          <Link href={getRoute(ROUTES.HOMEPAGE).path}>
            <MenuLinkLine onClick={onClose}>
              <img src={addPostIcon} alt="" />
              <MenuLinkName>Create</MenuLinkName>
            </MenuLinkLine>
          </Link>
          <Link href={getRoute(ROUTES.COMMUNITY.FEATURED).path}>
            <MenuLinkLine onClick={onClose}>
              <img src={homeIcon} alt="" />
              <MenuLinkName>Community</MenuLinkName>
            </MenuLinkLine>
          </Link>
          <Link href={getRoute(ROUTES.BLOG).path}>
            <MenuLinkLine onClick={onClose}>
              <img src={blogIcon} alt="" />
              <MenuLinkName>Blog</MenuLinkName>
            </MenuLinkLine>
          </Link>
          {isAuthenticated && (
            <MenuLinkLine
              onClick={() => {
                onClose()
                showNotifications()
              }}
            >
              <img src={notificationIcon} alt="" />
              <MenuLinkName>Notifications</MenuLinkName>
            </MenuLinkLine>
          )}
          {isAuthenticated && (
            <>
              <Link href={getRoute(ROUTES.USER.PROFILE).path}>
                <MenuLinkLine onClick={onClose}>
                  <img src={profileIcon} alt="" />
                  <MenuLinkName>Profile</MenuLinkName>
                </MenuLinkLine>
              </Link>
              <Link href={getRoute(ROUTES.USER.MY_EVENTS).path}>
                <MenuLinkLine onClick={onClose} underlined>
                  <img src={myEventIcon} alt="" />
                  <MenuLinkName>View MyEvent</MenuLinkName>
                </MenuLinkLine>
              </Link>
            </>
          )}
          <Link href={getRoute(ROUTES.HELP_CENTER).path}>
            <MenuLinkLine onClick={onClose}>
              <img src={helpIcon} alt="" />
              <MenuLinkName>Help Center</MenuLinkName>
            </MenuLinkLine>
          </Link>
          <Link href={getRoute(ROUTES.STATIC_PAGES.TERMS).path}>
            <MenuLinkLine onClick={onClose}>
              <img src={termsIcon} alt="" />
              <MenuLinkName>Terms of Use</MenuLinkName>
            </MenuLinkLine>
          </Link>
          <Link href={getRoute(ROUTES.STATIC_PAGES.PRIVACY_POLICY).path}>
            <MenuLinkLine onClick={onClose}>
              <img src={securityIcon} alt="" />
              <MenuLinkName>Privacy Policy</MenuLinkName>
            </MenuLinkLine>
          </Link>
          <Link href={getRoute(ROUTES.STATIC_PAGES.ABOUT).path}>
            <MenuLinkLine onClick={onClose}>
              <img src={aboutIcon} alt="" />
              <MenuLinkName>About</MenuLinkName>
            </MenuLinkLine>
          </Link>
        </MenuLinks>
      </MobileDrawer>
    </MobileModal>
  )
}
