import styled from 'styled-components'

export const HeaderMenuWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  width: 100vw;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  z-index: 1;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`

export const HeaderMenuItem = styled.li`
  display: inline;
`

export const HeaderMenuLink = styled.a`
  display: inline-block;
  color: ${props => (props.active ? '#000000' : '#979797')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  text-align: center;
  padding: 14px 24px;
  text-decoration: none;
  border-bottom: 4px solid
    ${props => (props.active ? '#000000' : 'transparent')};
  transition-property: border-bottom, color;
  transition-duration: 0.2s;
  transition-timing-function: linear;

  &:hover {
    color: black;
    border-bottom: 4px solid black;
  }
`
