import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

export const Header = () => {
  const router = useRouter()

  if (typeof router.query.inapp !== 'undefined') return null

  return (
    <>
      <MobileHeader />
      <DesktopHeader />
    </>
  )
}
