import { useState, useEffect } from 'react'

const queries = {
  phone: '(min-width: 320px) and (max-width: 568px)',
  tabletOnly: '(min-width : 768px)',
  tablet: '(min-width : 768px) and (max-width : 1024px)',
  desktopOnly: '(min-width : 992px)',
  desktopWorkspace: '(min-width : 1024px)',
  desktop: '(min-width : 1224px)'
}
// eslint-disable-next-line
export default query => {
  if (typeof window !== 'object') return
  if (!window.matchMedia) return

  const queryToMatch = queries[query] || query
  const [matches, setMatches] = useState(
    window.matchMedia(queryToMatch).matches
  )

  useEffect(() => {
    const media = window.matchMedia(queryToMatch)
    if (media.matches !== matches) setMatches(media.matches)
    const listener = () => setMatches(media.matches)
    media.addEventListener
      ? media.addEventListener('change', listener)
      : media.addListener(listener)
    return () =>
      media.removeEventListener
        ? media.removeEventListener('change', listener)
        : media.removeListener(listener)
  }, [matches, queryToMatch])

  return matches
}
