import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  DELETE_PRODUCTS,
  GET_BOOKMARKS,
  GET_WISHLIST,
  SAVE_PRODUCT2
} from '../../constants/ApiEndpoints'
import { api, checkFeedStatus } from '../../utils/fetchUtils'
import { FEED_RESET } from '../constants'

const bookmarkPageLimit = 15

export const fetchBookmarks = createAsyncThunk(
  'bookmark/fetchBookmarks',
  async (data, { getState }) => {
    //console.log('[bookmarkReducer][fetchBookmarks]')
    const { bookmarks, bookmarkPage, bookmarkPageEnd } = getState().bookmark

    const nextPage = checkFeedStatus(
      data,
      bookmarks.length,
      bookmarkPage,
      bookmarkPageEnd
    )
    if (nextPage === -1) return null

    const body = {
      skip: nextPage * bookmarkPageLimit,
      pageLimit: bookmarkPageLimit
    }

    let response = await api(GET_WISHLIST, JSON.stringify(body))

    return {
      data: response.result.savedProducts,
      page: nextPage
    }
  }
)

export const fetchBookmarkIds = createAsyncThunk(
  'bookmark/fetchBookmarkIds',
  async (data, { getState }) => {
    //console.log('[bookmarkReducer][fetchBookmarkIds]')
    let response = await api(GET_BOOKMARKS, JSON.stringify({}))
    return response.result.wishlistIds
  }
)

export const saveBookmark = createAsyncThunk(
  'bookmark/saveBookmark',
  async (data, { getState }) => {
    //console.log('[bookmarkReducer][saveBookmark]')
    const body = { itemIds: [data] }
    const response = await api(SAVE_PRODUCT2, JSON.stringify(body))
    return response.result
  }
)

export const unsaveBookmark = createAsyncThunk(
  'bookmark/unsaveBookmark',
  async (data, { getState }) => {
    //console.log('[bookmarkReducer][unsaveBookmark]')
    await api(DELETE_PRODUCTS, JSON.stringify(data))
    return data
  }
)

const bookmark = createSlice({
  name: 'bookmark',
  initialState: {
    bookmarks: [],
    bookmarkPage: 0,
    bookmarkPageEnd: false,
    bookmarkError: null,
    loading: 'pending',
    bookmarkReference: {},
    filters: []
  },
  reducers: {
    clearBookmarks: (state, action) => {
      state.bookmarks = []
      state.bookmarkReference = {}
      state.page = 0
    },
    select: (state, action) => {
      state.selected = action.payload
    }
  },
  extraReducers: {
    [fetchBookmarks.pending]: (state, action) => {
      state.bookmarkError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.bookmarks = []
        state.bookmarkPage = 0
        state.bookmarkPageEnd = false
      }
      if (state.bookmarkPage === 0) state.loading = 'pending'
    },
    [fetchBookmarks.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return
      if (action.payload.data.length < bookmarkPageLimit)
        state.bookmarkPageEnd = true
      else state.bookmarkPageEnd = false
      state.bookmarks = [...state.bookmarks, ...action.payload.data]
      state.bookmarkPage = action.payload.page + 1

      if (state.bookmarks.length === 0) state.bookmarkError = 'empty'
      else state.bookmarkError = null
    },
    [fetchBookmarkIds.fulfilled]: (state, action) => {
      if (!action.payload) return
      const ids = action.payload
      const obj = {}

      if (ids.itemIds) ids.itemIds.forEach(e => (obj[e] = e))
      if (ids.marketProductId) ids.marketProductIds.forEach(e => (obj[e] = e))
      if (ids.productIds) ids.productIds.forEach(e => (obj[e] = e))

      state.bookmarkReference = obj
    },
    [saveBookmark.fulfilled]: (state, action) => {
      if (!action.payload) return
      const it = action.payload
      const obj = {}

      it.forEach(e => {
        if (e.item) obj[e.item.objectId] = e.item.objectId
        if (e.marketProduct)
          obj[e.marketProduct.objectId] = e.marketProduct.objectId
        if (e.product) obj[e.product.objectId] = e.product.objectId
      })

      state.bookmarkReference = { ...state.bookmarkReference, ...obj }
    },
    [unsaveBookmark.fulfilled]: (state, action) => {
      if (!action.payload) return

      const ids = action.payload

      if (ids.itemIds) {
        delete state.bookmarkReference[ids.itemIds[0]]

        const fi = state.bookmarks.findIndex(
          e => e.item && e.item.objectId === ids.itemIds[0]
        )
        if (fi > -1) state.bookmarks.splice(fi, 1)
      }

      if (state.bookmarks.length === 0) state.bookmarkError = 'empty'
      else state.bookmarkError = null
    }
  }
})

export const { clearBookmarks } = bookmark.actions

export default bookmark.reducer
