import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import heartImg from '../../assets/social/heart.svg'
import { gotoLogIn, gotoSignUp } from '../../redux/actions/AuthActions'
import {
  likeUserInspiration,
  postActions,
  unlikeUserInspiration,
  unpostActions
} from '../../redux/actions/LikeAction'
import { logEvent } from '../../utils/firebase'
import { ActionButton, ActionButtonIcon } from './SocialButtons.styles'

const LikeBtn = ({
  postId,
  commentId,
  noButton,
  numLikes,
  loc,
  renderButton,
  ...props
}) => {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const { likes: likesObj, updatedLikeObj } = useSelector(state => state.like)
  const { commentLikes } = useSelector(state => state.postActions)
  const [active, setActive] = useState(false)
  const [likeCount, setCount] = useState(numLikes ? numLikes : 0)

  useEffect(() => {
    if (postId) {
      likesObj && likesObj.includes(postId) ? setActive(true) : setActive(false)
    }

    if (commentId) {
      commentLikes && commentLikes.includes(commentId)
        ? setActive(true)
        : setActive(false)
    }
  }, [likesObj, commentLikes, postId, commentId])

  useEffect(() => {
    if (updatedLikeObj.id === postId || updatedLikeObj.id === commentId)
      setCount(updatedLikeObj.numOfLikes)
  }, [postId, updatedLikeObj, commentId])

  const like = () => {
    const tag = loc + '_like_clicked'

    logEvent(tag)

    if (postId) {
      dispatch(likeUserInspiration({ designId: postId, likeCount }))
    }

    if (commentId) {
      dispatch(postActions({ commentId, likeCount }))
    }
  }

  const unLike = () => {
    const tag = loc + '_unlike_clicked'

    logEvent(tag)

    if (postId) {
      dispatch(unlikeUserInspiration({ designId: postId, likeCount }))
    }

    if (commentId) {
      dispatch(unpostActions({ commentId, likeCount }))
    }
  }

  const likeClick = e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (!isAuthenticated) {
      dispatch(gotoSignUp())
      return
    }
    !active ? like() : unLike()
  }

  if (renderButton) {
    return renderButton({ active, likeClick, props })
  }

  return (
    <>
      <ActionButton
        active={active}
        loc={loc}
        as={noButton ? 'div' : 'button'}
        onClick={e => likeClick(e)}
        {...props}
      >
        <ActionButtonIcon src={heartImg} alt="" />
        <span>{likeCount}</span>
      </ActionButton>
    </>
  )
}

export default LikeBtn
