import { useHeaderOptions } from './useHeaderOptions'

export const useSecondaryMenu = () => {
  const headerOptions = useHeaderOptions()

  const { headerMenu, browserPath } = headerOptions

  if (!headerMenu) return []

  const menuItems = headerMenu.map(item => {
    const { condition, headerPaths = [], ...props } = item

    return {
      ...props,
      headerPaths,
      visible: typeof condition === 'function' ? condition() : true,
      active: false
    }
  })

  // find last to make sure that only one is selected when item path is "/"
  // check create page
  const lastActiveItem = menuItems.findLast(item => {
    return (
      browserPath?.includes(item.path) ||
      item.headerPaths.some(headerPath => browserPath?.includes(headerPath))
    )
  })

  if (lastActiveItem) {
    lastActiveItem.active = true
  }

  return menuItems

  // active:
  // browserPath?.includes(props.path) ||
  // headerPaths.some(headerPath => browserPath?.includes(headerPath))
}
