export const COMMUNITY_CATEGORIES_LIST = [
  {
    label: 'All',
    filters: {
      types: [
        'event',
        'design',
        'contest',
        'pgc',
        'vlog',
        'blog',
        'influencerPgc',
        'influencerBlog',
        'influencerVlog',
        'blog',
        'vlog'
      ],
      kinds: ['featured']
    }
  },
  {
    label: 'Events',
    filters: {
      types: ['event'],
      kinds: ['featured']
    }
  },
  {
    label: 'Designs',
    filters: {
      types: ['design'],
      kinds: ['featured']
    }
  },
  {
    label: 'Magazine',
    filters: {
      types: ['pgc'],
      kinds: ['featured']
    }
  },
  {
    label: 'Blogs',
    filters: {
      types: ['blog', 'vlog'],
      kinds: ['featured']
    }
  }
]
