import qs from 'qs'
import {
  GET_BLOG_CATEGORIES,
  GET_BLOG_HOMEPAGE_METATAGS,
  GET_BLOG_POSTS,
  GET_POST_FEATURE_INFO
} from '../constants/ApiEndpoints'
import { useQuery } from 'react-query'

const FETCH_OPTIONS = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Authorization:
      'Bearer 4a03e51c7bea8e0dccef74da53b78ef5ac0996a8484c4fab91968ba6c73fa49073227764b4b26d5264bd24512a79c030f0aa0dacac888b41d3eb376b8d33cf295ab1b2d4f13e7e6ae6212b610e7c587ee8c408aa4c26f9878254f8e2d7f02d16481cf61acef12416ab673fe27d4bbd8afccff0ede9b67f827c5633d6b8737ce9'
  }
}

export const getFeaturedPost = async () => {
  const filters = {
    isFeatured: true
  }

  const result = await fetch(
    `${GET_BLOG_POSTS}?${qs.stringify({
      populate: '*',
      pagination: {
        page: 1,
        pageSize: 1
      },
      filters
    })}`,
    FETCH_OPTIONS
  )

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  const content = await result.json()

  if (!content) {
    throw new Error('No result')
  }

  return content
}

export const getAllBlogPosts = async (
  query = {},
  randomSort = false,
  pageSize = 10,
  populate = '*'
) => {
  const filters = {
    isFeatured: false
  }

  if (query.isFeatured === null || query.category) delete filters.isFeatured

  if (query.category) {
    filters.categories = {
      id: {
        $eq: parseInt(query.category)
      }
    }
  }

  if (query.q) {
    filters.title = {
      $containsi: query.q
    }
  }

  const limits = {
    pagination: {}
  }

  if (pageSize === -1) {
    limits.pagination.limit = -1
    limits.pagination.start = 0
  } else {
    limits.pagination.pageSize =
      randomSort || query.category
        ? pageSize
        : !query.page || query.page === 1
        ? 9
        : 10
    limits.pagination.page = query.page || 1
  }

  const result = await fetch(
    `${GET_BLOG_POSTS}?${qs.stringify({
      populate,
      randomSort,
      ...limits,
      filters,
      sort: randomSort ? undefined : ['createdDatetime:desc']
    })}`,
    FETCH_OPTIONS
  )

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  const content = await result.json()

  if (!content) {
    throw new Error('No result')
  }

  return content
}

export const getBlogPost = async query => {
  const result = await fetch(
    `${GET_BLOG_POSTS}?${qs.stringify({
      populate: 'cover_image, author.image, categories, seo, meta_social',
      pagination: {
        page: 1,
        pageSize: 1
      },
      filters: {
        slug: {
          $eq: query.postSlug
        }
      }
    })}`,
    FETCH_OPTIONS
  )

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  const content = await result.json()

  if (!content) {
    throw new Error('No result')
  }

  return content
}

export const getBlogCategories = async () => {
  const result = await fetch(
    `${GET_BLOG_CATEGORIES}?${qs.stringify({
      populate: 'deep',
      sort: ['id']
    })}`,
    FETCH_OPTIONS
  )

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  const content = await result.json()

  if (!content) {
    throw new Error('No result')
  }

  return content
}

export const getHomepageMetaTags = async () => {
  const result = await fetch(
    `${GET_BLOG_HOMEPAGE_METATAGS}?${qs.stringify({
      populate: 'deep'
    })}`,
    FETCH_OPTIONS
  )

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  const content = await result.json()

  if (!content) {
    throw new Error('No result')
  }

  return content
}

export const useRecommendedBlogposts = () =>
  useQuery('GET_RECOMMENDED_BLOGPOSTS', async () => {
    const result = await fetch(
      `${GET_BLOG_POSTS}?${qs.stringify({
        populate: 'cover_image',
        pagination: {
          page: 1,
          pageSize: 3
        }
      })}`,
      FETCH_OPTIONS
    )

    const { data } = await result.json()

    return data
  })

export const useBlogCategories = () =>
  useQuery('GET_BLOG_CATEGORIES', async () => {
    const result = await fetch(
      `${GET_BLOG_CATEGORIES}?${qs.stringify({
        populate: 'meta.metaImage',
        pagination: {
          page: 1,
          pageSize: 6
        }
      })}`,
      FETCH_OPTIONS
    )

    const { data } = await result.json()

    return data
  })

export const useBlogSearch = searchQuery =>
  useQuery(
    ['GET_BlOG_SEARCH_RESULTS', searchQuery],
    async () => {
      const result = await fetch(
        `${GET_BLOG_POSTS}?${qs.stringify({
          populate: 'cover_image',
          filters: {
            title: {
              $containsi: searchQuery
            }
          },
          pagination: {
            page: 1,
            pageSize: 3
          }
        })}`,
        FETCH_OPTIONS
      )

      const { data } = await result.json()

      return data
    },
    { enabled: !!searchQuery }
  )

export const getBlogFeatureInfo = async () => {
  const result = await fetch(GET_POST_FEATURE_INFO, FETCH_OPTIONS)

  if (!result.ok) {
    throw new Error('Data fetching failed')
  }

  const {
    data: { attributes }
  } = await result.json()

  return attributes
}
