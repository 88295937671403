import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import bg from '../../assets/weekly-report-bg.png'
import { GET_USER_INSPIRATION } from '../../constants/ApiEndpoints'
import { parseHeaders } from '../../utils/fetchUtil'
import { fetchChallenges } from '../../redux/designerReducers/challengeReducer'

import { ReactComponent as ReceivedIcon } from '../../assets/weekly-report/received.svg'
import { ReactComponent as ChallengesIcon } from '../../assets/weekly-report/challenges.svg'
import { ReactComponent as TipsIcon } from '../../assets/weekly-report/tips.svg'
import { ReactComponent as DesignsIcon } from '../../assets/weekly-report/designs.svg'
import { ReactComponent as LikesIcon } from '../../assets/weekly-report/likes.svg'
import { ReactComponent as GiftsIcon } from '../../assets/weekly-report/gifts.svg'
import ChallengeCard from '../../components/ChallengeCard'
import {
  ButtonsOverlay,
  ChallengeCardContent,
  ChallengeCardTypeIcon,
  ChallengeTimeLeft,
  ChallengeTitle
} from '../../components/ChallengeCard/ChallengeCard.styles'

const Root = styled.div`
  padding: 16px 35px 0;
  position: relative;
  overflow-y: auto;
`

export const Header = styled.div`
  background: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 11px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 32px;

  & h2 {
    color: #000;
    font-family: Helvetica Neue;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & span {
      font-size: 12px;
      font-weight: 500;
    }
  }

  & img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid #fff;
    margin-right: 9px;
  }

  & span {
    color: #000;
    font-family: Helvetica Neue;
    font-size: 10px;
    font-weight: 700;
  }
`

export const SectionTitle = styled.div`
  color: #000;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  margin-top: 7px;

  & svg {
    width: 30px;
    height: 30px;
    margin-right: 9px;
  }
`

export const Section = styled.div`
  padding: 12px 0 15px 40px;
  position: relative;

  &::before {
    content: '';
    display: ${({ isLast }) => (isLast ? 'none' : 'block')};
    position: absolute;
    left: 15px;
    width: 1px;
    top: 0;
    bottom: 0;
    background: #d8d8d8;
  }
`

export const Received = styled.div`
  display: flex;
  justify-content: space-around;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #000;
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 24px;
    font-weight: 700;

    & span {
      color: #000;
      font-family: Helvetica Neue;
      font-size: 12px;
      font-weight: 400;
    }
  }
`

const Tips = styled.ul`
  padding: 0;
  margin: 0;
  color: #000;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
`

const DesignDescription = styled.span`
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  line-height: 154%;

  font-weight: 400;

  & b {
    font-weight: 700;
  }
`

const DesignImage = styled.img`
  display: block;
  margin: 20px 0 15px;
  width: 175px;
  height: 175px;
  border-radius: 10px;
`

const DesignData = styled.div`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  margin-right: 25px;

  & svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
`

const ChallengeWrapper = styled.div`
  width: 144px;
  height: auto;
  margin-right: 18px;
  display: inline-block;

  & > div {
    width: 100%;
    height: auto;
    aspect-ratio: 1;

    & ${ChallengeCardTypeIcon} {
      width: 30px;
      height: 30px;
      top: 10px;
      right: 10px;
      left: auto;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }

    & ${ButtonsOverlay} {
      display: none !important;
    }

    & ${ChallengeCardContent} {
      padding: 10px 7px;
    }

    & ${ChallengeTitle} {
      font-size: 10px;
    }

    & ${ChallengeTimeLeft} {
      font-size: 8px;
      margin-bottom: -3px;

      & img {
        width: 14px;
        height: 14px;
      }
    }
  }
`

const JoinButton = styled.button`
  width: 102px;
  height: 30px;
  border-radius: 100px;
  border: 1px solid #ff5e6d;
  background: #fff;
  margin-bottom: 12px;
  cursor: pointer;

  color: #ff5e6d;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 400;

  margin: 20px auto 0;
  display: block;
`

const Footer = styled.div`
  height: 95px;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  position: sticky;
  bottom: 0;
  color: #ff5e6d;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 400;

  & button {
    width: 100%;
    max-width: 297px;
    height: 44px;
    border-radius: 22px;
    background: #ff5e6d;
    border: 0;
    color: #fff;
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
    cursor: pointer;
    flex-grow: 0;
  }
`

export const WeeklyReportDetailed = ({ report, handleClose }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { me } = useSelector(state => state.profile)
  const { challenge = [] } = useSelector(state => state.challenge)

  const [designInfo, setDesignInfo] = useState(null)

  const getDesignInfo = async () => {
    const res = await fetch(GET_USER_INSPIRATION, {
      method: 'POST',
      mode: 'cors',
      headers: parseHeaders(),
      body: JSON.stringify({
        objectId: report.mostPopularDesignId
      })
    })

    const data = await res.json()

    setDesignInfo(data.result.userInspiration.idea)
  }

  useEffect(() => {
    if (!report) return
    dispatch(fetchChallenges())
    getDesignInfo()
  }, [report])

  return (
    <Root>
      <Header>
        <h2>
          WEEKLY REPORT
          <span>{report.period}</span>
        </h2>
        <img src={me?.funAvatarImageUrl || me.pic} />
        <span>{me.uniqueDisplayName}</span>
      </Header>
      <SectionTitle>
        <ReceivedIcon />
        Weekly Received
      </SectionTitle>
      <Section>
        <Received>
          <div>
            {report.numLikes}
            <span>Likes</span>
          </div>
          <div>
            {report.numFollowers}
            <span>Followers</span>
          </div>
          <div>
            {report.numGifts}
            <span>Gift Value</span>
          </div>
        </Received>
      </Section>
      <SectionTitle>
        <DesignsIcon />
        New Designs
      </SectionTitle>
      <Section>
        <DesignDescription>
          This week you have posted <b>{report.numDesigns || 0}</b> designs
          {!!report.numDesigns && ', here is the most popular design'}
        </DesignDescription>
        {designInfo && (
          <>
            <DesignImage src={designInfo.cfThumbImageUrl} />
            <DesignData>
              <LikesIcon />
              {report.popularDesignInfo.numLikes || 0} Likes
            </DesignData>
            <DesignData>
              <GiftsIcon /> {report.popularDesignInfo.numGifts || 0} Gifts
            </DesignData>
          </>
        )}
      </Section>
      <SectionTitle>
        <TipsIcon />
        Design Tips
      </SectionTitle>
      <Section>
        <Tips>
          <li>Try to use newest items</li>
          <li>Try to use filter to find items</li>
          <li>Try to use #hashtags to get exposure</li>
          <li>Share design to your friends, get more likes and followers</li>
        </Tips>
      </Section>
      <SectionTitle>
        <ChallengesIcon />
        Hot challenges in DecorMatters now
      </SectionTitle>
      <Section isLast>
        {challenge.slice(0, 2).map(c => {
          return (
            <ChallengeWrapper>
              <ChallengeCard
                onClickMore={() => {
                  router.push(`/challenges/${c.objectId}`)
                  handleClose()
                }}
                data={c}
              />
              <JoinButton
                onClick={() => {
                  router.push(`/challenges/${c.objectId}`)
                  handleClose()
                }}
              >
                Join Now
              </JoinButton>
            </ChallengeWrapper>
          )
        })}
      </Section>
      <Footer>
        <button
          onClick={() => {
            router.push('/')
            handleClose()
          }}
        >
          Create New Designs
        </button>
        There will be weekly report if you posted +1 design
      </Footer>
    </Root>
  )
}
