import dynamic from 'next/dynamic'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AvatarContainer,
  UserInfoSlotIcon,
  UserSlotInfoBold,
  Email,
  ProfilePopupContent,
  UserInfoSlot,
  UserInfoSlotDivider,
  UserInfoSlots,
  Username,
  PopupLink,
  LogOutButton,
  AvatarAccessory,
  MembershipBanner,
  MembershipButton
} from './ProfilePopupMenu.styles'
import coinIcon from '../../assets/coin.svg'
import { getMembershipType } from '../../utils/getMembershipType'
import Link from 'next/link'
import MobileModal from '../MobileModal'
import { logout } from '../../redux/actions/AuthActions'
import { getRoute, ROUTES } from '../../constants/navigation'
import beachImg from '../../assets/membership/beach.svg'
import { setShowMembershipModal } from '../../redux/designerReducers/designerReducer'

const DMProfilePic = dynamic(
  () =>
    import('@decormatters/dm-theme').then(component => component.DMProfilePic),
  { ssr: false }
)

export const ProfilePopupMenu = ({ onClose }) => {
  const user = useSelector(state => state.profile.me)
  const membershipInfo = getMembershipType(user)
  const dispatch = useDispatch()

  if (!user) return null

  return (
    <MobileModal onClose={onClose} respectHeader hideBgOnDesktop>
      <ProfilePopupContent>
        <AvatarContainer>
          <DMProfilePic
            showLevelBar={true}
            autoPlay
            startLevel={(user && user.userLevel) || 0}
            endLevel={(user && user.userLevel) || 0}
            level={(user && user.userLevel) || 0}
            pic={
              (user && user?.funAvatarImageUrl) || (user && user.pic) || null
            }
          />
          {user?.funAccessoriesImageUrl && (
            <AvatarAccessory
              alt=""
              src={user.funAccessoriesImageUrl}
              loading="lazy"
            />
          )}
        </AvatarContainer>
        <Username>{user.uniqueDisplayName || user.username}</Username>
        <Email>{user.email}</Email>
        <Link href={getRoute(ROUTES.USER.PROFILE).path}>
          <UserInfoSlots onClick={onClose}>
            <UserInfoSlot>
              <span>Balance:</span>
              <UserInfoSlotIcon src={coinIcon} alt="Coins" />
              <UserSlotInfoBold>{user.numCoins}</UserSlotInfoBold>
            </UserInfoSlot>
            <UserInfoSlotDivider />
            <UserInfoSlot>
              <span>Member:</span>
              <UserInfoSlotIcon src={membershipInfo.icon} alt="" />
              <UserSlotInfoBold>
                {membershipInfo.membershipName}
              </UserSlotInfoBold>
            </UserInfoSlot>
          </UserInfoSlots>
        </Link>
        {!membershipInfo.isMember && (
          <MembershipBanner
            onClick={e => {
              dispatch(setShowMembershipModal(true))
              onClose()
            }}
          >
            <img src={beachImg} alt="" />
            <span>Free Designing?</span>
            <MembershipButton>Upgrade</MembershipButton>
          </MembershipBanner>
        )}
        <Link href="/dm" passHref>
          <PopupLink onClick={onClose}>My Profile</PopupLink>
        </Link>
        <Link href="/settings" passHref>
          <PopupLink onClick={onClose}>Account Settings</PopupLink>
        </Link>
        <LogOutButton
          onClick={() => {
            onClose()
            dispatch(logout({}))
          }}
        >
          Log Out
        </LogOutButton>
      </ProfilePopupContent>
    </MobileModal>
  )
}
