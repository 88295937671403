import {
  DEV_BASE,
  GET_DESIGN_CONTEST,
  GET_USER_HOSTED_EVENT,
  SAVE_USER_HOSTED_EVENT,
  UPLOAD_IMAGE,
  GET_MY_USER_EVENTS,
  ARCHIVE_EVENT,
  GET_OTHER_USER,
  SEARCH_USER,
  GET_USER_EVENTS,
  GET_DID_JOIN_USER_EVENT,
  GET_JOIN_CHALLENGE,
  UNIQUE_NAME_LOOKUP,
  GET_COMMENT_LIKE_STATUSES,
  GET_NOTIFICATIONS,
  GET_DESIGN_GAMES,
  GET_ME2,
  GET_WELCOME_GIFTS,
  WELCOME_GIFT_STATUS,
  GET_DAILY_TASKS
} from '../constants/ApiEndpoints'
import { api, getUserToken, sendBase64File } from '../utils/fetchUtils'
import { deleteStoredDesign } from './data/designStore'

//-------------------------------------------------------------------------------------------------------
// USER

// 2024
export const getMe = async d => {
  const result = await api(GET_ME2, JSON.stringify({}))
  return result.result
}
// 2024
export const getNewUserTasks = async d => {
  const result = await api(GET_WELCOME_GIFTS, JSON.stringify({}))
  return result?.result?.welcomeGifts || []
}
// 2024
export const getNewUserTaskStatuses = async d => {
  const result = await api(WELCOME_GIFT_STATUS, JSON.stringify({}))
  return result?.result?.welcomeGiftRecord || {}
}
// 2024
export const getDailyTasks = async d => {
  const result = await api(GET_DAILY_TASKS, JSON.stringify({}))
  return result?.result || {}
}

//-------------------------------------------------------------------------------------------------------
// TEMPLATE

export const getTemplates = async d => {
  var url = DEV_BASE + 'getTemplates2'
  const result = await api(url, JSON.stringify({}))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  return result.result.templates
}

export const getTemplateById = async d => {
  var url = DEV_BASE + 'getTemplates2'
  const result = await api(url, JSON.stringify({ objectId: d }))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  return result.result.template
}

// User Event

export const getUserEventById = async d => {
  const result = await api(GET_USER_HOSTED_EVENT, JSON.stringify({ objectIds: [d] }))

  return result.result.userEvents[0]
}

export const getCommentLikeStatusesByIds = async d => {
  const result = await api(GET_COMMENT_LIKE_STATUSES, JSON.stringify({ commentIds: d }))

  return result.result.currentUserLikes
}

export const getUserById = async userId => {
  const result = await api(GET_OTHER_USER, JSON.stringify({ userId }))

  return result.result
}

export const getUsersById = async userIds => {
  const result = await api(SEARCH_USER, JSON.stringify({ userIds }))

  return result.result.users
}

export const saveUserEvent = async event => {
  const result = await api(SAVE_USER_HOSTED_EVENT, JSON.stringify(event))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null

  return result
}

export const getMyUserEvents = async data => {
  if (!getUserToken()) return {}

  const result = await api(GET_MY_USER_EVENTS, JSON.stringify(data))

  return result.result || {}
}

export const archiveEvent = async objectId => {
  return await api(ARCHIVE_EVENT, JSON.stringify({ objectId }))
}

//-------------------------------------------------------------------------------------------------------
// Upload Image

export const uploadImage = async data => {
  const result = await api(UPLOAD_IMAGE, JSON.stringify(data))
  return result
}

//-------------------------------------------------------------------------------------------------------
// CHALLENGE

export const getChallengeById = async d => {
  const result = await api(GET_DESIGN_CONTEST, JSON.stringify({ objectId: d }))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null

  let first = result?.result?.designContests?.find(item => item.objectId === d || item.type === d)

  if (first.nuc) {
    delete first.nuc.objectId
    first = {
      ...first,
      ...first.nuc,
      thumbImageFile: {
        url: first.nuc.thumbImgUrl
      },
      contestImageFile: {
        url: first.nuc.contestImgUrl
      },
      backdropImageFile: {
        url: first.nuc.backdropImgUrl
      }
    }
  }

  return first
}

export const getMyChallengeStatus = async () => {
  //console.log('MY CHALLENGE STATUS')
  const result = await api(GET_DESIGN_CONTEST)
  //console.log(result)
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  return result?.result?.userContestStatuses
}

export const getUserEventsStatus = async () => {
  const result = await api(GET_USER_EVENTS)
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  return result?.result?.userEventStatuses
}

export const getChallengeByType = async d => {
  var url = GET_DESIGN_CONTEST
  const result = await api(url, JSON.stringify({ types: [d], status: 'active' }))

  if (Object.keys(result).length === 0 && result.constructor === Object) return null

  return result.result.designContests
}

export const getHasJoinedUserEvent = async eventId => {
  var url = GET_DID_JOIN_USER_EVENT
  const result = await api(url, JSON.stringify({ userEventId: eventId }))

  if (Object.keys(result).length === 0 && result.constructor === Object) return null

  return result.result
}

export const getHasJoinedChallenge = async eventId => {
  var url = GET_JOIN_CHALLENGE
  const result = await api(url, JSON.stringify({ designContestId: eventId }))

  if (Object.keys(result).length === 0 && result.constructor === Object) return null

  return result.result
}
// 2024
export const getAvailableChallengesAndUserEvents = async d => {
  const result = await api(GET_DESIGN_GAMES, JSON.stringify({}))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  return result.result
}

//-------------------------------------------------------------------------------------------------------
// DRAFT

export const deleteDraft = async d => {
  var url = DEV_BASE + 'deleteIdeas2'
  const result = await api(url, JSON.stringify({ objectIds: [d] }))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  deleteStoredDesign()
  return result
}

//-------------------------------------------------------------------------------------------------------
// MYROOM

export const deleteMyRoom = async d => {
  var url = DEV_BASE + 'archiveUserTemplates1'
  const result = await api(url, JSON.stringify({ userTemplateIds: [d] }))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  return result
}

export const uploadMyRoom = async file => {
  const ext = file.name.split('.').pop()
  const surl = DEV_BASE + 'getSignedUrl1'
  const sresult = await api(surl, JSON.stringify({ ext }))
  const { signedUrl } = sresult.result
  const bresult = await sendBase64File(signedUrl, file)
  const url = DEV_BASE + 'createUserTemplate1'
  const result = await api(url, JSON.stringify({ cfImageUrl: bresult.config.url.split('?')[0] }))
  return result
}

//-------------------------------------------------------------------------------------------------------
// MYDECOR

export const createMyDecor = async (imageData, updateData) => {
  var url = DEV_BASE + 'createUserProduct4'
  const result = await api(url, JSON.stringify({ imageData }))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null

  if (updateData) {
    return await updateMyDecor({
      userProductId: result.result.savedUserProduct.objectId,
      ...updateData
    })
  }
  return result.result.savedUserProduct
}

export const updateMyDecor = async data => {
  var url = DEV_BASE + 'updateUserProduct2'
  const result = await api(url, JSON.stringify(data))
}

export const deleteMyDecor = async id => {
  var url = DEV_BASE + 'deleteUserProducts1'
  const result = await api(url, JSON.stringify({ userProductIds: [id] }))
  if (Object.keys(result).length === 0 && result.constructor === Object) return null
  return result.result.archivedUserProducts
}

export const removebg = async file => {
  const options = {
    crop: true
  }
  const formData = new FormData()
  formData.append('token', '234324324')
  formData.append('original', file)
  formData.append('options', JSON.stringify(options))
  const payload = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Authorization: 'f08cad7d61789610a64b2c1179b2ec8b'
    },
    body: formData
  }
  const result = await fetch('https://honeycomb-api.decormatters.com/process', payload)

  if (!result) return
  if (result.status) {
    if (result.status === 204) return
    if (result.status === 401) return
    if (result.status === 405) return
    if (result.status === 406) return
  }

  return result.blob()
}

export const lookupUniqueName = async uniqueDisplayName => {
  const result = await api(UNIQUE_NAME_LOOKUP, JSON.stringify({ uniqueDisplayName }))
  return result
}

export const getNotifications = async props => {
  const result = await api(GET_NOTIFICATIONS, JSON.stringify(props))

  return result?.result?.notifications
}
