import { useEffect } from 'react'

const useOutsideAlerter = (ref, closeMenu, active = true) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !event?.path?.includes(ref.current)) {
        closeMenu()
      }
    }

    if (active)
      setTimeout(
        () => document.addEventListener('click', handleClickOutside),
        0
      )

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })
}

export default useOutsideAlerter
