import { combineReducers } from 'redux'
import profileReducer from '../../routes/Profile/store/profileReducer'
import AuthReducer from './AuthReducer'
import BrandSlice from './BrandSlice'
import CheckInReducer from './CheckInReducer'
import CoinsHistoryReducer from './CoinsHistoryReducer'
import DesignDetailsReducer from './DesignDetailsReducer'
import DMReducer from './DMReducer'
import createInfiniteFeed from '../feedInfiniteSlice'
import FollowingReducer from './FollowingReducer'
import GiftReducer from './GiftReducer'
import InspirationScrollReducer from './InspirationScrollReducer'
import ItemSlice from './ItemSlice'
import LeaderBoardReducer from './leaderBoardReducer'
import LikeReducer from './LikeReducer'
import MarketProductFeedReducer from './MarketProductFeedReducer'
import NotificationsReducer from './NotificationsReducer'
import PopularHashTagsReducer from './PopularHashTagsReducer'
import PostActionsReducer from './PostActionsReducer'
import PriceTagReducer from './PriceTagReducer'
import ProductDetailsTabReducer from './ProductDetailsTabReducer'
import QuestReducer from './QuestReducer'
import SaveInspirationReducer from './SaveInspirationReducer'
import SaveProductReducer from './SaveProductReducer'
import ShareReducer from './ShareReducer'
import VerifyModalReducer from './VerifyModalReducer'
import workspaceReducer from '../designerReducers/workspaceReducer'
import assetsReducer from '../designerReducers/assetsReducer'
import hashReducer from '../designerReducers/hashReducer'
import itemsReducer from '../designerReducers/itemsReducer'
import effectsReducer from '../designerReducers/effectsReducer'
import bookmarkReducer from '../designerReducers/bookmarkReducer'
import cartReducer from '../designerReducers/cartReducer'
import canvasReducer from '../designerReducers/canvasReducer'
import designerReducer from '../designerReducers/designerReducer'
import userSavedReducer from '../designerReducers/userSavedReducer'
import designReducer from '../../routes/Profile/store/designReducer'
import locationStateReducer from '../designerReducers/locationStateReducer'
import challengeReducer from '../designerReducers/challengeReducer'
import designGamesReducer from '../designerReducers/designGamesReducer'

const allReducers = Object.assign(
  {},
  {
    profile: profileReducer,
    design: designReducer,
    like: LikeReducer,
    postActions: PostActionsReducer,
    saveInspiration: SaveInspirationReducer,
    saveProduct: SaveProductReducer,
    scroll: InspirationScrollReducer,
    productTab: ProductDetailsTabReducer,
    share: ShareReducer,
    marketProductFeed: MarketProductFeedReducer,
    priceTag: PriceTagReducer,
    popularHashTags: PopularHashTagsReducer,
    checkIn: CheckInReducer,
    gift: GiftReducer,
    auth: AuthReducer,
    designdetails: DesignDetailsReducer,
    verificationModal: VerifyModalReducer,
    follows: FollowingReducer,
    quest: QuestReducer,
    dm: DMReducer,
    notifications: NotificationsReducer,
    item: ItemSlice,
    brand: BrandSlice,
    inspiration: createInfiniteFeed(
      'inspiration',
      'userInspirations',
      'getUserInspirations5'
    ),
    saved: createInfiniteFeed(
      'saved',
      'savedUserInspirations',
      'getSavedUserInspirations1'
    ),
    search: createInfiniteFeed(
      'search',
      'userInspirations',
      'getUserInspirationsWithText2'
    ),
    drafts: createInfiniteFeed('drafts', 'drafts', 'getIdeas2'),
    coinsHistory: CoinsHistoryReducer,
    leaderBoard: LeaderBoardReducer,

    designer: designerReducer,
    userSaved: userSavedReducer,

    //DESIGN BROWSE
    otherDesigns: createInfiniteFeed(
      'otherDesigns',
      'userInspirations',
      'getUserInspirations5'
    ),
    template: createInfiniteFeed(
      'template',
      'templates',
      'getTemplates2',
      false
    ),
    draft: createInfiniteFeed('draft', null, 'getIdeas2'),
    challenge: challengeReducer,
    myEventPlayTab: createInfiniteFeed(
      'myEventPlayTab',
      'userEvents',
      'getUserEventsPlayTab1'
    ),
    myroom: createInfiniteFeed('myroom', 'userTemplates', 'getUserTemplates1'),

    //WORKSPACE
    mydecor: createInfiniteFeed('mydecor', 'userProducts', 'getUserProducts5'),
    purchased: createInfiniteFeed(
      'purchased',
      'mpPurchases',
      'getMarketplacePurchases2',
      false
    ),
    wish: createInfiniteFeed('wish', 'savedProducts', 'getSavedProductsAtlas2'),
    wishsearch: createInfiniteFeed(
      'wishsearch',
      'savedProducts',
      'getSavedProductsAtlas2'
    ),
    itemFeed: createInfiniteFeed('itemFeed', 'items', 'getItems2'),

    workspace: workspaceReducer,
    assets: assetsReducer,
    hashtags: hashReducer,
    items: itemsReducer,
    effects: effectsReducer,

    bookmark: bookmarkReducer,
    cart: cartReducer,
    canvas: canvasReducer,
    locationState: locationStateReducer,
    designGames: designGamesReducer
  }
)

const rootReducer = combineReducers(allReducers)
export default rootReducer
