import NextImage from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import moment from 'moment'
import { setLocationState } from '../../redux/designerReducers/locationStateReducer'
import { setShowMembershipModal } from '../../redux/designerReducers/designerReducer'
import { convertDataToArtboard, convertiOSDataToArtboard } from '../../utils/fetchUtils'
import { ButtonsOverlay, ChallengeCardContent, ChallengeCardTypeIcon, ChallengeCardWrapper, ChallengeTimeLeft, ChallengeTitle, JoinNowButton, MoreInfoButton } from './ChallengeCard.styles'
import { getChallengeById } from '../../redux/apis'
import clockIcon from '../../assets/challenge-clock.svg'
import { timeLeft } from '../../utils/utils'
import { ReactComponent as limitedChallengeIcon } from '../../assets/challengeTypes/limited.svg'
import { ReactComponent as classicChallengeIcon } from '../../assets/challengeTypes/classic.svg'
import { ReactComponent as dailyChallengeIcon } from '../../assets/challengeTypes/daily.svg'
import { ReactComponent as newUserChallengeIcon } from '../../assets/challengeTypes/newUser.svg'
import { ReactComponent as popupChallengeIcon } from '../../assets/challengeTypes/popup.svg'
import { ReactComponent as proChallengeIcon } from '../../assets/challengeTypes/pro.svg'
import { ReactComponent as seriesChallengeIcon } from '../../assets/challengeTypes/series.svg'
import { ReactComponent as specialChallengeIcon } from '../../assets/challengeTypes/special.svg'
import { ReactComponent as stagingChallengeIcon } from '../../assets/challengeTypes/staging.svg'
import { ReactComponent as throwBackChallengeIcon } from '../../assets/challengeTypes/throw_back.svg'
import { ReactComponent as weekendBackChallengeIcon } from '../../assets/challengeTypes/weekend.svg'
import { ReactComponent as levelChallengeIcon } from '../../assets/challengeTypes/level.svg'
import { ReactComponent as vacationChallengeIcon } from '../../assets/challengeTypes/vacation.svg'
import { useSelector } from 'react-redux'
import { getMembershipType } from '../../utils/getMembershipType'
import { fetchChallengeDraftId } from '../../redux/designerReducers/challengeReducer'
import { fetchUserDesign } from '../../redux/designerReducers/designsReducer'

const imgload = async url => {
  const img = new Image()
  if (img) {
    img.src = url
    await img.decode()
    return { w: img.width, h: img.height }
  }
}
/*
const myImageLoader = ({ src, width, quality }) => {
  const parse = src.split('.')
  const ext = parse.pop()
  const base = parse.join('.')
  const resize = base + '-' + width + 'w.' + (ext === 'gif' ? 'gif' : 'webp')
  return resize
  //return `${src}?w=${width}&q=${quality || 85}&?fm=webp`
}
*/
export const ChallengeCard = ({ disableButtons, data, onClickMore, onClickJoin, refFn, wide }) => {
  const router = useRouter()
  const [isJoined, setIsJoined] = useState(false)
  const [isStarted, setIsStarted] = useState(false)
  const status = useSelector(state => state.designGames.status)
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile?.me)
  const membershipInfo = getMembershipType(profile)
  const [src, setSrc] = useState('')

  if (data.nuc) {
    data = {
      ...data,
      ...data.nuc
    }
  }

  useEffect(() => {
    //console.log(data)

    if (data.thumbImgUrl) {
      setSrc(data.thumbImgUrl)
      return
    }

    //console.log(data)
    if (data.thumbImageFile?.url) {
      setSrc(data.thumbImageFile.url)
      return
    }
  }, [data])

  useEffect(() => {
    if (status?.started && status?.started.includes(data.objectId)) {
      setIsStarted(true)
    }

    if (status?.joined && status?.joined.includes(data.objectId)) {
      setIsJoined(true)
    }
  }, [status])

  const time = useMemo(
    d => {
      const eventTime = moment(data.expiresAt.iso)
      const now = moment()
      const dx = eventTime - now
      const duration = moment.duration(dx, 'milliseconds')

      return timeLeft(duration)
    },
    [data.type, data.expiresAt.iso]
  )

  const ChallengeTypeIcon = useMemo(() => {
    switch (data.type) {
      case 'classic':
        return classicChallengeIcon
      case 'daily':
        return dailyChallengeIcon
      case 'newUser':
        return newUserChallengeIcon
      case 'popup':
        return popupChallengeIcon
      case 'pro':
        return proChallengeIcon
      case 'series':
        return seriesChallengeIcon
      case 'special':
        return specialChallengeIcon
      case 'staging':
        return stagingChallengeIcon
      case 'throwBack':
        return throwBackChallengeIcon
      case 'weekend':
        return weekendBackChallengeIcon
      case 'limited':
        return limitedChallengeIcon
      case 'vacation':
        return vacationChallengeIcon
      case 'level':
        return levelChallengeIcon
      case 'welcome':
        return null
      default:
        return dailyChallengeIcon
    }
  }, [data.type])

  const onClickMoreHandler = e => {
    e.preventDefault()
    e.stopPropagation()

    if (onClickMore) {
      onClickMore(e)
      return
    }

    router.push(`/challenges/${data.objectId}`)
  }

  const onClickJoinHandler = async e => {
    e.preventDefault()
    e.stopPropagation()

    if (onClickJoin) {
      onClickJoin(e)
      return
    }

    let challengeData

    challengeData = await getChallengeById(data.objectId)

    if (!challengeData) return

    //------------
    // Get Template Natural Dimensions
    const template = new Image()
    const tsrc = challengeData?.contestImageFile?.url || challengeData.templateImage
    template.src = tsrc + '?no-cache2'
    template.crossOrigin = 'anonymous'
    await template.decode()
    //------------

    var requirements = []
    if (challengeData.specialRequirements && challengeData.specialRequirements.tasks) requirements = challengeData.specialRequirements.tasks

    if (challengeData.requirements) requirements = challengeData.requirements

    if (challengeData.productsWithPosition && challengeData.productsWithPosition.length > 0) {
      var prods = [...challengeData.productsWithPosition]
      for (var i = 0; i < prods.length; i++) {
        const dim = await imgload(prods[i].imageUrl)
        prods[i] = {
          ...prods[i],
          w: dim.w,
          h: dim.h
        }
      }

      dispatch(
        setLocationState({
          idea: convertiOSDataToArtboard(challengeData.objectId, challengeData?.contestImageFile?.url || challengeData.templateImage, prods, challengeData, template.naturalWidth, template.naturalHeight),
          type: 'challenge',
          markers: challengeData.buttons,
          requirements,
          from: '/',
          challengeId: challengeData.objectId,
          designData: challengeData
        })
      )

      router.push(`/create/challenge/${challengeData.objectId}`)

      return
    }

    dispatch(
      setLocationState({
        idea: convertDataToArtboard(data.objectId, data?.contestImageFile?.url || data.templateImage, [], template.naturalWidth, template.naturalHeight),
        type: 'challenge',
        markers: data.buttons,
        requirements,
        from: '/',
        challengeId: data.objectId,
        designData: data
      })
    )

    router.push(`/create/challenge/${data.objectId}`)
  }

  const handleContinue = e => {
    e.stopPropagation()
    if (!data) return

    var requirements = []
    if (data.specialRequirements && data.specialRequirements.tasks) requirements = data.specialRequirements.tasks

    dispatch(fetchChallengeDraftId(data.objectId)).then(r => {
      const result = r.payload
      if (result.code) {
        dispatch(
          setLocationState({
            idea: convertDataToArtboard(data.objectId, data.contestImageFile.url, []),
            fromDraft: true,
            type: 'challenge',
            markers: data.buttons,
            requirements,
            challengeId: data.objectId
          })
        )
        router.push(`/create/challenge/${data.objectId}`)
      } else {
        dispatch(fetchUserDesign(result.ideaObjectId)).then(f => {
          const fp = f.payload
          dispatch(
            setLocationState({
              idea: convertDataToArtboard(fp.objectId, fp.cfBgImageUrl, fp.items),
              type: 'challenge',
              fromDraft: true,
              markers: data.buttons,
              requirements,
              from: '/',
              challengeId: data.objectId,
              designData: data
            })
          )
          router.push(`/create/challenge/${fp.objectId}`)
        })
      }
    })
  }

  const disableJoin = disableButtons || isJoined

  const handleRightButtonClick = e => {
    e.preventDefault()
    e.stopPropagation()

    if ((profile?.userLevel || 0) < data.levelRequired && !membershipInfo.isMember) {
      dispatch(setShowMembershipModal(true))

      return
    }

    if (isStarted) {
      handleContinue(e)
      return
    }

    if (disableJoin) return

    onClickJoinHandler(e)
  }

  const rightButtonText = useMemo(() => {
    if (isJoined) return 'Joined'

    if (isStarted) return 'Continue'

    return (profile?.userLevel || 0) < data.levelRequired && !membershipInfo.isMember ? `Members or Level ${data.levelRequired}+` : 'Join Now'
  }, [isJoined, isStarted, profile, membershipInfo.isMember, data.levelRequired])

  return (
    <ChallengeCardWrapper wide={wide} onClick={onClickMoreHandler} ref={refFn}>
      <NextImage
        src={src}
        alt={data.title}
        //unoptimized={data?.thumbImgUrl?.includes('.gif')}
        width={300}
        height={300}
        //loader={myImageLoader}
        sizes="(max-width: 800px) 140px, (max-width: 1024px) 240px, (max-width: 1200px) 240px, 300px"
      />
      <ChallengeCardContent>
        <ChallengeCardTypeIcon>{ChallengeTypeIcon && <ChallengeTypeIcon alt="" />}</ChallengeCardTypeIcon>
        <ChallengeTitle>{data.title}</ChallengeTitle>
        <ChallengeTimeLeft>
          <img src={clockIcon} alt="Time until end of challenge" />
          {time}
        </ChallengeTimeLeft>
      </ChallengeCardContent>
      <ButtonsOverlay>
        <MoreInfoButton disabled={disableButtons} onClick={onClickMoreHandler} color="white">
          View Detail
        </MoreInfoButton>
        <JoinNowButton disabled={disableJoin} onClick={disableButtons || isJoined || handleRightButtonClick}>
          {rightButtonText}
        </JoinNowButton>
      </ButtonsOverlay>
    </ChallengeCardWrapper>
  )
}
