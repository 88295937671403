import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { createGlobalStyle } from 'styled-components'
import PageWrapper from '../components/PageWrapper'
import Router from 'next/router'
import NProgress from 'nprogress'
import locationChangeEvent from '../utils/locationChangeEvent'
import 'nprogress/nprogress.css'
import '../assets/fonts/fonts.css'
import Theme from '../context/theme'

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => {
  locationChangeEvent.emit(window.location.pathname)
  NProgress.done()
})
Router.events.on('hashChangeComplete', () => {
  locationChangeEvent.emit(window.location.pathname, window.location.href)
})
Router.events.on('routeChangeError', () => NProgress.done())

const GlobalStyle = createGlobalStyle`
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

@media screen and (max-width: 600px) {
  #__next {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

a {
  color: black;
}

html {
  height: 100vh;
}

.currentItemIcon {
  transform: rotate(180deg);
}

input {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Helvetica', 'Roboto', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
*::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 46px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.open {
  display: block;
  background: white;
  height: auto;
  width: 196px;
  position: absolute;
  left: -146px;
  top: 60px;
  border-radius: 6px;
  z-index: 2;
}

.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  body {
    padding-bottom: var(--sab);
    overflow: hidden;
  }
}
`

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
})

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    const updateAppHeight = () => {
      document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px')
    }
    updateAppHeight()

    window.addEventListener('resize', updateAppHeight)
    return () => {
      window.removeEventListener('resize', updateAppHeight)
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <Theme>
          <PageWrapper inApp={pageProps.inApp} disableModals={pageProps.disableModals} disableOverscroll={pageProps.disableOverscroll}>
            <Component {...pageProps} />
          </PageWrapper>
        </Theme>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  )
}

export default App
