import {
  SET_COINS_HISTORY,
  SET_COINS_HISTORY_LOADING,
  SET_COINS_HISTORY_LOADED
} from '../../constants/CoinsHistoryConstants'

const initialState = {
  history: [],
  isLoading: false
}

const CoinsHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COINS_HISTORY: {
      return {
        ...state,
        history: action.payload
      }
    }
    case SET_COINS_HISTORY_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    case SET_COINS_HISTORY_LOADED: {
      return {
        ...state,
        isEverythingLoaded: action.payload
      }
    }
    default:
      return state
  }
}

export default CoinsHistoryReducer
