import React from 'react'
import appIconImg from '../../assets/AppQRCode.svg'
import appIconFocusedImg from '../../assets/AppQRCodeFocused.svg'
import QRCode from '../../assets/DecormattersQRCode.png'
import { useIsMobile } from '../../hooks/useIsMobile'
import {
  FloatingButtonIcon,
  FloatingButtonIconFocused,
  FloatingButtonWrapper,
  QRTooltip,
  QRTooltipContent,
  QRTooltipText
} from './FloatingButtons.styles'

export const FloatingQrButton = () => {
  const isMobile = useIsMobile()

  const handleClick = () => {
    if (!isMobile || typeof window === 'undefined') return

    window.open('https://decormatters.app.link/download')
  }

  return (
    <FloatingButtonWrapper
      aria-label="Download iOS App"
      onClick={handleClick}
      hasActiveState
    >
      <FloatingButtonIcon width={74} height={74} src={appIconImg} alt="" />
      <FloatingButtonIconFocused
        width={74}
        height={74}
        src={appIconFocusedImg}
        alt=""
      />

      <QRTooltip>
        <QRTooltipContent QRCode={QRCode} />
        <QRTooltipText>
          Scan Code to Download {'\n'}
          <span>DecorMatters iOS App</span>
        </QRTooltipText>
      </QRTooltip>
    </FloatingButtonWrapper>
  )
}
