import { createSlice } from '@reduxjs/toolkit'
import { APPID, GET_USER_INSPIRATION } from '../../constants/ApiEndpoints'
import { parseHeaders } from '../../utils/fetchUtil'
import { createApiAction } from '../../utils/reduxActionUtils'
import { gotoLogIn } from '../actions/AuthActions'
import { FEED_RESET } from '../constants'
import { fetchChallenges } from '../designerReducers/challengeReducer'
import { fetchFeed, updateFeedElement } from '../feedInfiniteSlice'
import { updatePost as updatePostStore } from '../actions/DesignDetailsAction.js'

const BASE =
  APPID === '1'
    ? 'https://decormatters-dev.herokuapp.com/parse/functions/'
    : `https://decormatters-prod.herokuapp.com/parse/functions/`

const ENDPOINTS = {
  CREATE_REPORT: `${BASE}createComplaint1`,
  GET_SEND_LIKES: `${BASE}getUserInspirationLikes1`,
  GET_COMMENTS: `${BASE}getUserInspirationComments2`,
  GET_REPLIES: `${BASE}getUserInspirationCommentReplies1`,
  SEND_COMMENTS: `${BASE}createUserInspirationComment3`,
  DELETE_COMMENT: `${BASE}deleteUserInspirationComment1`,
  DELETE_POST: `${BASE}deleteUserInspirations1`,
  UPDATE_POST: `${BASE}updateUserInspiration1`
}

const postActions = createSlice({
  name: 'postActions',
  initialState: {
    numLike: 0,
    commentLike: 0,
    commentData: [],
    userInsId: '',
    userInsIdComment: '',
    likeModal: false,
    reportModal: false,
    commentModal: false,
    objectId: null,
    userInsLikeList: [],
    userInsCommentList: [],
    commentLikes: [],
    showDeleteModal: false,
    postIdToDelete: null,
    deletingChallengePost: false,
    showFilterInfoModal: false,
    filterInfoPost: {}
  },
  reducers: {
    setFilterInfoModalVisible: (state, action) => {
      state.showFilterInfoModal = action.payload.visible
      state.filterInfoPost = action.payload.post
    },
    setPostLikeList: (state, action) => {
      state.userInsLikeList = action.payload
    },
    setCommentLikes: (state, action) => {
      state.commentLikes = action.payload.map(
        item => item.userInspirationComment.objectId
      )
    },
    setCommentLikesFromIds: (state, action) => {
      state.commentLikes = action.payload
    },
    showDeletionModal: (state, { payload }) => {
      state.deletingChallengePost = payload.isChallengePost
      state.postIdToDelete = payload.postId
      state.showDeleteModal = true
    },
    hideDeletionModal: state => {
      state.showDeleteModal = false
      state.postIdToDelete = null
      state.deletingChallengePost = false
    },
    appendCommentLikes: (state, action) => {
      state.commentLikes = state.commentLikes.concat(
        action.payload.map(item => item.userInspirationComment.objectId)
      )
    },
    likeClicked: (state, action) => {
      state.commentLikes = state.commentLikes.concat(action.payload)
    },
    likeUnclicked: (state, action) => {
      state.commentLikes = state.commentLikes.filter(
        item => item !== action.payload
      )
    },
    setPostCommentList: (state, action) => {
      state.userInsCommentList = action.payload
    },
    openModal: (state, action) => {
      state.likeModal = true
      state.numLike = action.payload.likeNum
      state.userInsId = action.payload.id
    },
    closeModal: state => {
      state.likeModal = false
    },
    openReportModals: (state, action) => {
      state.objectId = action.payload
      state.reportModal = true
    },
    closeReportModal: state => {
      state.reportModal = false
    },
    openCommentModals: (state, action) => {
      state.commentModal = true
      state.commentLike = action.payload.commentNum
      state.commentData = action.payload.data
      state.userInsIdComment = action.payload.id
    },
    closeCommentModal: state => {
      state.commentModal = false
      state.commentLike = 0
      state.commentData = []
      state.userInsIdComment = ''
      state.userInsCommentList = []
    }
  }
})

export const {
  openModal,
  closeModal,
  openReportModals,
  closeReportModal,
  setPostLikeList,
  appendCommentLikes,
  openCommentModals,
  closeCommentModal,
  setPostCommentList,
  likeClicked,
  likeUnclicked,
  showDeletionModal,
  hideDeletionModal,
  setFilterInfoModalVisible,
  setCommentLikesFromIds
} = postActions.actions

export const openLikeModal = data => {
  return async (dispatch, state) => {
    if (state().auth.isAuthenticated === false) {
      dispatch(gotoLogIn())
      return false
    }
    if (state().auth.user) {
      return false
    }
    return dispatch(postActions.actions.openModal(data))
  }
}

export const openReportModal = data => {
  return async (dispatch, state) => {
    if (state().auth.isAuthenticated === false) {
      dispatch(gotoLogIn())
      return false
    }
    if (state().auth.user) {
      return false
    }
    return dispatch(postActions.actions.openReportModals(data))
  }
}

export const openCommentModal = (data, id) => {
  return async (dispatch, state) => {
    if (state().auth.isAuthenticated === false) {
      dispatch(gotoLogIn())
      return false
    }
    if (state().auth.user) {
      return false
    }
    return dispatch(postActions.actions.openCommentModals(data, id))
  }
}

export const sendReport = (userInspirationId, reason) => {
  return async (dispatch, state) => {
    try {
      await fetch(ENDPOINTS.CREATE_REPORT, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders(),
        body: JSON.stringify({ userInspirationId, reason })
      })
      dispatch(postActions.actions.closeReportModal())
    } catch (error) {
      // handle error
    }
  }
}

export const sendCommentReport = (userInspirationCommentId, reason) => {
  return async (dispatch, state) => {
    try {
      await fetch(ENDPOINTS.CREATE_REPORT, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders(),
        body: JSON.stringify({ userInspirationCommentId, reason })
      })
      dispatch(postActions.actions.closeReportModal())
    } catch (error) {
      // handle error
    }
  }
}

export const deleteComment = createApiAction({
  endpoint: ENDPOINTS.DELETE_COMMENT,
  mapPropsToBody: ({ userInspirationCommentId, userInspirationId }) => ({
    userInspirationCommentId,
    userInspirationId
  }),
  handler: ({ props, dispatch }) => {
    dispatch(
      getUserInsComments(
        props.userInspirationId,
        0,
        [],
        [],
        props.completionCallback
      )
    )
  }
})

export const deletePost = createApiAction({
  endpoint: ENDPOINTS.DELETE_POST,
  mapPropsToBody: ({ postId }) => ({
    userInspirationObjectIds: [postId]
  }),
  handler: ({ dispatch }) => {
    dispatch(fetchFeed('inspiration')({ status: FEED_RESET }))
    dispatch(fetchChallenges())
    dispatch(fetchFeed('myEventPlayTab')({ status: FEED_RESET }))
  }
})

export const updatePost = createApiAction({
  endpoint: ENDPOINTS.UPDATE_POST,
  mapPropsToBody: props => props,
  handler: ({ dispatch, props }) => {
    dispatch(updatePostStore(props))
  }
})

export const getUserInsLikes = (userInspirationId, skip = 0, previous = []) => {
  return async (dispatch, state) => {
    if (userInspirationId.length > 0) {
      try {
        let response = await fetch(ENDPOINTS.GET_SEND_LIKES, {
          method: 'POST',
          mode: 'cors',
          headers: parseHeaders(),
          body: JSON.stringify({ userInspirationId, skip })
        })
        let { result } = await response.json()
        if (result && result.likes && result.likes.length)
          return dispatch(
            getUserInsLikes(
              userInspirationId,
              skip + 10,
              previous.concat(result.likes)
            )
          )
        else {
          return dispatch(postActions.actions.setPostLikeList(previous))
        }
      } catch (error) {
        // handle error
      }
    }
  }
}

export const getUserInsComments = (
  userInspirationId,
  skip = 0,
  previous = [],
  previousLikes = [],
  onFinished = () => {}
) => {
  return async (dispatch, state) => {
    if (userInspirationId.length > 0) {
      try {
        let response = await fetch(ENDPOINTS.GET_COMMENTS, {
          method: 'POST',
          mode: 'cors',
          headers: parseHeaders(),
          body: JSON.stringify({ userInspirationId, skip })
        })

        let { result } = await response.json()

        if (
          result &&
          result.userInspirationComments &&
          result.userInspirationComments.length
        )
          return dispatch(
            getUserInsComments(
              userInspirationId,
              skip + 10,
              previous.concat(result.userInspirationComments),
              previousLikes.concat(result.currentUserLikes),
              onFinished
            )
          )
        else {
          dispatch(postActions.actions.setPostCommentList(previous))
          dispatch(postActions.actions.setCommentLikes(previousLikes))
          onFinished()
        }
      } catch (error) {
        // handle error
      }
    }
  }
}

export const getUserInsCommentReplies = async (
  userInspirationCommentId,
  skip = 0,
  previous = [],
  previousLikes = []
) => {
  if (userInspirationCommentId.length > 0) {
    try {
      let response = await fetch(ENDPOINTS.GET_REPLIES, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders(),
        body: JSON.stringify({ userInspirationCommentId, skip })
      })

      let { result } = await response.json()

      if (
        result &&
        result.userInspirationComments &&
        result.userInspirationComments.length
      )
        return await getUserInsCommentReplies(
          userInspirationCommentId,
          skip + 10,
          previous.concat(result.userInspirationComments),
          previousLikes.concat(result.currentUserLikes)
        )
      else {
        return {
          userInspirationComments: previous,
          currentUserLikes: previousLikes
        }
      }
    } catch (error) {
      // handle error
    }
  }
}

export const updateUserInspirationById = createApiAction({
  endpoint: GET_USER_INSPIRATION,
  mapPropsToBody: prop => ({ objectId: prop }),
  handler: ({ dispatch, result }) => {
    dispatch(updateFeedElement('inspiration')(result.result.userInspiration))
    dispatch(updateFeedElement('saved')(result.result.userInspiration))
  }
})

export const sendUserInsComment = createApiAction({
  endpoint: ENDPOINTS.SEND_COMMENTS,
  mapPropsToBody: ({
    userInspirationId,
    text,
    clientCreatedAt,
    repliedToCommentId
  }) => ({ userInspirationId, text, clientCreatedAt, repliedToCommentId }),
  handler: ({ dispatch, props }) => {
    if (props.onSendCallback) props.onSendCallback()
    dispatch(getUserInsComments(props.userInspirationId))
    dispatch(updateUserInspirationById(props.userInspirationId))
  }
})

export default postActions.reducer
