import styled from 'styled-components'

export const OverlayWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  z-index: 1000;

  @media (max-width: 1000px), (max-height: 648px) {
    margin-top: ${props => (props.fullscreen ? '0' : '60px')};
  }

  @media screen and (min-width: 786px) {
    z-index: 1001;
  }
`

export const OverlayContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.fullscreen ? '100vh' : 'calc(100vh - 130px)')};

  @media only screen and (max-width: 600px) {
    justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
    align-items: ${props => (props.centered ? 'center' : 'flex-start')};
  }

  ${props =>
    props.bottomSheet &&
    `
    @media (max-width: 768px) {
      align-items: flex-end;
    }
  `}

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`
