import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  DO_PURCHASE,
  DO_PURCHASE_ITEM,
  DO_PURCHASE_MYDECOR,
  GET_ALL_PURCHASES
} from '../../constants/ApiEndpoints'
import getItemPrice from '../../utils/getItemPrice'
import { api } from '../../utils/fetchUtils'

export const fetchPurchaseKeys = createAsyncThunk(
  'cart/fetchPurchaseKeys',
  async (data, { getState }) => {
    let response = await api(GET_ALL_PURCHASES, JSON.stringify({}))

    //remove market place items and remaing of mydecor because everything is one time useage now
    //const d = { ...response.result.remainingItemUses, ...response.result.remainingMpUses, ...response.result.remainingMyDecorUses }

    const d = {
      ...response.result.remainingItemUses,
      ...response.result.remainingMyDecorUses
    }

    for (const key in d) {
      d[key] = {
        initialRemaining: d[key],
        numRemaining: d[key],
        numCoins: 0,
        purchasedIds: [],
        unpaidIds: []
      }
    }

    return d
  }
)

export const purchaseItem = createAsyncThunk(
  'cart/purchaseItem',
  async (data, { getState }) => {
    const { itemReference } = getState().canvas

    if (data.type === 'm') {
      const body = {
        marketProductIds: [data.id],
        isMember: data.isMember
      }

      await api(DO_PURCHASE, JSON.stringify(body))
    }

    if (data.type === 'u') {
      const body = {
        userProductIds: [data.id],
        isMember: data.isMember
      }

      await api(DO_PURCHASE_MYDECOR, JSON.stringify(body))
    }

    const { finalPrice: price } = getItemPrice(data)

    // const isLimitedDiscount =
    //   data?.limitedDiscount &&
    //   moment(data?.limitedDiscount?.expiresAt).isAfter(moment())

    // const price = isLimitedDiscount
    //   ? data?.limitedDiscount?.numCoins
    //   : data.discountPrice || data.numCoins

    if (data.type === 'i') {
      const body = {
        items: [
          {
            itemId: data.id,
            price: data.isMember ? 0 : price
          }
        ],
        isMember: data.isMember
      }
      await api(DO_PURCHASE_ITEM, JSON.stringify(body))
    }

    if (data.type === 'item') {
      const body = {
        items: [
          {
            itemId: data.objectId,
            price: data.isMember ? 0 : price
          }
        ],
        isMember: data.isMember
      }
      await api(DO_PURCHASE_ITEM, JSON.stringify(body))
    }

    return { [data.id]: itemReference[data.id] }
  }
)

export const purchaseAllItems = createAsyncThunk(
  'cart/purchaseAllItems',
  async (isMember = false, { dispatch, getState }) => {
    const { unpaid } = getState().cart
    const targets = getState().canvas.targets

    for (let index = 0; index < unpaid.length; index++) {
      const item = unpaid[index]

      const itemTarget = targets.find(t => t.item.objectId === item.objectId)

      if (itemTarget.visible !== false) {
        await dispatch(purchaseItem({ ...item, isMember }))
      }
    }

    const { itemReference } = getState().canvas

    dispatch(fetchPurchaseKeys()).then(s => {
      dispatch(updateCart(itemReference))
    })

    return
  }
)
/*
1. Call to get list of purchase
2. Call updatePurchases to create initial objs
3. Call updateCart to loop through nodes in scene and update purchase and unpaid

*/

const cart = createSlice({
  name: 'cart',
  initialState: {
    cart: [],
    cartReference: {},
    unpaid: [],
    items: {},
    uses: {},
    justPurchased: null
  },
  reducers: {
    updatePurchases: (state, action) => {
      const d = action.payload
      const obj = {}
      d.forEach(e => {
        if (e.marketProduct) {
          obj[e.marketProduct.objectId] = {
            numRemaining: e.numRemaining,
            numCoins: e.marketProduct.numCoins || 0,
            purchasedIds: [],
            unpaidIds: []
            //quantity: e.numRemaining
          }
        }
        if (e.item) {
          obj[e.item._id] = {
            numRemaining: e.numRemaining,
            numCoins: e.item.numCoins || 0,
            purchasedIds: [],
            unpaidIds: []
            //quantity: e.numRemaining
          }
        }
        if (e.className === 'UserProduct') {
          obj[e.objectId] = {
            numRemaining: 0,
            numCoins: e.numCoins || 0,
            purchasedIds: [],
            unpaidIds: []
            //quantity: e.numRemaining
          }
        }
      })

      if (Object.keys(obj).length === 0 && obj.constructor === Object) return

      state.cartReference = { ...state.cartReference, ...obj }
    },
    updateCart: (state, action) => {
      if (!action.payload) return

      const nodes = action.payload

      var ref = state.uses
      var unpaid = []

      for (const c in ref) {
        ref[c].numRemaining = ref[c].initialRemaining || 0
        ref[c].purchasedIds = []
        ref[c].unpaidIds = []
        ref[c].numCoins = ref[c].numCoins || 0
      }

      for (const [key, value] of Object.entries(nodes)) {
        var d = Object.assign({}, ref[key])

        // Item not found in list of purchases
        if (Object.keys(d).length === 0 && d.constructor === Object) {
          const entry = {
            numRemaining: 0,
            purchasedIds: [],
            unpaidIds: [],
            numCoins: value.item.numCoins || 0
          }

          value.ids.forEach(u => {
            entry.unpaidIds.push(u)
            var it = Object.assign({}, value.item)
            /*
            if (entry.unpaidIds.length % 6 !== 0 && entry.unpaidIds.length > 1) {
              it.numCoins = 0
            }*/
            unpaid.push(it)
          })

          continue
        }

        //Now update paid/unpaid and numremaining
        for (let i = 0; i < value.ids.length; i++) {
          if (d.numRemaining > 0) {
            d.numRemaining = d.numRemaining - 1
            d.purchasedIds.push(value.ids[i])
          } else {
            d.unpaidIds.push(value.ids[i])
            var it = Object.assign({}, value.item)
            /*
            if (d.unpaidIds.length % 6 !== 0 && d.unpaidIds.length > 1) {
              it.numCoins = 0
            }
            */
            unpaid.push(it)
          }
        }

        ref[key] = d
      }

      state.unpaid = unpaid
    }
  },
  extraReducers: {
    [fetchPurchaseKeys.fulfilled]: (state, action) => {
      if (!action.payload) return
      const data = action.payload
      //state.uses = {...data.remainingItemUses, ...data.remainingMpUses}
      state.uses = data
    },
    [purchaseItem.fulfilled]: (state, action) => {
      if (!action.payload) return

      state.justPurchased = {
        ...state.justPurchased,
        ...action.payload
      }
    }
  }
})

export const { updatePurchases, updateCart } = cart.actions

export default cart.reducer
