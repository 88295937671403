const KEY_LOCAL_DESIGN = 'keylocaldesign'

export const checkStoreDesign = (id, template) => {
  const design = localStorage.getItem(KEY_LOCAL_DESIGN)
  if (design) {
    const data = JSON.parse(design)
    if (data.id === id) {
      //console.log("[CHECK] LOCAL DESIGN FOUND")
      if (id === 'new') {
        if (data.template === template) return true
        else return false
      }
      return true
    }
    //console.log("[CHECK] NO LOCAL DESIGN FOUND")
    return false
  } else {
    //console.log("[CHECK] NO LOCAL DESIGN FOUND")
    return false
  }
}

export const getStoredDesign = id => {
  const design = localStorage.getItem(KEY_LOCAL_DESIGN)
  if (design) {
    const data = JSON.parse(design)
    if (data.id === id) return data
    return null
  } else return null
}

export const createStoredDesign = (
  id,
  template,
  nodes = [],
  challengeId = undefined
) => {
  //console.log("[CREATE] LOCAL STORAGE")
  const obj = {
    isReload: false,
    id,
    template,
    nodes,
    challengeId
  }
  localStorage.setItem(KEY_LOCAL_DESIGN, JSON.stringify(obj))
}

export const updateStoredDesign = (template, nodes) => {
  //console.log("[UPDATE] LOCAL STORAGE")
  const design = localStorage.getItem(KEY_LOCAL_DESIGN)
  if (design) {
    const data = JSON.parse(design)
    if (data) {
      //console.log("[UPDATE] LOCAL DESIGN FOUND")
      const obj = {
        isReload: false,
        id: data.id,
        template,
        nodes
      }
      //console.log(nodes)
      localStorage.setItem(KEY_LOCAL_DESIGN, JSON.stringify(obj))
    } else {
      //console.log("[UPDATE] NO LOCAL DESIGN FOUND")
    }
  } else {
    //console.log("[UPDATE] NO LOCAL DESIGN FOUND")
  }
}

export const deleteStoredDesign = () => {
  localStorage.removeItem(KEY_LOCAL_DESIGN)
}

export const setReloadStoredDesign = () => {
  const design = localStorage.getItem(KEY_LOCAL_DESIGN)
  if (design) {
    const data = JSON.parse(design)
    data.isReload = true
    localStorage.setItem(KEY_LOCAL_DESIGN, JSON.stringify(data))
  }
}

///---------------------------------------------
///---------------------------------------------
///---------------------------------------------
///---------------------------------------------
///---------------------------------------------
///---------------------------------------------

export const checkAutoSave = (id, template) => {
  const design = localStorage.getItem(KEY_LOCAL_DESIGN)
  if (design) {
    const data = JSON.parse(design)
    if (data.id === id) {
      if (id === 'new') {
        const tid = data.template.split('?')[0]
        if (tid === template) return true
        else return false
      }
      return true
    }
    return false
  } else return false
}

export const startAutoSave = (
  id,
  template,
  designId,
  targets = [],
  challengeId = undefined
) => {
  //console.log('[designStore] Start Auto Save')
  localStorage.removeItem(KEY_LOCAL_DESIGN)
  const obj = {
    isReload: false,
    id,
    template,
    targets,
    challengeId,
    designId
  }
  localStorage.setItem(KEY_LOCAL_DESIGN, JSON.stringify(obj))
}

export const getAutoSave = (id = null) => {
  const design = localStorage.getItem(KEY_LOCAL_DESIGN)
  if (design) {
    const data = JSON.parse(design)
    if (data.id === id) return data
    else if (id === null) return data
    return null
  } else return null
}

export const saveAutoSave = (template, targets) => {
  //console.log('[designStore] Save Auto Save')
  const design = localStorage.getItem(KEY_LOCAL_DESIGN)
  if (design) {
    const data = JSON.parse(design)
    if (data) {
      //console.log('[UPDATE] LOCAL DESIGN FOUND')
      const obj = {
        isReload: false,
        id: data.id,
        template,
        targets,
        designId: data.designId || data.id
      }
      localStorage.setItem(KEY_LOCAL_DESIGN, JSON.stringify(obj))
    } else {
      //console.log('[UPDATE] NO LOCAL DESIGN FOUND')
    }
  } else {
    //console.log('[UPDATE] NO LOCAL DESIGN FOUND')
  }
}

export const clearAutoSave = () => {
  localStorage.removeItem(KEY_LOCAL_DESIGN)
}
