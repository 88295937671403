import React from 'react'
import MobileModal from '../MobileModal'
import { MobileSearchPopupWrapper } from './MobileSearchPopup.styles'
import QuickSearchContent from '../QuickSearchContent'
import { SEARCH_MODES } from '../../constants/SearchConstants'

export const MobileSearchPopup = ({ onClose, searchQuery }) => {
  return (
    <MobileModal onClose={onClose} respectHeader>
      <MobileSearchPopupWrapper>
        <QuickSearchContent
          searchMode={SEARCH_MODES.ALL}
          onClose={onClose}
          searchQuery={searchQuery}
        />
      </MobileSearchPopupWrapper>
    </MobileModal>
  )
}
