import * as React from 'react'
import {
  BlogCard,
  BlogPostCoverImage,
  BlogPostTitle,
  PopularCard,
  PostIcon,
  SearchCardsGrid,
  SearchResultLine,
  SearchResultsList,
  SearchSubtitle,
  SearchTitleLine,
  UserIcon
} from './QuickSearchContent.styles'
import { useRecommendedSearches } from '../../queries/search'
import { useBlogSearch } from '../../queries/blog'
import Link from 'next/link'
import { LoadingIndicator } from '../Loading'

export const SearchSuggestions = ({ onClose, searchQuery }) => {
  const suggestions = useRecommendedSearches(searchQuery)
  const blogSearchResults = useBlogSearch(searchQuery)

  return (
    <>
      {searchQuery && <LoadingIndicator loading={suggestions.isLoading} />}
      {searchQuery && !suggestions.isLoading && (
        <>
          {suggestions?.data?.hashtags && suggestions.data.hashtags.length > 0 && (
            <>
              <SearchTitleLine>
                <SearchSubtitle>Hashtags</SearchSubtitle>
              </SearchTitleLine>
              <SearchCardsGrid inline={suggestions.data.hashtags < 3}>
                {suggestions.data.hashtags.map(item => {
                  const cleanTitle = item.title.replaceAll('#', '').trim()

                  return (
                    <Link key={item.objectId} href={`/hashtag/${cleanTitle}`}>
                      <PopularCard
                        onClick={onClose}
                        background={item.previewImageUrl}
                      >
                        <span>{`#${cleanTitle}`}</span>
                      </PopularCard>
                    </Link>
                  )
                })}
              </SearchCardsGrid>
            </>
          )}
          {suggestions?.data?.userInspirations &&
            suggestions.data.userInspirations.length > 0 && (
              <>
                <SearchTitleLine>
                  <SearchSubtitle>Inspirations</SearchSubtitle>
                </SearchTitleLine>
                <SearchResultsList>
                  {suggestions.data.userInspirations.map(post => (
                    <Link key={post.objectId} href={`/design/${post.objectId}`}>
                      <SearchResultLine onClick={onClose}>
                        <PostIcon />
                        <span>{post.title}</span>
                      </SearchResultLine>
                    </Link>
                  ))}
                </SearchResultsList>
              </>
            )}
          {suggestions?.data?.users && suggestions.data.users.length > 0 && (
            <>
              <SearchTitleLine>
                <SearchSubtitle>Users</SearchSubtitle>
              </SearchTitleLine>
              <SearchResultsList>
                {suggestions.data.users.map(user => (
                  <Link key={user.objectId} href={`/dm/${user.objectId}`}>
                    <SearchResultLine onClick={onClose}>
                      <UserIcon />
                      <span>{user.uniqueDisplayName}</span>
                    </SearchResultLine>
                  </Link>
                ))}
              </SearchResultsList>
            </>
          )}
          {blogSearchResults.data && blogSearchResults.data.length > 0 && (
            <>
              <SearchTitleLine>
                <SearchSubtitle>In DecorMatters' Blog</SearchSubtitle>
              </SearchTitleLine>
              <SearchCardsGrid inline={blogSearchResults.data.length < 3}>
                {blogSearchResults.data.map(post => (
                  <Link key={post.id} href={`/blog/${post.attributes.slug}`}>
                    <BlogCard
                      marginRight={blogSearchResults.data.length < 3}
                      onClick={onClose}
                    >
                      <BlogPostCoverImage
                        src={post.attributes.cover_image.data.attributes.url}
                        alt=""
                      />
                      <BlogPostTitle>{post.attributes.title}</BlogPostTitle>
                    </BlogCard>
                  </Link>
                ))}
              </SearchCardsGrid>
            </>
          )}
        </>
      )}
    </>
  )
}
