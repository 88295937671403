import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  GET_CHALLENGES,
  GET_DID_JOIN_USER_EVENT,
  GET_JOIN_CHALLENGE,
  GET_OTHER_USER
} from '../../constants/ApiEndpoints'
import { api, checkFeedStatus } from '../../utils/fetchUtils'
import { FEED_RESET } from '../constants'

const challengePageLimit = 15

export const fetchChallenges = createAsyncThunk(
  'challenge/fetchChallenges',
  async (data, { getState }) => {
    //console.log("[challengeReducer][fetchChallenges]")
    const { challenge, challengePage, challengePageEnd } = getState().challenge

    const nextPage = checkFeedStatus(
      data,
      challenge.length,
      challengePage,
      challengePageEnd
    )
    if (nextPage === -1) return null

    const body = {
      skip: nextPage * challengePageLimit,
      pageLimit: challengePageLimit,
      filter: 'active'
    }

    let response = await api(GET_CHALLENGES, JSON.stringify(body))

    const status = response.result.userContestStatuses
      ? response.result.userContestStatuses
      : {
          joined: [],
          started: []
        }

    return {
      status,
      data: response.result.designContests,
      page: nextPage
    }
  }
)

export const fetchChallengeDraftId = createAsyncThunk(
  'DesignerDesigns/fetchChallengeDraft',
  async (designContestId, { getState }) => {
    const body = {
      designContestId
    }
    let response = await api(GET_JOIN_CHALLENGE, JSON.stringify(body))
    if (response.code) return response
    return response.result
  }
)

export const fetchDidJoinUserEvent = createAsyncThunk(
  'DesignerDesigns/fetchDidJoinUserEvent',
  async (userEventId, { getState }) => {
    const body = {
      userEventId
    }
    let response = await api(GET_DID_JOIN_USER_EVENT, JSON.stringify(body))

    if (response.code) return response

    return response.result
  }
)

const challenge = createSlice({
  name: 'challenge',
  initialState: {
    challenge: [],
    challengePage: 0,
    challengePageEnd: false,
    challengeError: null,
    loading: 'pending',
    status: {
      joined: [],
      started: []
    }
  },
  reducers: {},
  extraReducers: {
    [fetchChallenges.pending]: (state, action) => {
      state.challengeError = null
      if (action.meta && action.meta.arg === FEED_RESET) {
        state.challenge = []
        state.challengePage = 0
        state.challengePageEnd = false
      }
      if (state.challengePage === 0) state.loading = 'pending'
    },
    [fetchChallenges.fulfilled]: (state, action) => {
      state.loading = 'idle'
      if (!action.payload) return

      state.status = action.payload.status
      if (action.payload.data.length < challengePageLimit)
        state.challengePageEnd = true
      else state.challengePageEnd = false
      state.challenge = [...state.challenge, ...action.payload.data]
      state.challengePage = action.payload.page + 1

      if (state.challenge.length === 0) state.challengeError = 'empty'
      else state.challengeError = null
    }
  }
})

export default challenge.reducer
