import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import arrowImg from '../../../assets/arrow-right.svg'
import newUserImg from '../../../assets/new-user-notification.png'
import {
  getNotificationBackgroundColor,
  getNotificationUserEventIcon,
  NOTIFICATION_TYPES,
  PREVIEW_EVENT_NOTIFICATION,
  USER_EVENT_NOTIFICATIONS
} from '../../../constants/NotificationTypeConstants'
import { getUserById, getUserEventById } from '../../../redux/apis'
import {
  getChallengePerkIcon,
  getChallengePerkText,
  getChallengeTypeLogo
} from '../../../utils/challengeTypeLogo'
import formatDate from '../../../utils/formatDate'
import {
  getNoitificationDescription,
  getNoitificationLink,
  getNotificationAction,
  getNotificationBannerDescription,
  getNotificationBannerIllustration
} from '../../../utils/notifications'
import { CirclesBackground } from '../../Backgrounds/CirclesBackground'
import { ReadIndicator } from '../SmallNotification/SmallNotification.styles'
import WinBadgeIcon from '../../../assets/userEvent/winBadge.svg'
import CoinsIcon from '../../../assets/Coins.svg'
import CoinIcon from '../../../assets/coin.svg'
import {
  ArrowButton,
  BadgeIcon,
  BannerAlertText,
  BannerAlertTitle,
  BannerChallengeInfo,
  BannerContainer,
  BannerInfoPoster,
  BannerInfoPosterText,
  BannerNotificationWrapper,
  BannerPoster,
  BannerPosterWrapper,
  BannerTimeText,
  ChallengeAuthor,
  ChallengePerkIcon,
  ChallengePerkLine,
  ChallengePerks,
  ChallengeWinnerInfo,
  ChallengeWinnerInfoColumn,
  InfoPart,
  UserEventAuthor,
  UserEventBannerContainer,
  UserEventBannerIcon,
  WeeklyReportInfo,
  WeeklyReportInfoContainer,
  WeeklyReportTitle,
  WinnerDesignImage,
  WinnersBgImage,
  WinnersGrid,
  WinnerName
} from './BannerNotification.styles'
import pencilImg from '../../../assets/pencil.svg'
import weeklyReportImg from '../../../assets/Weeklyreport.svg'
import ProfileImage from '../../../routes/Profile/components/profile/ProfileImage'
import winnersBg from '../../../assets/userEvent/winnersBg.png'
import Link from 'next/link'

export const BannerNotification = ({
  onClick,
  notification,
  showInfo,
  showIndicator = true,
  userEventLive,
  userEventWinners,
  challengeWinner
}) => {
  const me = useSelector(state => state.profile.me)
  const router = useRouter()
  const dispatch = useDispatch()

  const userEventId = notification?.userEvent?.objectId

  const { data: userEventData } = useQuery(
    ['notification-userevent', userEventId],
    async () => {
      const result = await getUserEventById(userEventId)
      return result
    },
    {
      enabled: Boolean(userEventId)
    }
  )

  const user = userEventData?.user
    ? userEventData?.user
    : notification?.sourceUser

  const WIDE_BANNER_NOTIFICATIONS = [
    NOTIFICATION_TYPES.AVATAR,
    NOTIFICATION_TYPES.RELEASE,
    NOTIFICATION_TYPES.ITEM_BUNDLE_SALE_GLOBAL,
    NOTIFICATION_TYPES.GIFT
  ]

  const ChallengeIcon = getChallengeTypeLogo(notification?.designContest)
  const notificationLink = getNoitificationLink(notification)
  const Wrapper = notificationLink ? Link : 'div'

  return (
    <Wrapper href={notificationLink}>
      <BannerContainer
        addSpacing={!showIndicator}
        onClick={() => {
          onClick()
          getNotificationAction(notification, dispatch, router)
        }}
      >
        <>
          {showIndicator && (
            <ReadIndicator active={notification.status !== 'read'} />
          )}
          <BannerNotificationWrapper>
            <BannerPosterWrapper
              tall={
                notification?.type === NOTIFICATION_TYPES.NEW_USER ||
                notification?.type === NOTIFICATION_TYPES.WEEKLY_REPORT ||
                (notification?.type?.toLowerCase()?.includes('challenge') &&
                  notification.type !== NOTIFICATION_TYPES.CHALLENGE_REWARD) ||
                userEventWinners ||
                userEventLive ||
                challengeWinner
              }
            >
              {!userEventWinners &&
                notification?.type !== NOTIFICATION_TYPES.NEW_USER && (
                  <CirclesBackground
                    background={getNotificationBackgroundColor(
                      notification.type,
                      notification?.designContest
                    )}
                  />
                )}
              {notification?.type === NOTIFICATION_TYPES.NEW_USER && (
                <BannerPoster src={newUserImg} alt="" />
              )}
              {challengeWinner && (
                <ChallengeWinnerInfo>
                  <ChallengeWinnerInfoColumn>
                    <ProfileImage
                      size={68}
                      showLevel
                      small
                      key={notification?.userInspiration?.objectId}
                      user={notification?.userInspiration?.user}
                    />
                    <span>
                      {notification?.userInspiration?.user?.uniqueDisplayName}
                    </span>
                  </ChallengeWinnerInfoColumn>
                  <WinnerDesignImage
                    src={notification?.userInspiration?.idea?.cfThumbImageUrl}
                    alt=""
                  />
                </ChallengeWinnerInfo>
              )}
              {userEventWinners && <WinnersBgImage src={winnersBg} />}
              {WIDE_BANNER_NOTIFICATIONS.includes(notification?.type) && (
                <WinnersBgImage src={notification.backdropImageUrl} />
              )}
              {showInfo &&
                !WIDE_BANNER_NOTIFICATIONS.includes(notification?.type) && (
                  <BannerInfoPoster>
                    <BannerInfoPosterText>
                      {getNotificationBannerDescription(notification)}
                    </BannerInfoPosterText>
                    {!PREVIEW_EVENT_NOTIFICATION.includes(notification.type) &&
                      getNotificationBannerIllustration(notification, me)}
                  </BannerInfoPoster>
                )}
              {!USER_EVENT_NOTIFICATIONS.includes(notification.type) &&
                notification.type === NOTIFICATION_TYPES.WEEKLY_REPORT && (
                  <WeeklyReportInfoContainer>
                    <WeeklyReportInfo>
                      <img src={pencilImg} alt="" />
                      <WeeklyReportTitle>Weekly Report</WeeklyReportTitle>
                    </WeeklyReportInfo>
                    <img src={weeklyReportImg} alt="" />
                  </WeeklyReportInfoContainer>
                )}
              {!USER_EVENT_NOTIFICATIONS.includes(notification.type) &&
                !showInfo &&
                notification?.designContest && (
                  <BannerChallengeInfo>
                    {ChallengeIcon && <ChallengeIcon />}
                    <ChallengePerks>
                      {notification.designContest.rewardInfo.map(
                        (reward, idx) => (
                          <ChallengePerkLine key={idx}>
                            <ChallengePerkIcon
                              src={getChallengePerkIcon(reward)}
                              alt={reward.type}
                            />
                            {getChallengePerkText(reward)}
                          </ChallengePerkLine>
                        )
                      )}
                    </ChallengePerks>
                  </BannerChallengeInfo>
                )}
              {userEventWinners && (
                <BannerChallengeInfo>
                  <WinnersGrid>
                    {notification?.users?.map(winner => (
                      <div>
                        <ProfileImage
                          size={68}
                          showLevel
                          small
                          key={winner.objectId}
                          user={winner}
                        />
                        <WinnerName>{winner.uniqueDisplayName}</WinnerName>
                      </div>
                    ))}
                  </WinnersGrid>
                </BannerChallengeInfo>
              )}
              {userEventLive && (
                <>
                  <BannerChallengeInfo>
                    <img
                      src={getChallengeTypeLogo(notification?.userEvent)}
                      alt=""
                    />
                    <ChallengePerks>
                      <ChallengeAuthor>
                        <ProfileImage
                          size={48}
                          small
                          showLevel
                          user={user}
                          userLevel={
                            userEventData?.user?.userLevel || user?.level
                          }
                        />
                        <UserEventAuthor>
                          {user?.uniqueDisplayName || notification?.objectId}
                        </UserEventAuthor>
                      </ChallengeAuthor>
                      <ChallengePerkLine>
                        <ChallengePerkIcon src={CoinIcon} />
                        Earn 100 Dcoins
                      </ChallengePerkLine>
                      <ChallengePerkLine>
                        <ChallengePerkIcon src={CoinsIcon} />
                        Winners: 2K Dcoins
                      </ChallengePerkLine>
                      <ChallengePerkLine>
                        <ChallengePerkIcon src={WinBadgeIcon} />
                        Winners: Badge
                      </ChallengePerkLine>
                    </ChallengePerks>
                  </BannerChallengeInfo>
                  <BannerPoster src={userEventData?.inspirationImage} alt="" />
                </>
              )}
              {!USER_EVENT_NOTIFICATIONS.includes(notification.type) &&
                !showInfo &&
                (notification?.designContest?.backdropImageFile?.url ||
                  notification?.designContest?.thumbImageFile?.url) && (
                  <BannerPoster
                    src={
                      notification?.designContest?.thumbImgUrl ||
                      notification?.designContest?.thumbImageFile?.url
                    }
                    alt=""
                  />
                )}
              {PREVIEW_EVENT_NOTIFICATION.includes(notification.type) && (
                <UserEventBannerContainer>
                  {notification.type !== NOTIFICATION_TYPES.EVENT_WINNER && (
                    <img
                      src={
                        notification?.userEvent?.inspirationImage ||
                        notification?.designContest?.thumbImgUrl
                      }
                      alt=""
                    />
                  )}
                  {notification.type === NOTIFICATION_TYPES.EVENT_WINNER && (
                    <BadgeIcon src={WinBadgeIcon} alt="" />
                  )}
                  <UserEventBannerIcon
                    src={getNotificationUserEventIcon(notification.type)}
                    coins={[
                      NOTIFICATION_TYPES.USER_EVENT_REWARD,
                      NOTIFICATION_TYPES.EVENT_WINNER,
                      NOTIFICATION_TYPES.CHALLENGE_REWARD,
                      NOTIFICATION_TYPES.USER_EVENT_REWARD
                    ].includes(notification.type)}
                    alt=""
                  />
                </UserEventBannerContainer>
              )}
            </BannerPosterWrapper>
            <InfoPart>
              <div>
                {notification.title && (
                  <BannerAlertTitle>{notification.title}</BannerAlertTitle>
                )}
                {(challengeWinner ||
                  notification.type === NOTIFICATION_TYPES.NEW_USER) &&
                  notification?.alert && (
                    <BannerAlertTitle>{notification?.alert}</BannerAlertTitle>
                  )}
                {userEventLive && (
                  <BannerAlertTitle>New event is live!</BannerAlertTitle>
                )}
                {userEventWinners && (
                  <BannerAlertTitle>
                    Event winners have been chosen!
                  </BannerAlertTitle>
                )}
                <BannerAlertText>
                  {getNoitificationDescription(notification)}
                </BannerAlertText>
                <BannerTimeText>
                  {formatDate(
                    notification.type === NOTIFICATION_TYPES.NEW_USER
                      ? me?.createdAt
                      : notification.createdAt
                  )}
                </BannerTimeText>
              </div>
              <ArrowButton>
                <img src={arrowImg} alt="" />
              </ArrowButton>
            </InfoPart>
          </BannerNotificationWrapper>
        </>
      </BannerContainer>
    </Wrapper>
  )
}
