import React from 'react'
import { MobileModalBackground, ModalTouchable } from './MobileModal.styles'

export const MobileModal = ({
  children,
  onClose,
  horizontal,
  respectHeader,
  hideBgOnDesktop
}) => {
  return (
    <MobileModalBackground
      respectHeader={respectHeader}
      horizontal={horizontal}
    >
      {children}
      <ModalTouchable hideOnDesktop={hideBgOnDesktop} onClick={onClose} />
    </MobileModalBackground>
  )
}
