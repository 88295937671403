import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../redux'
import FloatingButtons from '../FloatingButtons'
import { PageInsideWrapper } from './PageInsideWrapper'
import { GlobalStyle } from './PageWrapper.styles'
import { DecorProvider } from '../../context/decor'

export const PageWrapper = ({ children, disableModals, disableOverscroll, inApp }) => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <DecorProvider>
        <PageInsideWrapper inApp={inApp} disableModals={disableModals} disableOverscroll={disableOverscroll}>
          {children}
        </PageInsideWrapper>
        <FloatingButtons />
      </DecorProvider>
    </Provider>
  )
}
