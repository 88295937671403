import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DEV_BASE, GET_BOOKMARKS } from '../../constants/ApiEndpoints'
import { api } from '../../utils/fetchUtils'

export const fetchSaved = createAsyncThunk(
  'userSaved/fetchSaved',
  async data => {
    let response = await api(GET_BOOKMARKS)
    return response.result
  }
)

//-------------------------------------------------------------------------------------

export const setFavorite = createAsyncThunk(
  'userSaved/setFavorite',
  async data => {
    const body = { userInspirationIds: [data] }
    let response = await api(
      DEV_BASE + 'saveUserInspirations1',
      JSON.stringify(body)
    )
    return response.result
  }
)

export const setUnfavorite = createAsyncThunk(
  'userSaved/setUnfavorite',
  async data => {
    const body = { userInsIds: [data] }
    let response = await api(
      DEV_BASE + 'archiveSavedUserInspirations1',
      JSON.stringify(body)
    )
    return response.result
  }
)

//-------------------------------------------------------------------------------------

export const setLike = createAsyncThunk('userSaved/setLike', async data => {
  const body = { userInspirationObjectId: data }
  let response = await api(
    DEV_BASE + 'likeUserInspiration2',
    JSON.stringify(body)
  )
  return response.result
})

export const setUnlike = createAsyncThunk('userSaved/setUnlike', async data => {
  const body = { userInspirationObjectId: data }
  let response = await api(
    DEV_BASE + 'unlikeUserInspiration1',
    JSON.stringify(body)
  )
  return response.result
})

const userSaved = createSlice({
  name: 'userSaved',
  initialState: {
    savedFollowing: {},
    savedPGC: {},
    savedBlogs: {},
    savedDesigns: {},
    savedItems: {},
    savedLikes: {}
  },
  reducers: {
    quickSetFavorite: (state, action) => {
      if (!action.payload) return

      state.savedDesigns = {
        ...state.savedDesigns,
        [action.payload]: action.payload
      }
    },
    quickSetUnfavorite: (state, action) => {
      if (!action.payload) return
      delete state.savedDesigns[action.payload]
    },
    setSavedLikes: (state, action) => {
      if (!action.payload) return

      const savedLikes = action.payload.reduce((m, o) => {
        m[o.userInspiration.objectId] = o.userInspiration.objectId
        return m
      }, {})

      state.savedLikes = {
        ...state.savedLikes,
        ...savedLikes
      }
    },
    quickSetLike: (state, action) => {
      if (!action.payload) return

      state.savedLikes = {
        ...state.savedLikes,
        [action.payload]: action.payload
      }
    },
    quickSetUnlike: (state, action) => {
      if (!action.payload) return
      delete state.savedLikes[action.payload]
    }
  },
  extraReducers: {
    [fetchSaved.fulfilled]: (state, action) => {
      if (!action.payload) return
      const data = action.payload

      if (data.followingIds) {
        state.savedFollowing = data.followingIds.reduce((m, o) => {
          m[o] = o
          return m
        }, {})
      }

      if (data.userInspirationIds) {
        const inspire = data.userInspirationIds
        if (inspire.blogIds) {
          state.savedBlogs = inspire.blogIds.reduce((m, o) => {
            m[o] = o
            return m
          }, {})
        }
        if (inspire.designIds) {
          state.savedDesigns = inspire.designIds.reduce((m, o) => {
            m[o] = o
            return m
          }, {})
        }
        if (inspire.pgcIds) {
          state.savedPGC = inspire.pgcIds.reduce((m, o) => {
            m[o] = o
            return m
          }, {})
        }
      }

      if (data.wishlistIds) {
        const wish = data.wishlistIds
        if (wish.itemIds) {
          state.savedBlogs = wish.itemIds.reduce((m, o) => {
            m[o] = o
            return m
          }, {})
        }
      }
    }
  }
})

export const {
  quickSetFavorite,
  quickSetUnfavorite,
  quickSetLike,
  quickSetUnlike,
  setSavedLikes
} = userSaved.actions
export default userSaved.reducer
