import React from 'react'
import { GetAppButtonWrapper, IconWrapper } from './GetAppButton.styles'
import dmIcon from '../../assets/dmIconCircle.png'

export const GetAppButton = () => {
  return (
    <GetAppButtonWrapper
      onClick={() => {
        window.open('https://decormatters.app.link/download')
      }}
    >
      <IconWrapper>
        <img src={dmIcon} alt="" />
      </IconWrapper>
      <span>Get the app</span>
    </GetAppButtonWrapper>
  )
}
