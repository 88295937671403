import React, { useEffect, useState } from 'react'
import { DEVELOPMENT, PRODUCTION } from '../../constants/ApiEndpoints'
import { DevToolsButton, DevToolsWrapper } from './DevTools.styles'

export const DevTools = () => {
  const [visible, setVisible] = useState(false)

  const setEnironment = env => () => {
    localStorage.setItem('env', env)
    window.location.reload()
  }

  useEffect(() => {
    setTimeout(() => setVisible(true), 1000)
  }, [])

  if (!visible) return null

  return (
    <DevToolsWrapper>
      <DevToolsButton onClick={setEnironment(DEVELOPMENT)}>dev</DevToolsButton>
      <DevToolsButton onClick={setEnironment(PRODUCTION)}>prod</DevToolsButton>
    </DevToolsWrapper>
  )
}
