import React from 'react'
import Link from 'next/link'
import {
  HeaderMenuItem,
  HeaderMenuLink,
  HeaderMenuWrapper
} from './HeaderMenu.styles'

export const HeaderSecondaryMenu = ({ secondaryMenu }) => {
  if (!secondaryMenu) return null

  return (
    <HeaderMenuWrapper>
      {secondaryMenu.map(link => {
        return (
          <HeaderMenuItem key={link.path}>
            <Link href={link.path} passHref>
              <HeaderMenuLink active={link.active} exact>
                {link.title}
              </HeaderMenuLink>
            </Link>
          </HeaderMenuItem>
        )
      })}
    </HeaderMenuWrapper>
  )
}
