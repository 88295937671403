import moment from 'moment'
import starterMembershipIcon from '../assets/badge_membership_off.png'
import fullMembershipIcon from '../assets/badge_membership_on.png'

export const getMembershipType = user => {
  const renewDate =
    user?.subscriberRecord &&
    user?.subscriberRecord?.entitlements &&
    user?.subscriberRecord?.entitlements &&
    Object.values(user.subscriberRecord.entitlements).find(item =>
      item?.expires_date ? new Date(item?.expires_date) > new Date() : false
    )

  const isMember =
    Boolean(
      user?.haveMembership ||
        !!user?.membership?.name ||
        (user?.subscriberRecord?.entitlements &&
          Object.keys(user?.subscriberRecord?.entitlements).length > 0 &&
          renewDate)
    ) || false

  return {
    isMember: isMember,
    membershipName:
      user && user?.membership && user?.membership?.name
        ? user.membership.name
        : isMember
        ? 'Member'
        : 'Starter',
    renewDate: renewDate?.expires_date,
    icon: isMember ? fullMembershipIcon : starterMembershipIcon
  }
}
