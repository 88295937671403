import React from 'react'
import { BackgroundContainer, Circle } from './Backgrounds.styles'

export const CirclesBackground = ({ background }) => {
  return (
    <BackgroundContainer background={background}>
      <Circle top="-30px" left="-30px" scale="0.2" />
      <Circle right="-40px" top="-40px" scale="0.5" />
      <Circle left="-50px" bottom="-20px" scale="0.4" />
      <Circle right="-30px" bottom="-30px" scale="0.8" />
    </BackgroundContainer>
  )
}
