import React, { useEffect, useState } from 'react'
import {
  BadgeCounterWrapper,
  Counter,
  CounterContent
} from './BadgeCounter.styles'

export const BadgeCounter = ({ count = 0, children }) => {
  const [prevCount, setPrevCount] = useState(count)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (count > prevCount) {
      setAnimate(true)

      setPrevCount(count)

      setTimeout(() => {
        setAnimate(false)
      }, 1000)
    }
  }, [prevCount, count])

  if (count === 0) return children
  count = count > 99 ? 99 : count

  return (
    <BadgeCounterWrapper>
      <Counter animate={animate}>
        <CounterContent>{count}</CounterContent>
      </Counter>
      {children}
    </BadgeCounterWrapper>
  )
}
