import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ children, tag = 'div', ...props }) => {
  const [portalContainer, setPortalContainer] = useState()

  useEffect(() => {
    const el = document.createElement(tag)
    setPortalContainer(el)
    const root = document.getElementById('__next')
    root.appendChild(el)

    const attributes = Object.keys(props)
    const hasAttributes = attributes?.length > 0

    if (el && hasAttributes) {
      attributes.forEach(attribute => {
        const value = props[attribute]

        if (attribute === 'className') {
          el.setAttribute('class', value)
        }

        if (attribute === 'style') {
          Object.assign(el.style, value)
        } else {
          el.setAttribute(attribute, value)
        }
      })
    }

    return () => root.removeChild(el)
  }, [])

  if (!portalContainer) return null

  return ReactDOM.createPortal(children, portalContainer)
}

export default Portal
