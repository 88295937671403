export const STRIPE_PRODUCT_IDS = {
  prod_Ino8iK8EljnbzC: 'Yearly',
  prod_Ino8JKC8tIhcwN: 'Monthly',
  prod_KCdoYfqaqh5n36: 'Monthly',
  prod_KCdoks0Wzppw7B: 'Weekly',
  prod_IZaaDSPUYnktKT: 'Yearly',
  prod_IZabhSkVQ5UFc0: 'Monthly',
  prod_KCdmwVjG128rwl: 'Monthly',
  prod_KCbQgYyvHavMrp: 'Weekly',
  yearlyMember: 'Yearly',
  monthlyMember: 'Monthly',
  weeklyMember: 'Weekly'
}

export const STRIPE_PLANS = {
  WEEKLY: 'prod_KCbQgYyvHavMrp',
  MONTHLY: 'prod_KCdmwVjG128rwl',
  YEARLY: 'prod_IZaaDSPUYnktKT'
}
