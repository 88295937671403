import dynamic from 'next/dynamic'
import deleteQuestionImg from '../../assets/modal/deleteQuestion.svg'
import ModalOverlay from '../common/ModalOverlay'
import {
  DeleteConfirmationModalContainer,
  DeleteConfirmationText,
  DeleteConfirmationTitle
} from './ConfirmationModal.styles'
import Portal from '../DesignerCommon/portal'

const DMPrimaryButton = dynamic(
  () =>
    import('@decormatters/dm-theme').then(
      component => component.DMPrimaryButton
    ),
  { ssr: false }
)

const DMPrimaryAltButton = dynamic(
  () =>
    import('@decormatters/dm-theme').then(
      component => component.DMPrimaryAltButton
    ),
  { ssr: false }
)

export const ConfirmationModal = ({
  title,
  subtitle,
  onConfirm,
  onCancel,
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel'
}) => {
  return (
    <Portal>
      <ModalOverlay>
        <DeleteConfirmationModalContainer>
          <img src={deleteQuestionImg} alt="" />
          <DeleteConfirmationTitle>{title}</DeleteConfirmationTitle>
          <DeleteConfirmationText>{subtitle}</DeleteConfirmationText>
          <div>
            <DMPrimaryAltButton onClick={onCancel}>
              {cancelButtonTitle}
            </DMPrimaryAltButton>
            <DMPrimaryButton onClick={onConfirm}>
              {confirmButtonTitle}
            </DMPrimaryButton>
          </div>
        </DeleteConfirmationModalContainer>
      </ModalOverlay>
    </Portal>
  )
}
