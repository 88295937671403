import {
  SET_LEADER_BOARD_LOADING,
  SET_LEADER_BOARD_CATEGORIES,
  SET_LEADER_BOARD_MODAL_DATA,
  SET_LEADER_BOARD_DETAILED
} from '../../constants/LeaderBoardConstants'

const initialState = {
  categories: {
    monthly: [],
    allTime: [],
    newUsers: []
  },
  detailed: [],
  currentUserLeader: null,
  isLoading: false,
  modalData: null
}

const CoinsHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEADER_BOARD_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case SET_LEADER_BOARD_DETAILED: {
      console.log(action)
      return {
        ...state,
        detailed: action.payload.detailed,
        currentUserLeader: action.payload.currentUserLeader
      }
    }
    case SET_LEADER_BOARD_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    case SET_LEADER_BOARD_MODAL_DATA: {
      return {
        ...state,
        modalData: action.payload
      }
    }
    default:
      return state
  }
}

export default CoinsHistoryReducer
