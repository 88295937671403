export const ROUTES = {
  COMMUNITY: {
    FEATURED: 'featured',
    EXPLORE: 'explore'
  },
  CREATE: {
    DECORATE_MY_ROOM: 'myroom',
    TEMPLATES: 'templates',
    CHALLENGES: 'challenges',
    MY_EVENTS: 'myevents'
  },
  HOMEPAGE: 'homepage',
  HOMEPAGE_OUR_OFFERING: 'homepage-our-offering',
  BLOG: 'blog',
  USER: {
    PROFILE: 'profile',
    MY_EVENTS: 'profile_my_events',
    SETTINGS: 'settings'
  },
  HELP_CENTER: 'help-center',
  STATIC_PAGES: {
    TERMS: 'terms',
    PRIVACY_POLICY: 'privacy-policy',
    ABOUT: 'about'
  }
}

const NAVIGATION_ROUTES = {
  [ROUTES.COMMUNITY.FEATURED]: {
    key: 'featured',
    title: 'Featured',
    path: '/community/featured'
  },
  [ROUTES.COMMUNITY.EXPLORE]: {
    key: 'explore',
    title: 'Explore',
    path: '/community/explore',
    headerPaths: ['/hashtag/']
  },
  [ROUTES.COMMUNITY.LEADERS]: {
    key: 'leaders',
    title: 'Leaders',
    path: '/leaders'
  },
  [ROUTES.HOMEPAGE]: {
    key: 'homepage',
    title: 'Create',
    path: '/'
  },
  [ROUTES.HOMEPAGE_OUR_OFFERING]: {
    key: 'homepage-our-offering',
    title: 'Our Offering',
    path: '/#ourOffering'
  },
  [ROUTES.BLOG]: {
    key: 'blog',
    title: 'Blog',
    path: '/blog'
  },
  [ROUTES.USER.PROFILE]: {
    key: 'profile',
    title: 'Profile',
    path: '/dm'
  },
  [ROUTES.USER.MY_EVENTS]: {
    key: 'profile',
    title: 'My Event',
    path: '/myevents'
  },
  [ROUTES.HELP_CENTER]: {
    key: 'help-center',
    title: 'Help Center',
    path: '/help-center'
  },
  [ROUTES.STATIC_PAGES.TERMS]: {
    key: 'terms',
    title: 'Terms of Use',
    path: '/terms'
  },
  [ROUTES.STATIC_PAGES.PRIVACY_POLICY]: {
    key: 'privacy-policy',
    title: 'Privacy Policy',
    path: '/policy'
  },
  [ROUTES.STATIC_PAGES.ABOUT]: {
    key: 'about',
    title: 'About',
    path: 'https://www.company.decormatters.com/',
    outside: true,
    props: {
      target: '_blank'
    }
  },
  [ROUTES.USER.SETTINGS]: {
    key: 'settings',
    title: 'Settings',
    path: '/settings'
  },
  [ROUTES.CREATE.CHALLENGES]: {
    key: 'challenges',
    title: 'Challenges',
    path: '/challenges'
  },
  [ROUTES.CREATE.MY_EVENTS]: {
    key: 'myEvents',
    title: 'My Events',
    path: '/myevents'
  },
  [ROUTES.CREATE.DECORATE_MY_ROOM]: {
    key: 'myRoom',
    title: 'My Room',
    path: '/myrooms'
  },
  [ROUTES.CREATE.TEMPLATES]: {
    key: 'templates',
    title: 'Templates',
    path: '/templates'
  }
}

export const getRoute = key => {
  return NAVIGATION_ROUTES[key] || null
}

export const getConditionalRoute = (key, condition) => {
  const route = getRoute(key)

  if (!route) return null

  return {
    ...route,
    condition
  }
}

export const DEFAULT_SUB_MENU = [
  getRoute(ROUTES.COMMUNITY.FEATURED),
  getRoute(ROUTES.COMMUNITY.EXPLORE),
  getRoute(ROUTES.COMMUNITY.LEADERS)
]

export const CREATE_SUB_MENU = [
  getRoute(ROUTES.HOMEPAGE),
  getRoute(ROUTES.HOMEPAGE_OUR_OFFERING)
]
