import _ from 'lodash'

const profileImageObject = user => {
  var pic = 'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'
  if (user.thumbProfileImageFile) pic = user.thumbProfileImageFile.url
  else if (user.cfTbImageUrl) pic = user.cfTbImageUrl

  return pic
}

export const userObject = data => {
  if (!data?.user) return

  let rootData = _.cloneDeep(data)

  delete rootData['user']
  delete data.user['ACL']
  delete data.user['__type']

  return {
    ...rootData,
    uniqueDisplayName: 'DM_User_' + data.user.objectId.slice(-2),
    ...data.user,
    pic: profileImageObject(data.user),
    numCoins: data?.numCoins || 0,
    userLevel: data?.userLevel || 1
  }
}
