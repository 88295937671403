import styled from 'styled-components'

const DEFAULT_SIZE = 148

export const ProfileImageBg = styled.img`
  border-radius: 50%;
  border: 2px solid white;
`

export const ProfileImageWrapper = styled.div`
  width: ${props => props.size || DEFAULT_SIZE}px;
  height: ${props => props.size || DEFAULT_SIZE}px;
  background-size: cover;
  border-radius: 50%;
  background-color: rgb(245, 245, 245);
  box-shadow: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: ${props => (props.borderRadius ? `${props.borderRadius}px` : '3px')}
    solid rgba(229, 229, 229, 1);
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  ${ProfileImageBg} {
    width: ${props => (props.size || DEFAULT_SIZE) - 6}px;
    height: ${props => (props.size || DEFAULT_SIZE) - 6}px;
  }
`

export const Level = styled.div`
  margin: 0;
  position: absolute;
  z-index: 2;
  bottom: 3px;
  right: 0;
  border-radius: 10px;
  padding: 2px 12px;
  font-size: 12px;
  font-weight: bold;
  user-select: none;
  color: white;
  background: #ff5e6d;
  width: initial;

  @media (max-width: 820px) {
    border-radius: 52px;
    box-shadow: 0px 2px 4px 0px rgba(255, 94, 109, 0.53);
    width: 29px;
    height: 12px;
    font-size: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`

export const SmallLevel = styled(Level)`
  font-size: 8px;
  padding: 2px 4px;
`

export const ProfileExpBar = styled.div`
  content: '';
  width: 100%;
  height: 100%;
  background: red;
  position: absolute;
  top: 0;
  left: 0;
`

export const AvatarAccessory = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ size }) =>
    Math.round((size || DEFAULT_SIZE) * 1.33)}px !important;
  height: ${({ size }) =>
    Math.round((size || DEFAULT_SIZE) * 1.33)}px !important;
`
