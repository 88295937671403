import styled from 'styled-components'
import arrowImg from '../../assets/arrow-right.svg'

export const NotificationsMenuContainer = styled.div`
  top: 65px;
  right: 10px;
  width: 440px;
  height: 724px;
  position: absolute;
  background-color: white;
  z-index: 10000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media screen and (max-height: 768px) {
    height: 600px;
  }

  @media screen and (max-width: 450px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
`

export const NotificationTabs = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e5e5e5;
`

export const TabContent = styled.button`
  width: 100%;
  height: 100%;
  padding: 20px;
  border: none;
  cursor: default;
  font-size: 14px;
  border-bottom: 3px solid transparent;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;

  ${props =>
    props.hasUpdates &&
    `
  &::after {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ff5e6d;
    position: absolute;
    margin-top: -5px;
    margin-left: 5px;
  }
  `}
`

export const NotificationTab = styled.li`
  text-align: center;
  flex-grow: 0;
  margin-left: 7px;
  margin-right: 7px;

  &.active ${TabContent}, &:hover ${TabContent} {
    border-bottom: 3px solid black;
    color: rgba(0, 0, 0, 1);
  }
`

export const NotificationsList = styled.ul`
  list-style: none;
  height: 93%;
  overflow: auto;
  margin: 0;
  padding: 0;
  margin-right: 5px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 5px;
    width: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`

export const NotificationBlock = styled.li`
  margin-bottom: 12px;
`

export const NotificationPeriodDate = styled.li`
  padding-bottom: 16px;
  padding-top: 16px;
  border-top: 1px solid #fbfbfb;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 30px;
`

export const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  padding-top: ${props => (props.withoutPadding ? '0px' : '80px')};

  & span {
    display: block;
    color: #b5b5b5;
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 63px;
  }
`

export const NotificationsHeaderMobile = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.02em;
  color: #000000;

  @media screen and (max-width: 450px) {
    display: flex;
  }
`

export const BackArrowButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  left: 15px;
  top: 15px;
`

export const BackArrow = styled.img.attrs({
  src: arrowImg
})`
  transform: rotate(180deg);
`

export const TabCategories = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

export const CategoryButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justoify-content: center;
  border: none;
  background: transparent;
  color: ${props => (props.selected ? 'black' : '#a6a6a6')};
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: ${props => (props.selected ? 'bold' : '400')};
  gap: 8px;
`

export const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 10000;
`

export const PopupHeader = styled.div`
  width: 100%;
  height: 59px;
  padding-top: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 2px solid #fbfbfb;
`

export const PopupHeaderBackButton = styled.div`
  cursor: pointer;
  padding: 0 14px;
`

export const PopupHeaderTitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

export const EmptyStateButton = styled.button`
  width: 320px;
  height: 44px;
  border-radius: 56px;
  background: #ff5e6d;
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 700;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`
