import styled from 'styled-components'

export const GetAppButtonWrapper = styled.a`
  width: 100%;
  background: linear-gradient(
    270deg,
    #ff9d74 0%,
    #ff5e6d 37.4%,
    #ff4f7e 74.69%,
    #ff6d90 100%
  );
  border-radius: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  position: relative;

  & span {
    margin-right: -5px;
  }
`

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;

  & img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`
