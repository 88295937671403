import React, { useState, createContext, useContext } from 'react'
import { ThemeProvider } from 'styled-components'

const light = {
  body: {
    background: '#FFFFFF'
  }, //E5E5E5
  background: {
    primary: '#F5F5F5',
    secondary: '#FFFFFF'
  },
  card: {
    primary: '#f5f5f5'
  },
  text: {
    primary: '#000000',
    secondary: '#888', //979797
    disabled: '#fff',
    menu: '#555'
  },
  button: {
    link: '#eee',
    disabled: '#f5f5f5'
  },
  nav: {
    background: '#FFFFFF',
    info: '#8B8B8B',
    brand: '#FF5E6D',
    profile: '#000000',
    toolOff: '#888888',
    toolOn: '#FF5E6D',
    toolDisable: '#CCCCCC'
  },
  sectionTab: {
    highlight: '#F5F5F5',
    text: '#FF5E6D',
    icon: '#8B8B8B'
  },
  tool: {
    icon: '#000000',
    button: '#FFFFFF',
    hover: '#e5e5e5',
    disabled: '#CCC',
    background: '#F5F5F5',
    divider: '#CCC',
    inputBackground: '#FFFFFF'
  },
  shadows: {
    light: '1px 1px 6px rgba(0,0,0,.1)'
  },
  tabs: {
    active: '#000000',
    normal: '#777777',
    line: '#E5E5E5'
  },
  accordion: {
    highlight: '#F5F5F5',
    content: '#F5F5F5',
    text: '#000000'
  },
  items: {
    text: '#979797',
    background: '#FFFFFF',
    info: '#F5F5F5',
    bookmarkActive: '#FF5E6D',
    bookmarkDisable: '#E5E5E5'
  },
  color: {
    primary: '#FF5E6D'
  },
  border: {
    primary: '#E5E5E5'
  },
  canvas: '#979797',
  sidebar: '#F5F5F5',
  redacted: '#DADADA',
  redactedAlt: '#f5f5f5',
  highlight: {
    primary: '#ffbbc1',
    secondary: '#E5E5E5'
  },
  logo: '#FF5E6D',
  profile: {
    background: '#FFFFFF',
    highlightBackground: '#FFFCFC'
  },
  form: {
    inputBackground: '#f5f5f5',
    inputText: '#2D2D2D',
    label: '#2D2D2D',
    labelSecondary: '#8B8B8B',
    drop: '#8B8B8B',
    tagBackground: '#FFFFFF',
    tagText: '#000000',
    tagRemove: '#ff5e6d'
  },
  purchase: {
    cardBackground: '#FFFFFF',
    cardTitle: '#000000',
    cardSubtitle: '#8B8B8B',
    inputBorder: '#E5E5E5'
  },
  settings: {
    background: '#FFFFFF'
  },
  divider: '#E5E5E5',
  drawer: {
    titleBackground: '#E5E5E5',
    titleColor: '#777777'
  },
  pill: '#FFFFFF',
  post: {
    action: '#FFFFFF',
    side: 'rgba(255, 242, 243, 0.36)',
    inventory: '#F5F5F5'
  },
  designcard: {
    background: '#FFFFFF',
    pill: '#F5F5F5'
  },
  modal: {
    upload:
      'https://didr9pubr8qfh.cloudfront.net/designer/background_upload.png',
    remove:
      'https://didr9pubr8qfh.cloudfront.net/designer/background_modal_remove.png'
  }
}

const dark = {
  body: {
    background: '#000000'
  },
  background: {
    primary: '#000000',
    secondary: '#000000'
  },
  card: {
    primary: '#444'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#575757', //979797
    disabled: '#222',
    menu: '#F5F5F5'
  },
  button: {
    link: '#333',
    disabled: '#111'
  },
  nav: {
    background: '#575757',
    info: '#FFFFFF',
    brand: '#FFFFFF',
    profile: '#FFFFFF',
    toolOff: '#FFFFFF',
    toolOn: '#FF5E6D',
    toolDisable: '#CCCCCC'
  },
  sectionTab: {
    highlight: '#4F4F4F',
    text: '#ffffff',
    icon: '#555555'
  },
  tool: {
    icon: '#FFFFFF',
    button: '#000000',
    hover: '#333',
    disabled: '#999',
    background: '#000000',
    divider: '#CCCCCC',
    inputBackground: '#333'
  },
  shadows: {
    light: 'none'
  },
  tabs: {
    active: '#FFFFFF',
    normal: '#FFFFFF',
    line: '#E5E5E5'
  },
  accordion: {
    highlight: '#2D2D2D',
    content: '#000000',
    text: '#FFFFFF'
  },
  items: {
    text: '#D8D8D8',
    background: '#FFFFFF',
    info: '#777777',
    bookmarkActive: '#FF5E6D',
    bookmarkDisable: '#8B8B8B'
  },
  color: {
    primary: '#FF5E6D'
  },
  border: {
    primary: '#8B8B8B'
  },
  canvas: '#000000',
  sidebar: '#2D2D2D',
  redacted: '#343434',
  redactedAlt: '#2D2D2D',
  highlight: {
    primary: '#ffbbc1',
    secondary: '#2D2D2D'
  },
  logo: '#FFFFFF',
  profile: {
    background: '#111111',
    highlightBackground: '#222222'
  },
  form: {
    inputBackground: '#2D2D2D',
    inputText: '#FFFFFF',
    label: '#FFFFFF',
    labelSecondary: '#8B8B8B',
    drop: '#8B8B8B',
    tagBackground: '#8B8B8B',
    tagText: '#FFFFFF',
    tagRemove: '#FFFFFF'
  },
  purchase: {
    cardBackground: '#343434',
    cardTitle: '#FFFFFF',
    cardSubtitle: '#FFFFFF',
    inputBorder: '#4F4F4F'
  },
  settings: {
    background: '#000000'
  },
  divider: '#8B8B8B',
  drawer: {
    titleBackground: '#575757',
    titleColor: '#FFFFFF'
  },
  pill: '#343434',
  post: {
    action: '#202020',
    side: '#000000',
    inventory: '#262626'
  },
  designcard: {
    background: '#2C2C2C',
    pill: '#777777'
  },
  modal: {
    upload:
      'https://didr9pubr8qfh.cloudfront.net/designer/background_upload_dark.png',
    remove:
      'https://didr9pubr8qfh.cloudfront.net/designer/background_modal_remove_dark.png'
  }
}

/*

canvas: '#2D2D2D',


const DesignerTheme = {
  backgrounds: {
    primary: '#ffffff',
    secondary: '#f5f5f5',
  },
  disabled: '#C4C4C4',
  primary: '#ff5e6d',
  shadows: {
    light: '1px 1px 6px rgba(0,0,0,.1)',
  },
}
*/

export const ThemeContext = createContext()

const Theme = props => {
  const persistMode =
    typeof window !== 'undefined' && localStorage.getItem('colormode')
      ? localStorage.getItem('colormode')
      : 'light'

  const [appearance, setAppearance] = useState(persistMode)

  const setLight = () => {
    localStorage.setItem('colormode', 'light')
    setAppearance('light')
  }

  const setDark = () => {
    localStorage.setItem('colormode', 'dark')
    setAppearance('dark')
  }

  return (
    <ThemeContext.Provider value={{ appearance, setLight, setDark }}>
      <ThemeProvider theme={appearance === 'dark' ? dark : light} {...props} />
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
export default Theme
