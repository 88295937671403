import moment from 'moment'
import { NOTIFICATION_TYPES } from '../constants/NotificationTypeConstants'

export const groupNotificationsByPeriod = (
  notifications,
  weeklyReports = []
) => {
  const today = []
  const thisWeek = []
  const thisMonth = []
  const older = []

  const todayStart = moment().startOf('day')
  const thisWeekStart = moment().startOf('week')
  const thisMonthStart = moment().startOf('month')
  const threeDaysAgo = moment(todayStart).subtract(3, 'days')

  notifications.forEach(notification => {
    const createdAt = moment(notification.createdAt)

    if (createdAt.isBefore(thisMonthStart)) older.push(notification)
    if (createdAt.isAfter(thisMonthStart) && createdAt.isBefore(thisWeekStart))
      thisMonth.push(notification)
    if (createdAt.isAfter(thisWeekStart) && createdAt.isBefore(todayStart))
      thisWeek.push(notification)
    if (createdAt.isAfter(todayStart)) today.push(notification)
  })

  weeklyReports.forEach(weeklyReport => {
    const report = {
      ...weeklyReport,
      type: NOTIFICATION_TYPES.WEEKLY_REPORT_SMALL
    }
    const createdAt = moment(report.createdAt)

    // New weekly report should fix on top for 3 days
    if (createdAt.isAfter(threeDaysAgo)) {
      today.unshift(report)
      return
    }

    if (createdAt.isBefore(thisMonthStart)) older.push(report)
    if (createdAt.isAfter(thisMonthStart) && createdAt.isBefore(thisWeekStart))
      thisMonth.push(report)
    if (createdAt.isAfter(thisWeekStart) && createdAt.isBefore(todayStart))
      thisWeek.push(report)
    if (createdAt.isAfter(todayStart)) today.push(report)
  })

  return [
    {
      title: 'Today',
      notifications: today
    },
    {
      title: 'This Week',
      notifications: thisWeek
    },
    {
      title: 'This Month',
      notifications: thisMonth
    },
    {
      title: 'Older',
      notifications: older
    }
  ]
}
