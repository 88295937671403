import { useCallback, useState, useEffect } from 'react'
import { LOCAL_STORAGE_KEY } from '../constants/SearchConstants'

export const useRecentSearches = () => {
  const [recentSearches, setRecentSearches] = useState([])

  useEffect(() => {
    const cachedSearches = localStorage.getItem(LOCAL_STORAGE_KEY)

    if (cachedSearches) {
      const list = JSON.parse(cachedSearches)

      setRecentSearches(list)
    }
  }, [])

  const clearRecentSearches = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, '[]')
    setRecentSearches([])
  }, [])

  return [recentSearches, clearRecentSearches]
}
