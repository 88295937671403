import moment from 'moment'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const HIDDEN_TASKS = ['wBQjZVuZxn' /* novice badge */, 'isdDu0OxQ8' /* create first design */, 'hIyJ6U0bRp' /* watch ads */, 'wqdJDrrozL']

export const useDailyRewardsInfo = () => {
  const me = useSelector(state => state.profile.me)
  const { dailyTasks, welcomeTasks, recommendedDesigners, taskRecord, welcomeRecord, specialTasks } = useSelector(state => state.checkIn)

  const { rewardedDtIds, numLikes: numberLikes, numFollows: numberFollows, numGifts: numberGifts, designTemplate, joinEvent, challenge, designWithBrand: challengeWithRequirements, numVotes } = taskRecord || {}
  const { rewardedWgIds, joinedNewUserChallenge } = welcomeRecord || {}

  const rewardedTaskIds = useMemo(() => {
    return [...(rewardedDtIds || []), ...(rewardedWgIds || [])]
  }, [rewardedDtIds, rewardedWgIds])

  const specialTaskList = useMemo(() => {
    if (!specialTasks) return false

    return specialTasks.filter(item => {
      if (rewardedTaskIds.includes(item.objectId)) return false
      if (moment(item?.taskExpiresAt?.iso).isBefore(moment())) return false

      return true
    })
  }, [specialTasks, rewardedTaskIds])

  const tasksList = useMemo(() => {
    const tasksJoined = [...(welcomeTasks || []), ...(dailyTasks || [])]

    const tasksFiltered = tasksJoined.filter(task => {
      // Show only tasks enabled from backend
      // if (task.status !== 'active') return false

      // Hide some tasks
      if (HIDDEN_TASKS.includes(task.objectId)) return false

      if (recommendedDesigners?.length === 0 && task.objectId === 'kmEXaEWYFG') return false

      return true
    })

    const tasksSorted = tasksFiltered.sort((a, b) => {
      return a.rank - b.rank
    })

    return tasksSorted
      .map(task => {
        const completionInfo = {}

        let actionsDone = 0

        switch (task.objectId) {
          case '1axAkTN3zc':
            actionsDone = numberLikes || 0
            break
          case 'Xr4CyGw6Jb':
            actionsDone = numberGifts || 0
            break
          case 'kmEXaEWYFG':
            actionsDone = numberFollows || 0
            break
          case 'rcIEBNmaSr':
            actionsDone = challengeWithRequirements ? 1 : 0
            break
          case 'OUv6BrETWp':
            actionsDone = designTemplate ? 1 : 0
            break
          case 'ffTFgusCfz':
            actionsDone = challenge ? 1 : 0
            break
          case 'KC4XeteGcx':
            actionsDone = joinEvent ? 1 : 0
            break
          case 'jrY1WqDHjS':
            actionsDone = numVotes || 0
            break
          case '8GORtOEbeF':
            actionsDone = rewardedTaskIds.includes('8GORtOEbeF') ? 1 : 0
            break
          case 'dIEQgLxtpw':
            actionsDone = joinedNewUserChallenge ? 1 : 0
            break
          default:
            actionsDone = 0
            break
        }

        if (rewardedTaskIds.includes(task.objectId)) {
          completionInfo.completed = true
          completionInfo.claimed = true
        } else if (actionsDone >= (Number.isInteger(task.actionsRequired) ? task.actionsRequired : 1)) {
          completionInfo.completed = true
          completionInfo.claimed = false
        } else {
          completionInfo.completed = false
          completionInfo.claimed = false
        }

        return {
          ...task,
          ...completionInfo,
          actionsDone,
          actionsRequired: Number.isInteger(task.actionsRequired) ? task.actionsRequired : 1
        }
      })
      .filter(task => {
        if (['8GORtOEbeF', 'dIEQgLxtpw'].includes(task.objectId)) {
          return !task.claimed
        }

        return true
      })
  }, [numVotes, welcomeTasks, joinedNewUserChallenge, dailyTasks, rewardedTaskIds, recommendedDesigners, numberLikes, numberGifts, numberFollows, challengeWithRequirements, challenge, designTemplate, joinEvent])

  const claimedTasks = useMemo(() => {
    return tasksList.filter(task => task.claimed).length
  }, [rewardedTaskIds])

  return { claimedTasks, tasksList, specialTaskList }
}
