import * as React from 'react'
import {
  RecentSearchTag,
  RemoveRecentsButton,
  SearchSubtitle,
  SearchTitleLine
} from './QuickSearchContent.styles'
import removeRecentsImg from '../../assets/removeRecents.svg'
import { useRecentSearches } from '../../hooks/useRecentSearches'

export const RecentSearches = () => {
  const [recentSearches, clearRecentSearches] = useRecentSearches()

  return (
    <>
      {recentSearches.length > 0 && (
        <>
          <SearchTitleLine>
            <SearchSubtitle>Recent searches</SearchSubtitle>
            <RemoveRecentsButton onClick={clearRecentSearches}>
              <img src={removeRecentsImg} alt="Remove recent searches" />
            </RemoveRecentsButton>
          </SearchTitleLine>
          {recentSearches.map((search, i) => {
            return (
              <RecentSearchTag key={`${search}_${i}`}>{search}</RecentSearchTag>
            )
          })}
        </>
      )}
    </>
  )
}
