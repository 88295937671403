export const SEARCH_KEYWORD = 'SEARCH_KEYWORD'
export const SEARCH_PAGINATION_KEYWORD = 'SEARCH_PAGINATION_KEYWORD'
export const SEARCH_LOADING = 'SEARCH_LOADING'
export const LOCAL_STORAGE_KEY = 'recent_searches'

export const SEARCH_MODES = {
  ALL: 'ALL',
  BLOG: 'BLOG',
  COMMUNITY: 'COMMUNITY'
}
