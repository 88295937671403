import { createSlice } from '@reduxjs/toolkit'
import { NAV } from '../constants'

const designer = createSlice({
  name: 'designer',
  initialState: {
    showPurchaseCoins: false,
    showPurchaseMembershipModal: false,
    showBundles: false,
    preselectPackage: null,
    membershipSidebarProps: {},
    showProfile: false,
    modalOpen: false,
    mainScrollbarVisible: true,
    navbar: NAV
  },
  reducers: {
    setShowPurchaseCoins: (state, action) => {
      state.showPurchaseCoins = action.payload ? action.payload : false
    },
    setShowBundles: (state, action) => {
      state.showBundles = action.payload ? action.payload : false
    },
    setPreselectPackage: (state, action) => {
      state.preselectPackage = action.payload ? action.payload : null
    },
    setShowProfile: (state, action) => {
      state.showProfile = action.payload ? action.payload : false
    },
    setModalOpen: (state, action) => {
      state.modalOpen = true
    },
    setModalClose: (state, action) => {
      state.modalOpen = false
    },
    setNavbar: (state, action) => {
      state.navbar = action.payload
    },
    setMainScrollbarVisible: (state, action) => {
      state.mainScrollbarVisible = action.payload
    },
    setShowMembershipModal: (state, action) => {
      state.showPurchaseMembershipModal = action.payload
    },
    setMembershipSidebarProps: (state, action) => {
      state.membershipSidebarProps = action.payload
    }
  }
})

export const {
  setShowPurchaseCoins,
  setShowProfile,
  setModalOpen,
  setModalClose,
  setNavbar,
  setMainScrollbarVisible,
  setShowMembershipModal,
  setMembershipSidebarProps,
  setShowBundles,
  setPreselectPackage
} = designer.actions

export default designer.reducer
