import { getChallengeBackgroundFromType } from '../utils/challengeTypeLogo'
import eventChangeRequiredImg from '../assets/myevent/eventChangeRequired.svg'
import eventLiveImg from '../assets/myevent/eventLive.svg'
import eventNewDesignsImg from '../assets/myevent/eventNewDesigns.svg'
import eventScheduledImg from '../assets/myevent/eventScheduled.svg'
import eventWinndesImg from '../assets/myevent/eventWinners.svg'
import eventWinnerCoinsImg from '../assets/coin6.svg'

export const NOTIFICATION_TYPES = {
  NEW_USER: 'onboard',
  MODIFY_EVENT: 'modifyEvent',
  EVENT_WINNERS_REMINDER: 'eventWinnersReminder',
  EVENT_SCHEDULED: 'eventScheduled',
  EVENT_LIVE: 'eventLive',
  USER_EVENT_REWARD: 'userEventReward',
  EVENT_WINNER: 'eventWinner',
  NEW_EVENT_DESIGNS: 'newEventDesigns',
  USER_INSPIRATION_LIKE: 'userInspirationLike',
  USER_INSPIRATION_COMMENT: 'userInspirationComment',
  USER_INSPIRATION_COMMENT_REPLY: 'userInspirationCommentReply',
  USER_INSPIRATION_COMMENT_LIKE: 'userInspirationCommentLike',
  FOLLOW: 'follow',
  PUBLISHED_DESIGN: 'publishedDesign',
  PUBLISHED_DESIGN_GLOBAL: 'publishedDesignGlobal',
  CHALLENGE_ENDED_GLOBAL: 'challengeEndedGlobal',
  CHALLENGE_REMINDER: 'challengeReminder',
  CHALLENGE_ENDED: 'challengeEnded',
  OTHER: 'other',
  DAILY_CHECK_IN: 'dailyCheckIn',
  NEW_CHALLENGE_GLOBAL: 'newChallengeGlobal',
  NEW_PRODUCTS_GLOBAL: 'newProductsGlobal',
  TOP_VOTES_IN_CHALLENGE: 'topVotesInChallenge',
  EVENT_PROGRESS: 'eventProgress',
  EVENT_COMPLETE: 'eventComplete',
  PUBLISHED_DESIGN_MENTION: 'publishedDesignMention',
  COIN_GIFT: 'coinGift',
  ITEM_BUNDLE_SALE_GLOBAL: 'itemBundleSaleGlobal',
  POPULAR_FEED: 'popularFeed',
  DAILY_TASK_REWARD: 'dailyTaskReward',
  NEW_USER_DAILY_PICK: 'newUserDailyPick',
  MONTHLY_WINNERS: 'monthlyWinner',
  DAILY_DESIGN_WINNER: 'dailyDesignWinner',
  RELEASE: 'release',
  AVATAR: 'avatar',
  GIFT: 'gift',

  WELCOME_GIFT: 'welcomeGift',
  // rewards
  LEVEL_UP: 'levelUp',
  BADGE_REWARD: 'badgeReward',
  CHALLENGE_REWARD: 'challengeReward',
  TOP_RANK: 'topRank',
  NEW_USER_TOP_RANK: 'newUserTopRank',
  TOP_INFLUENCER: 'topInfluencer',
  NEW_USER_TOP_INFLUENCER: 'newUserTopInfluencer',
  MOST_VOTES_IN_CHALLENGES: 'mostVotesInChallenges',
  NEW_USER_MOST_VOTES_IN_CHALLENGES: 'newUserMostVotesInChallenges',
  TOP_GIFTS: 'topGifts',
  TOP_GIFTS_ALL_TIME: 'topGiftsAllTime',
  TOP_GIFTS_NEW_USER: 'topGiftsNewUser',
  TOP_BADGES: 'topBadges',
  WEEKLY_REPORT: 'weeklyReport',
  WEEKLY_REPORT_SMALL: 'weeklyReportSmall',

  EVENT_LIVE_GLOBAL: 'eventLiveGlobal',
  EVENT_WINNERS_GLOBAL: 'eventWinnersGlobal',

  MULTI_GIFT: 'multiGift',
  MULTI_LIKE: 'multiLike',

  NON_GROUPED_LIKE: 'userInspirationLikeTemp',
  NON_GROUPED_GIFT: 'userInsVirtualGiftTemp'
}

export const USER_EVENT_NOTIFICATIONS = [
  NOTIFICATION_TYPES.MODIFY_EVENT,
  NOTIFICATION_TYPES.EVENT_WINNERS_REMINDER,
  NOTIFICATION_TYPES.EVENT_SCHEDULED,
  NOTIFICATION_TYPES.EVENT_LIVE,
  NOTIFICATION_TYPES.USER_EVENT_REWARD,
  NOTIFICATION_TYPES.EVENT_WINNER,
  NOTIFICATION_TYPES.NEW_EVENT_DESIGNS
]

export const PREVIEW_EVENT_NOTIFICATION = [
  ...USER_EVENT_NOTIFICATIONS,
  NOTIFICATION_TYPES.USER_EVENT_REWARD,
  NOTIFICATION_TYPES.CHALLENGE_REWARD
]

export const NOTIFICATION_SECTIONS = {
  YOU: {
    types: [
      NOTIFICATION_TYPES.USER_INSPIRATION_LIKE,
      NOTIFICATION_TYPES.NON_GROUPED_LIKE,
      NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT,
      NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_REPLY,
      NOTIFICATION_TYPES.USER_INSPIRATION_COMMENT_LIKE,
      NOTIFICATION_TYPES.FOLLOW,
      NOTIFICATION_TYPES.CHALLENGE_REMINDER,
      NOTIFICATION_TYPES.CHALLENGE_ENDED,
      NOTIFICATION_TYPES.EVENT_PROGRESS,
      NOTIFICATION_TYPES.EVENT_COMPLETE,
      NOTIFICATION_TYPES.PUBLISHED_DESIGN_MENTION,
      NOTIFICATION_TYPES.COIN_GIFT,
      NOTIFICATION_TYPES.ITEM_BUNDLE_SALE_GLOBAL,
      NOTIFICATION_TYPES.POPULAR_FEED,
      NOTIFICATION_TYPES.MULTI_LIKE,
      NOTIFICATION_TYPES.MULTI_GIFT,
      NOTIFICATION_TYPES.NON_GROUPED_GIFT
    ],
    globalTypes: []
  },
  REWARDS: {
    types: [],
    globalTypes: []
  },
  OFFICIAL: {
    types: [],
    globalTypes: [
      NOTIFICATION_TYPES.NEW_PRODUCTS_GLOBAL,
      NOTIFICATION_TYPES.NEW_CHALLENGE_GLOBAL,
      NOTIFICATION_TYPES.CHALLENGE_ENDED_GLOBAL,
      NOTIFICATION_TYPES.PUBLISHED_DESIGN_GLOBAL,
      NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL,
      NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL
    ]
  }
}

export const LOAD_NOTIFICATIONS_LIST = 'LOAD_NOTIFICATIONS_LIST'
export const LOAD_WEEKLY_REPORTS = 'LOAD_WEEKLY_REPORTS'
export const SET_NOTIFICATION_LOADING = 'SET_NOTIFICATIONS_LOADING'
export const RESET_NOTIFICATIONS_LIST = 'RESET_NOTIFICATIONS_LIST'

export const COMMENT_TYPES = {
  GIFT: 'userInsVirtualGift'
}

export const getNotificationBackgroundColor = (type, challenge) => {
  switch (type) {
    case NOTIFICATION_TYPES.BADGE_REWARD:
      return '#E3DCFF'
    case NOTIFICATION_TYPES.LEVEL_UP:
      return '#FFE8C8'
    case NOTIFICATION_TYPES.TOP_RANK:
      return '#DDEAFF'
    case NOTIFICATION_TYPES.PUBLISHED_DESIGN:
      return '#E0F9CC'
    case NOTIFICATION_TYPES.CHALLENGE_REMINDER:
    case NOTIFICATION_TYPES.CHALLENGE_REWARD:
    case NOTIFICATION_TYPES.NEW_CHALLENGE_GLOBAL:
      return getChallengeBackgroundFromType(challenge)
    case NOTIFICATION_TYPES.CHALLENGE_ENDED:
    case NOTIFICATION_TYPES.CHALLENGE_ENDED_GLOBAL:
      return '#E1E1E1'
    case NOTIFICATION_TYPES.EVENT_LIVE:
      return '#EDFFF2'
    case NOTIFICATION_TYPES.MODIFY_EVENT:
      return '#FFF3F3'
    case NOTIFICATION_TYPES.EVENT_PROGRESS:
      return '#EBEDFF'
    case NOTIFICATION_TYPES.EVENT_SCHEDULED:
      return '#FFE8E8'
    case NOTIFICATION_TYPES.EVENT_WINNERS_REMINDER:
      return '#FFF5E7'
    case NOTIFICATION_TYPES.EVENT_LIVE_GLOBAL:
      return '#FFEEEE'
    case NOTIFICATION_TYPES.EVENT_WINNERS_GLOBAL:
      return '#EEE7FF'
    case NOTIFICATION_TYPES.NEW_EVENT_DESIGNS:
      return '#EBEDFF'
    case NOTIFICATION_TYPES.TOP_BADGES:
    case NOTIFICATION_TYPES.TOP_GIFTS:
    case NOTIFICATION_TYPES.TOP_GIFTS_ALL_TIME:
    case NOTIFICATION_TYPES.TOP_GIFTS_NEW_USER:
    case NOTIFICATION_TYPES.TOP_INFLUENCER:
    case NOTIFICATION_TYPES.TOP_RANK:
    case NOTIFICATION_TYPES.TOP_VOTES_IN_CHALLENGE:
    case NOTIFICATION_TYPES.NEW_USER_TOP_INFLUENCER:
    case NOTIFICATION_TYPES.NEW_USER_TOP_RANK:
      return '#E3EFFA'
    case NOTIFICATION_TYPES.DAILY_DESIGN_WINNER:
      return '#EEE7FF'
    case NOTIFICATION_TYPES.NEW_USER_DAILY_PICK:
      return '#E3EFFA'
    default:
      return '#FFF3F3'
  }
}

export const getNotificationUserEventIcon = type => {
  switch (type) {
    case NOTIFICATION_TYPES.EVENT_LIVE:
      return eventLiveImg
    case NOTIFICATION_TYPES.MODIFY_EVENT:
      return eventChangeRequiredImg
    case NOTIFICATION_TYPES.NEW_EVENT_DESIGNS:
      return eventNewDesignsImg
    case NOTIFICATION_TYPES.EVENT_SCHEDULED:
      return eventScheduledImg
    case NOTIFICATION_TYPES.EVENT_WINNERS_REMINDER:
      return eventWinndesImg
    case NOTIFICATION_TYPES.EVENT_WINNER:
    case NOTIFICATION_TYPES.USER_EVENT_REWARD:
    case NOTIFICATION_TYPES.CHALLENGE_REWARD:
      return eventWinnerCoinsImg
    default:
      return eventNewDesignsImg
  }
}
