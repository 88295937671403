import { createSlice } from '@reduxjs/toolkit'
import { ATTEMPT_DAILY_TASK_RESET, GET_DAILY_TASKS, GET_RECOMMENDED_USERS, GET_WELCOME_GIFTS, REWARD_DAILY_TASK, REWARD_WELCOME_GIFT, WELCOME_GIFT_STATUS } from '../../constants/ApiEndpoints'
import { loadMe } from '../../routes/Profile/store/profileReducer'
import { parseToken } from '../../utils/authLocalStorage'
import { parseHeaders } from '../../utils/fetchUtil'

const ENDPOINTS = {
  GET_DAILY_TASKS: GET_DAILY_TASKS,
  ATTEMPT_DAILY_TASK_RESET: ATTEMPT_DAILY_TASK_RESET,
  REWARD_DAILY_TASK: REWARD_DAILY_TASK,
  GET_WELCOME_GIFTS: GET_WELCOME_GIFTS,
  WELCOME_GIFT_STATUS: WELCOME_GIFT_STATUS,
  REWARD_WELCOME_GIFT: REWARD_WELCOME_GIFT,
  GET_RECOMMENDED_USERS: GET_RECOMMENDED_USERS
}

const checkin = createSlice({
  name: 'checkin',
  initialState: {
    active: false,
    autoOpen: false,
    loadingStatus: null,
    dailyTasks: null,
    taskRecord: null,
    officialUTCStartHour: null,
    welcomeTasks: null,
    welcomeRecord: null,
    showReward: false,
    rewardAmount: 50,
    isSpecialTaskReward: false,
    recommendedDesigners: null,
    recommendedLoading: false,
    recommendedLoadMore: true,
    showFollowModal: false,
    showUsernameModal: false,
    specialTasks: []
  },
  reducers: {
    openModal: state => {
      state.active = true
    },
    closeModal: state => {
      state.active = false
      state.autoOpen = false
    },
    loading: state => {
      state.loadingStatus = 'loading'
    },
    loaded: state => {
      state.loadingStatus = 'loaded'
    },
    showReward: (state, action) => {
      state.showReward = true
      state.rewardAmount = action.payload.coinReward
      state.isSpecialTaskReward = action.payload.isSpecialTask
    },
    hideReward: state => {
      state.showReward = false
    },
    setTasks: (state, action) => {
      //console.log(action.payload)
      let { dailyTasks, currentTaskStatus, officialUTCStartHour, specialTasks } = action.payload
      state.dailyTasks = dailyTasks
      state.taskRecord = currentTaskStatus
      state.officialUTCStartHour = officialUTCStartHour
      state.specialTasks = specialTasks
    },
    setWelcomeTasks: (state, action) => {
      //console.log(action.payload)
      state.welcomeTasks = action.payload
    },
    setWelcomeRecord: (state, action) => {
      //console.log(action.payload)
      state.welcomeRecord = action.payload
    },
    setRecommendedDesigners: (state, action) => {
      state.recommendedDesigners = action.payload
      state.recommendedLoading = false
    },
    setRecommendedLoading: (state, action) => {
      state.recommendedLoading = action.payload
    },
    setRecommendedLoadMore: (state, action) => {
      state.recommendedLoadMore = action.payload
    },
    setShowUsernameModal: (state, action) => {
      state.showUsernameModal = action.payload
    },
    openFollowModal: state => {
      state.showFollowModal = true
      state.active = false
    },
    closeFollowModal: state => {
      state.showFollowModal = false
    }
  }
})

// --- redux thunks ---
export const getDailyTasks = () => {
  return async (dispatch, state) => {
    if (!parseToken()) return

    try {
      const response = await fetch(ENDPOINTS.GET_DAILY_TASKS, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders()
      })
      const { result } = await response.json()
      return dispatch(checkin.actions.setTasks(result))
    } catch (error) {
      // handle error
    }
  }
}

export const claimDailyTaskReward = (taskId, isSpecialTask = false) => {
  return async (dispatch, state) => {
    try {
      const response = await fetch(`${ENDPOINTS.REWARD_DAILY_TASK}?dailyTaskId=${taskId}`, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders()
      })
      const { result } = await response.json()
      if (result && result.coinReward) {
        dispatch(
          checkin.actions.showReward({
            coinReward: result.coinReward,
            isSpecialTask
          })
        )
        dispatch(getDailyTasks())
        dispatch(loadMe())
      }
    } catch (error) {
      // handle error
    }
  }
}

//OLD 2024
/*
export const attemptDailyTasksReset = () => {
  console.log('attemptDailyTasksReset')
  return async dispatch => {
    try {
      dispatch(checkin.actions.loading())
      await fetch(ENDPOINTS.ATTEMPT_DAILY_TASK_RESET, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders()
      })
      await Promise.all([
        dispatch(getDailyTasks()),
        dispatch(getWelcomeTasks()),
        dispatch(welcomeGiftStatus())
      ])
      return dispatch(checkin.actions.loaded())
    } catch (error) {
      // handle error
    }
  }
}
*/
//OLD 2024 This needs to be rewritten
/*
export const resetAndOpen = () => {
  return async (dispatch, state) => {
    try {
      dispatch(checkin.actions.openModal())
      //OLD 2024
      dispatch(attemptDailyTasksReset())
    } catch (error) {
      // handle error
    }
  }
}
*/
export const getWelcomeTasks = () => {
  return async (dispatch, state) => {
    try {
      const response = await fetch(ENDPOINTS.GET_WELCOME_GIFTS, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders()
      })
      const { result } = await response.json()
      return dispatch(checkin.actions.setWelcomeTasks(result.welcomeGifts))
    } catch (error) {
      // handle error
    }
  }
}

export const welcomeGiftStatus = () => {
  //console.log("WELCOME GIFT", ENDPOINTS.WELCOME_GIFT_STATUS)
  return async (dispatch, state) => {
    try {
      const response = await fetch(ENDPOINTS.WELCOME_GIFT_STATUS, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders()
      })
      const { result } = await response.json()
      return dispatch(checkin.actions.setWelcomeRecord(result.welcomeGiftRecord))
    } catch (error) {
      // handle error
    }
  }
}

export const claimWelcomeGiftReward = giftId => {
  return async (dispatch, state) => {
    try {
      const response = await fetch(`${ENDPOINTS.REWARD_WELCOME_GIFT}?welcomeGiftId=${giftId}`, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders()
      })
      const { result } = await response.json()
      if (result && result.coinReward) {
        dispatch(
          checkin.actions.showReward({
            coinReward: result.coinReward,
            isSpecialTask: false
          })
        )
        dispatch(welcomeGiftStatus())
        dispatch(loadMe())
      }
    } catch (error) {
      // handle error
    }
  }
}

export const getRecommendedUsers = (skip = 0) => {
  return async (dispatch, state) => {
    dispatch(checkin.actions.setRecommendedLoading(true))
    try {
      const response = await fetch(ENDPOINTS.GET_RECOMMENDED_USERS, {
        method: 'POST',
        mode: 'cors',
        headers: parseHeaders(),
        body: JSON.stringify({ skip })
      })
      const { recommendedDesigners } = state().checkIn
      const { result } = await response.json()
      let data = []
      if (recommendedDesigners) {
        data = [].concat(recommendedDesigners, result.recommendedUsers)
      } else {
        data = result.recommendedUsers
      }
      if (data.length < 10 && result.recommendedUsers.length !== 0) {
        dispatch(getRecommendedUsers(5))
      }
      if (result.recommendedUsers.length === 0) {
        dispatch(checkin.actions.setRecommendedLoadMore(false))
      }
      dispatch(checkin.actions.setRecommendedDesigners(data))
    } catch (error) {
      // handle error
    }
  }
}

export const closeFollowModalAndUpdate = () => {
  return async (dispatch, state) => {
    dispatch(checkin.actions.closeFollowModal())
    dispatch(getDailyTasks())
  }
}

export const { openModal, closeModal, showReward, hideReward, openFollowModal, setShowUsernameModal, setWelcomeTasks, setWelcomeRecord, setTasks, loaded } = checkin.actions

export default checkin.reducer
